import React from "react";
import WithdrawalTemplate from "../../../../components/dashboard/wallets/withdrawal";

type WithdrawalSymbolProps = {
  params: {
    symbol: string;
  };
};

const WithdrawalSymbol = (props: WithdrawalSymbolProps) => {
  return <WithdrawalTemplate symbol={props.params.symbol} />;
};

export default WithdrawalSymbol;
