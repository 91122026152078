import React from "react";
import FeatureItem from "./feature-item";
import ApiIcon from "./../../images/api.svg";
import PercentIcon from "./../../images/percent.svg";
import SupportIcon from "./../../images/support.svg";
import SecurityIcon from "./../../images/security.svg";
import { useStaticQuery, graphql } from "gatsby";

const OurFeatures = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteShortName
          }
        }
      }
    `
  );

  return (
    <div className="bg-gray-50 border-t">
      <div className="container py-20 sm:py-40">
        <h3 className="text-2xl sm:text-4xl font-bold text-center text-gray-900">
          {site.siteMetadata.siteShortName} has many features <br />
          that make it the best place to start trading
        </h3>
        <p className="text-center text-lg mt-8 text-gray-500 font-medium">
          Here are just a few reasons why you should choose {site.siteMetadata.siteShortName}
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-8 mt-20">
          <FeatureItem
            icon={ApiIcon}
            title={"Advanced " + site.siteMetadata.siteShortName + " API"}
            text={
              site.siteMetadata.siteShortName +
              "'s APIs are designed to offer an easy and efficient way to develop"
            }
          />
          <FeatureItem
            icon={PercentIcon}
            title="Lower Maker Fees"
            text="Attractive to the powerful trading makers, creating high liquidity"
          />
          <FeatureItem
            icon={SupportIcon}
            title="Excellent Customer Support"
            text="Got a problem? Just get in touch. Our support team is available 24/7"
          />
          <FeatureItem
            icon={SecurityIcon}
            title="Reliable Security Solutions"
            text="Multiple security mechanisms to protect user information"
          />
        </div>
      </div>
    </div>
  );
};

export default OurFeatures;
