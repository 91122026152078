import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import OurFeatures from '../components/features/our-features';
import StartTrading from '../components/features/start-trading';
import TradeEasily from '../components/features/trade-easily';
import GetStarted from '../components/get-started/get-started';
import Layout from '../components/layout';
import MarketsList from '../components/markets/markets-list';
import PlotBox from '../components/plot-box';

const IndexPage = () => {
  return (
    <Layout relative>
      <div className="container my-8 sm:my-28 pt-10 sm:pt-0">
        <h1 className="text-center text-4xl sm:text-6xl font-bold text-gray-900 leading-relaxed mb-2">
          Trade the Future
        </h1>
        <h2 className="text-center text-sm text-gray-400 leading-relaxed">
          Buy, sell and store over 50 digital assets at one of Europe's leading exchanges
        </h2>
        {
          <div className="mt-24 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            <PlotBox _1stCoin="BTC" _2ndCoin="USDT" />
            <PlotBox _1stCoin="ETH" _2ndCoin="USDT" />
            <PlotBox _1stCoin="ETH" _2ndCoin="BTC" />
          </div>
        }
      </div>
      <MarketsList />
      <GetStarted />
      <TradeEasily />
      <OurFeatures />
      <StartTrading />
    </Layout>
  );
};

export default IndexPage;
