import React from "react";
import Step1 from "./../../images/rewards/step1.svg";
import Step2 from "./../../images/rewards/step2.svg";
import Step3 from "./../../images/rewards/step3.svg";

const Steps = () => {
  return (
    <div className="pt-14 pb-20">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-10 md:gap-x-20 lg:gap-x-36 gap-y-14 w-full text-lg sm:text-xl md:text-2xl font-semibold">
        <div className="w-full flex flex-col justify-between">
          <div className="mb-6 sm:mb-12">
            <span className="text-3xl text-blue-700">1. </span> Create an Account
          </div>
          <div className="bg-white drop-shadow-2xl flex justify-center items-center rounded-3xl">
            <img src={Step1} className="w-full h-full"></img>
          </div>
        </div>
        <div className="w-full flex flex-col justify-between">
          <div className="mb-6 sm:mb-12">
            <span className="text-3xl text-blue-700">2. </span> Complete Assigned Tasks
          </div>
          <div className="bg-white drop-shadow-2xl flex justify-center items-center rounded-3xl">
            <img src={Step2} className="w-full h-full"></img>
          </div>
        </div>
        <div className="w-full flex flex-col justify-between">
          <div className="mb-6 sm:mb-12">
            <span className="text-3xl text-blue-700">3. </span> Check Your Rewards!
          </div>
          <div className="bg-white drop-shadow-2xl flex justify-center items-center rounded-3xl">
            <img src={Step3} className="w-full h-full"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
