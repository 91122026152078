import React from "react";
import "./buy-crypto-banner.css";

const BuyCryptoBanner = () => {
  return (
    <div className="relative buy-crypto-banner">
      <div className="buy-crypto-banner-main">
        <div className="container text-white flex items-center h-full">
          <div>
            <h1 className="text-2xl font-semibold">Buy Bitcoins</h1>
            <h2 className="text-sm sm:text-base">One Click to Buy Coins: Never been easier to buy coins</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyCryptoBanner;
