import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { Lang } from '../../model/enums';
import SymbolPlot from '../../components/markets/symbol/symbol-plot';
import OrderBook from '../../components/markets/symbol/order-book';
import SymbolHeader from '../../components/markets/symbol/symbol-header';
import ControlPanel from '../../components/markets/symbol/trading-panel/control-panel';
import MarketTrades from '../../components/markets/symbol/market-trades';
import { Button } from 'evergreen-ui';
import FacebookLoader from '../../components/loaders/facebook-loader';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useReadLocalStorage } from 'usehooks-ts';
import SymbolSeo from '../symbol-seo';

const SymbolPage = (props: any) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteShortName
          }
        }
      }
    `
  );

  const loggedIn: boolean | null = useReadLocalStorage<boolean>('logged_in');

  const [firstCoin, setFirstCoin] = useState<string | undefined>(undefined);
  const [secondCoin, setSecondCoin] = useState<string | undefined>(undefined);
  const [symbol, setSymbol] = useState<string>('');

  const [selectedPanel, setSelectedPanel] = useState(0);

  useEffect(() => {
    setFirstCoin(props.pageContext.firstCoin);
    setSecondCoin(props.pageContext.secondCoin);
    setSymbol(props.pageContext.symbol);
  });

  return (
    <Layout removePadding container relative withLoading supportDisabled seoDisabled>
      <SymbolSeo
        symbol={symbol}
        lang={Lang.EN}
        title={`${site.siteMetadata.siteShortName}: Bitcoin Exchange | Cryptocurrency Exchange`}
        description={
          site.siteMetadata.siteShortName +
          ' is the most advanced and secure cryptocurrency exchange to exchange Bitcoin, Ethereum, Litecoin, TRON, USDT, NEO, XRP, and more.'
        }
        meta={[]}
      />
      <div className="bg-white text-gray-900 block w-full mt-3 lg:mt-0">
        {firstCoin && secondCoin ? (
          <SymbolHeader _1stCoin={firstCoin} _2ndCoin={secondCoin} />
        ) : (
          <FacebookLoader></FacebookLoader>
        )}
        <div className="grid grid-cols-4">
          <div className="order-last md:order-first col-span-4 md:col-span-1 md:border-r md:pr-6">
            <ul className="grid grid-cols-2 mb-2 text-xs text-gray-400">
              <li
                className={
                  'cursor-pointer ' + (selectedPanel === 0 ? 'text-blue-700 font-bold' : '')
                }
                onClick={(e) => setSelectedPanel(0)}
              >
                Order Book
              </li>
              <li
                className={
                  'cursor-pointer text-right ' +
                  (selectedPanel === 1 ? 'text-blue-700 font-bold' : '')
                }
                onClick={(e) => setSelectedPanel(1)}
              >
                Market Trades
              </li>
            </ul>
            <div className={selectedPanel === 0 ? 'block' : 'hidden'}>
              {firstCoin && secondCoin && <OrderBook _1stCoin={firstCoin} _2ndCoin={secondCoin} />}
            </div>
            <div className={selectedPanel === 1 ? 'block' : 'hidden'}>
              {firstCoin && secondCoin && (
                <MarketTrades _1stCoin={firstCoin} _2ndCoin={secondCoin} />
              )}
            </div>
          </div>
          <div className="col-span-4 md:col-span-3 md:pl-6 grid grid-cols-1 grid-row-2">
            {firstCoin && secondCoin && (
              <SymbolPlot interval={'1h'} _1stCoin={firstCoin} _2ndCoin={secondCoin} />
            )}
            <div className="hidden md:block">
              {firstCoin && secondCoin && (
                <ControlPanel _1stCoin={firstCoin} _2ndCoin={secondCoin} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden sticky bottom-0 bg-white pt-4 pb-2 border-t">
        <div className="grid grid-cols-2 gap-x-4 mb-3">
          <Button appearance="primary" intent="success" className="w-full" fontFamily="Manrope">
            Buy
          </Button>
          <Button appearance="primary" intent="danger" className="w-full" fontFamily="Manrope">
            Sell
          </Button>
        </div>

        {!loggedIn ? (
          <div className="text-center">
            <span className="text-xxs">
              <Link to="/signin" className="text-blue-700 cursor-pointer font-semibold">
                Log In
              </Link>
              &nbsp;&nbsp;or&nbsp;&nbsp;
              <Link to="/signup" className="text-blue-700 cursor-pointer font-semibold">
                Sign Up&nbsp;
              </Link>{' '}
              to&nbsp;&nbsp;start&nbsp;&nbsp;trading
            </span>
          </div>
        ) : (
          <div className="text-center">
            <span className="text-xxs">
              Make a&nbsp;&nbsp;
              <Link
                to="/dashboard/wallets/deposit"
                className="text-blue-700 cursor-pointer font-semibold"
              >
                deposit&nbsp;
              </Link>{' '}
              to&nbsp;&nbsp;start&nbsp;&nbsp;trading
            </span>
          </div>
        )}
      </div>

      <div className="hidden md:flex justify-center items-center py-10 md:py-20">
        {!loggedIn ? (
          <span className="text-base">
            <Link to="/signin" className="text-blue-700 cursor-pointer font-semibold">
              Log In
            </Link>
            &nbsp;&nbsp;or&nbsp;&nbsp;
            <Link to="/signup" className="text-blue-700 cursor-pointer font-semibold">
              Sign Up&nbsp;
            </Link>{' '}
            to&nbsp;&nbsp;start&nbsp;&nbsp;trading
          </span>
        ) : (
          <span className="text-base">
            Make a&nbsp;&nbsp;
            <Link
              to="/dashboard/wallets/deposit"
              className="text-blue-700 cursor-pointer font-semibold"
            >
              deposit&nbsp;
            </Link>{' '}
            to&nbsp;&nbsp;start&nbsp;&nbsp;trading
          </span>
        )}
      </div>
    </Layout>
  );
};

export default SymbolPage;
