import React, { PropsWithChildren, useEffect } from "react";
import DashboardHeader from "./dashboard-header";
import LogoLink from "./../header/logo-link";
import { navigate } from "gatsby";
import { useLocalStorage } from "usehooks-ts";
import Sidebar from "./sidebar";

type DashboardLayoutProps = PropsWithChildren<{
  activeTab: number;
}>;

const DashboardLayout = (props: DashboardLayoutProps) => {
  const [loggedIn, setLoggedIn] = useLocalStorage<boolean>("logged_in", false);

  useEffect(() => {
    if (!loggedIn) {
      navigate("/signin");
    }
  }, [loggedIn]);

  return (
    <>
      <div className="hidden lg:block fixed top-0 left-0 bottom-0 w-full h-full lg:max-w-xxs xl:max-w-xs 2xl:max-w-sm min-h-screen bg-gray-100 border-r">
        <div className="px-8 py-5 h-full flex flex-col">
          <LogoLink large />
          <div className="mt-20 grow flex">
            <Sidebar
              mobile={false}
              activeTab={props.activeTab}
              onLogout={() => {
                setLoggedIn(false);
                navigate("/signin");
              }}
            />
          </div>
        </div>
      </div>
      <DashboardHeader />
      <div className="pl-0 lg:pl-60 xl:pl-80 2xl:pl-96">
        <div className="container">
          <div className="lg:hidden block overflow-auto">
            <div className="py-4">
              <Sidebar
                mobile
                activeTab={props.activeTab}
                onLogout={() => {
                  setLoggedIn(false);
                  navigate("/signin");
                }}
              />
            </div>
          </div>
          {props.children}
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
