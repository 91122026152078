import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import StartTrading from "../../components/features/start-trading";
import Layout from "../../components/layout";
import Sidebar from "../../components/news/sidebar";

const News = () => {
  const { site, allMdx } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteShortName
          }
        }
        allMdx(sort: { fields: frontmatter___date, order: DESC }) {
          nodes {
            frontmatter {
              date(formatString: "MMMM D, YYYY")
              title
              hero_image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, formats: [AUTO])
                }
              }
            }
            id
            slug
            excerpt
          }
        }
      }
    `
  );

  return (
    <Layout>
      <div className="relative container">
        <h1 className="text-3xl sm:text-4xl mt-5 mb-10 pb-4 font-black border-b">
          {site.siteMetadata.siteShortName} Latest News
        </h1>
        <div className="flex flex-wrap lg:flex-nowrap">
          <div className="lg:w-2/3 pb-20 sm:pb-40">
            <ul>
              {allMdx.nodes.map((node: any) => (
                <li key={node.id} className="mb-12">
                  <Link to={`/news/${node.slug}`}>
                    <div className="mb-5 rounded-xl overflow-hidden">
                      <GatsbyImage
                        loading="eager"
                        image={getImage(node.frontmatter.hero_image)!}
                        alt={node.frontmatter.title}
                      ></GatsbyImage>
                    </div>
                    <h2 className="text-2xl sm:text-3xl font-black mb-4">
                      {node.frontmatter.title.replace(
                        /%siteName%/g,
                        site.siteMetadata.siteShortName
                      )}
                    </h2>
                    <p className="mb-2">{node.excerpt}</p>
                    <p className="text-xxs sm:text-sm text-gray-400">
                      Published at {node.frontmatter.date}
                    </p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <Sidebar />
        </div>
      </div>
      <StartTrading />
    </Layout>
  );
};

export default News;
