export const coins = [
  {
    abbr: "BTC",
    name: "Bitcoin",
    k: 1,
  },
  {
    abbr: "ETH",
    name: "Ethereum",
    k: 1.076,
  },
  {
    abbr: "BCH",
    name: "Bitcoin Cash",
    k: 1,
  },
  {
    abbr: "LTC",
    name: "Litecoin",
    k: 1,
  },
  {
    abbr: "USDT",
    name: "Tether",
    k: 1,
  },
  {
    abbr: "USDC",
    name: "USD Coin",
    k: 1,
  },
  {
    abbr: "AVAX",
    name: "Avalanche",
    k: 1,
  },
  {
    abbr: "SOL",
    name: "Solana",
    k: 1,
  },
  {
    abbr: "NEAR",
    name: "NEAR Protocol",
    k: 1,
  },
  {
    abbr: "ONT",
    name: "Ontology",
    k: 1,
  },
  {
    abbr: "TRX",
    name: "TRON",
    k: 1,
  },
  {
    abbr: "XRP",
    name: "Ripple",
    k: 1,
  },
  {
    abbr: "NEO",
    name: "NEO",
    k: 1,
  },
  {
    abbr: "GLMR",
    name: "Glimmer",
    k: 1,
  },
  {
    abbr: "LINK",
    name: "ChainLink",
    k: 1,
  },
  {
    abbr: "BAT",
    name: "Basic Attention Token",
    k: 1,
  },
  {
    abbr: "HOT",
    name: "Holo",
    k: 1,
  },
  {
    abbr: "DOGE",
    name: "Dogecoin",
    k: 1,
  },
  {
    abbr: "ATOM",
    name: "Cosmos",
    k: 1,
  },
  {
    abbr: "FTM",
    name: "Fantom",
    k: 1,
  },
  {
    abbr: "MATIC",
    name: "Polygon",
    k: 1,
  },
  {
    abbr: "SHIB",
    name: "SHIBA INU",
    k: 1,
  },
  {
    abbr: "ROSE",
    name: "Oasis Network",
    k: 1,
  },
  {
    abbr: "CAKE",
    name: "PancakeSwap",
    k: 1,
  },
  {
    abbr: "YFI",
    name: "yearn.finance",
    k: 1,
  },
  {
    abbr: "SUSHI",
    name: "Sushi",
    k: 1,
  },
  {
    abbr: "DYDX",
    name: "dYdX",
    k: 1,
  },
  {
    abbr: "SXP",
    name: "Swipe",
    k: 1,
  },
  {
    abbr: "1INCH",
    name: "1inch",
    k: 1,
  },
  {
    abbr: "COMP",
    name: "Compound",
    k: 1,
  },
  {
    abbr: "QNT",
    name: "Quant",
    k: 1,
  },
  {
    abbr: "ENS",
    name: "Ethereum Name Service",
    k: 1,
  },
  {
    abbr: "ZRX",
    name: "0x",
    k: 1,
  },
  {
    abbr: "UNI",
    name: "Uniswap",
    k: 1,
  },
  {
    abbr: "AAVE",
    name: "Aave",
    k: 1,
  },
  {
    abbr: "MANA",
    name: "Decentraland",
    k: 1,
  },
  {
    abbr: "SAND",
    name: "The Sandbox",
    k: 1,
  },
];
