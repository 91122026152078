import React, { useEffect, useState } from "react";
import { getTrades } from "../../../utils/binance/api";

type MarketTradesProps = {
  _1stCoin: string;
  _2ndCoin: string;
};

const MarketTrades = (props: MarketTradesProps) => {
  const [trades, setTrades] = useState<any[]>([]);

  const symbol = `${props._1stCoin}${props._2ndCoin}`;
  const f = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  useEffect(() => {
    const interval = setInterval(() => {
      getTrades(symbol, 35, function (_trades) {
        setTrades(_trades);
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-sm">
      <table className="table-fixed w-full text-xs">
        <thead className="">
          <tr className="text-xxs text-gray-400">
            <th className="text-left pb-2 font-light">{`Price(${props._2ndCoin})`}</th>
            <th className="text-right pb-2 font-light">{`Amount(${props._1stCoin})`}</th>
            <th className="text-right pb-2 font-light">Time</th>
          </tr>
        </thead>
        <tbody>
          {trades &&
            trades
              .slice(0)
              .reverse()
              .map((trade, index) => (
                <tr key={index} className="">
                  <th
                    className={
                      "text-left font-normal pb-1 " +
                      (trade.isBuyerMaker ? "text-green-600" : "text-red-500")
                    }
                  >
                    {Number(trade.price).toFixed(2)}
                  </th>
                  <th className="text-right font-normal pb-1">{Number(trade.qty).toFixed(5)}</th>
                  <th className="text-right font-normal pb-1">
                    {new Date(trade.time).toLocaleTimeString()}
                  </th>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default MarketTrades;
