import React, { PropsWithChildren } from "react";

type SidebarButtonProps = PropsWithChildren<{
  text: string;
  pressed?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  mobile: boolean;
}>;

const SidebarButton = (props: SidebarButtonProps) => {
  return (
    <button
      onClick={props.onClick}
      className={
        "flex flex-row items-center " +
        (props.mobile
          ? "justify-center bg-gray-50 border rounded-lg py-1 px-3 "
          : "text-gray-800 mb-8 mt-auto ") +
        (props.pressed ? "text-blue-700 border-blue-100 " : " ") +
        (props.mobile && props.pressed ? "order-first shadow-sm border" : "")
      }
    >
      <div className={props.mobile ? "w-6 h-6" : "w-6 h-6"}>{props.children}</div>
      <span className={props.mobile ? "pl-2 text-sm font-semibold" : "pl-4 text-base font-bold"}>
        {props.text}
      </span>
    </button>
  );
};

export default SidebarButton;
