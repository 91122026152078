import React from "react";
import BlueButton from "../blue-button";

const StartTrading = () => {
  return (
    <div className="bg-gray-50 border-t">
      <div className="container flex flex-col sm:flex-row justify-center items-center py-10 sm:py-20 mx-auto">
        <h3 className="text-2xl sm:text-3xl font-bold sm:ml-auto mb-8 sm:mb-0">
          Start trading now
        </h3>
        <div className="mx-auto mr-auto">
          <BlueButton anchor="/signup" text="Get started" />
        </div>
      </div>
    </div>
  );
};

export default StartTrading;
