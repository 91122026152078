import React from "react";
import CodeSVG from "../svg/code";
import FormInput from "./form-input";

type InvitationCodeInputProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InvitationCodeInput = (props: InvitationCodeInputProps) => {
  return (
    <FormInput type="text" placeholder="Invitation Code (Required)" onChange={props.onChange}>
      <CodeSVG />
    </FormInput>
  );
};

export default InvitationCodeInput;
