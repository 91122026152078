exports.components = {
  "component---src-components-templates-symbol-tsx": () => import("./../../../src/components/templates/symbol.tsx" /* webpackChunkName: "component---src-components-templates-symbol-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aml-cft-policy-tsx": () => import("./../../../src/pages/aml-cft-policy.tsx" /* webpackChunkName: "component---src-pages-aml-cft-policy-tsx" */),
  "component---src-pages-buy-crypto-tsx": () => import("./../../../src/pages/buy-crypto.tsx" /* webpackChunkName: "component---src-pages-buy-crypto-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-dashboard-affiliates-tsx": () => import("./../../../src/pages/dashboard/affiliates.tsx" /* webpackChunkName: "component---src-pages-dashboard-affiliates-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-orders-tsx": () => import("./../../../src/pages/dashboard/orders.tsx" /* webpackChunkName: "component---src-pages-dashboard-orders-tsx" */),
  "component---src-pages-dashboard-settings-index-tsx": () => import("./../../../src/pages/dashboard/settings/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-settings-index-tsx" */),
  "component---src-pages-dashboard-settings-security-tsx": () => import("./../../../src/pages/dashboard/settings/security.tsx" /* webpackChunkName: "component---src-pages-dashboard-settings-security-tsx" */),
  "component---src-pages-dashboard-settings-verification-tsx": () => import("./../../../src/pages/dashboard/settings/verification.tsx" /* webpackChunkName: "component---src-pages-dashboard-settings-verification-tsx" */),
  "component---src-pages-dashboard-wallets-deposit-[symbol]-tsx": () => import("./../../../src/pages/dashboard/wallets/deposit/[symbol].tsx" /* webpackChunkName: "component---src-pages-dashboard-wallets-deposit-[symbol]-tsx" */),
  "component---src-pages-dashboard-wallets-deposit-index-tsx": () => import("./../../../src/pages/dashboard/wallets/deposit/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-wallets-deposit-index-tsx" */),
  "component---src-pages-dashboard-wallets-index-tsx": () => import("./../../../src/pages/dashboard/wallets/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-wallets-index-tsx" */),
  "component---src-pages-dashboard-wallets-transfer-[symbol]-tsx": () => import("./../../../src/pages/dashboard/wallets/transfer/[symbol].tsx" /* webpackChunkName: "component---src-pages-dashboard-wallets-transfer-[symbol]-tsx" */),
  "component---src-pages-dashboard-wallets-transfer-index-tsx": () => import("./../../../src/pages/dashboard/wallets/transfer/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-wallets-transfer-index-tsx" */),
  "component---src-pages-dashboard-wallets-withdraw-[symbol]-tsx": () => import("./../../../src/pages/dashboard/wallets/withdraw/[symbol].tsx" /* webpackChunkName: "component---src-pages-dashboard-wallets-withdraw-[symbol]-tsx" */),
  "component---src-pages-dashboard-wallets-withdraw-index-tsx": () => import("./../../../src/pages/dashboard/wallets/withdraw/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-wallets-withdraw-index-tsx" */),
  "component---src-pages-fees-tsx": () => import("./../../../src/pages/fees.tsx" /* webpackChunkName: "component---src-pages-fees-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markets-tsx": () => import("./../../../src/pages/markets.tsx" /* webpackChunkName: "component---src-pages-markets-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-mdx-slug-tsx": () => import("./../../../src/pages/news/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-news-mdx-slug-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-rewards-tsx": () => import("./../../../src/pages/rewards.tsx" /* webpackChunkName: "component---src-pages-rewards-tsx" */),
  "component---src-pages-risk-warning-tsx": () => import("./../../../src/pages/risk-warning.tsx" /* webpackChunkName: "component---src-pages-risk-warning-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

