import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { useReadLocalStorage } from "usehooks-ts";
import AffiliateBox from "../../components/dashboard/affiliate/affiliate-box";
import AffiliateStatistics from "../../components/dashboard/affiliate/affiliate-statistics";
import DashboardLayout from "../../components/dashboard/dashboard-layout";
import FakeTable from "../../components/dashboard/fake-table";
import { AppUser } from "../../model/types";

const Affiliates = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteDomain
            siteShortName
          }
        }
      }
    `
  );

  const user: AppUser | null = useReadLocalStorage<AppUser>("user");

  return (
    <DashboardLayout activeTab={3}>
      <div className="py-10">
        <h1 className="text-2xl font-bold mb-2">Invite Friends</h1>
        <p className="text-xs text-gray-500 mb-8">
          To invite friends to register {site.siteMetadata.siteShortName} through refferal code or
          link
        </p>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 mb-20">
          <AffiliateBox title="Refferal code" textToCopy={user?.affiliateCode!!}>
            {user?.affiliateCode}
          </AffiliateBox>
          <AffiliateBox
            title="Refferal link"
            textToCopy={
              "https://" + site.siteMetadata.siteDomain + "/invite/" + user?.affiliateCode
            }
          >
            <span className="text-gray-500">{site.siteMetadata.siteDomain + "/invite/"}</span>
            {user?.affiliateCode}
          </AffiliateBox>
        </div>
        <AffiliateStatistics />
        <FakeTable title="Your Referrals" headings={["User", "Date", "Earned"]} />
      </div>
    </DashboardLayout>
  );
};

export default Affiliates;
