import React, { useEffect, useState } from 'react';
import UpIcon from './../../../images/arrow-up.svg';
import DownIcon from './../../../images/arrow-down.svg';
import { getTicker24h } from '../../../utils/binance/api';
import SymbolDropdown from './symbol-dropdown';

type SymbolHeaderProps = {
  _1stCoin: string;
  _2ndCoin: string;
};

const SymbolHeader = (props: SymbolHeaderProps) => {
  const priceFormat = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 6,
    minimumFractionDigits: 2,
  });
  const volumeFormat = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
  const symbol = `${props._1stCoin}${props._2ndCoin}`;
  const headerItemClass = 'text-xs pr-4';
  const mobileHeaderItemClass = 'text-xxs font-normal';

  const [prevLastPrice, setPrevLastPrice] = useState<number>(0);

  const [lastPrice, setLastPrice] = useState<number>(0);
  const [priceChange24h, setPriceChange24h] = useState<number>(0);
  const [priceChangePercent24h, setPriceChangePercent24h] = useState<number>(0);
  const [highPrice24h, setHighPrice24h] = useState<number>(0);
  const [lowPrice24h, setLowPrice24h] = useState<number>(0);
  const [volume, setVolume] = useState<number>(0);
  const [quoteVolume, setQuoteVolume] = useState<number>(0);
  const [weightedAvgPrice, setWeightedAvgPrice] = useState<number>(0);
  const [bidPrice, setBidPrice] = useState<number>(0);
  const [askPrice, setAskPrice] = useState<number>(0);
  const [prevClosePrice, setPrevClosePrice] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      getTicker24h(
        symbol,
        function (
          _lastPrice,
          _priceChange24h,
          _priceChangePercent24h,
          _highPrice24h,
          _lowPrice24h,
          _volume,
          _quoteVolume,
          _weightedAvgPrice,
          _bidPrice,
          _askPrice,
          _prevClosePrice
        ) {
          setPrevLastPrice(lastPrice);
          setLastPrice(_lastPrice);
          setPriceChange24h(_priceChange24h);
          setPriceChangePercent24h(_priceChangePercent24h);
          setHighPrice24h(_highPrice24h);
          setLowPrice24h(_lowPrice24h);
          setVolume(_volume);
          setQuoteVolume(_quoteVolume);
          setWeightedAvgPrice(_weightedAvgPrice);
          setBidPrice(_bidPrice);
          setAskPrice(_askPrice);
          setPrevClosePrice(_prevClosePrice);
        }
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [lastPrice, prevLastPrice]);

  return (
    <>
      <header className="grid md:hidden grid-cols-4 grid-rows-2 border-b pb-2 mb-3 gap-y-2">
        <div className="col-span-2 pl-1 font-bold text-xl text-gray-800">
          <SymbolDropdown _1stCoin={props._1stCoin} _2ndCoin={props._2ndCoin} />
        </div>
        <div className={mobileHeaderItemClass}>
          <div className="font-medium text-gray-400 py-1">High 24H</div>
          <div>{`${priceFormat.format(highPrice24h)}`}</div>
        </div>
        <div className={mobileHeaderItemClass}>
          <div className="font-medium text-gray-400 py-1">{`Volume 24H (${props._1stCoin})`}</div>
          <div>{`${volumeFormat.format(volume)}`}</div>
        </div>
        <div className="col-span-2 pl-1">
          <div
            className={
              'text-2xl ' + (lastPrice > prevLastPrice ? 'text-green-600' : 'text-red-500')
            }
          >{`${priceFormat.format(lastPrice)}`}</div>
          <div
            className={
              'text-xxs ' + (lastPrice > prevLastPrice ? 'text-green-600' : 'text-red-500')
            }
          >
            {`${priceFormat.format(priceChange24h)} \u00A0 ${Number(priceChangePercent24h).toFixed(
              2
            )}%`}
          </div>
        </div>
        <div className={mobileHeaderItemClass}>
          <div className="font-medium text-gray-400 py-1">Low 24H</div>
          <div>{`${priceFormat.format(lowPrice24h)}`}</div>
        </div>
        <div className={mobileHeaderItemClass}>
          <div className="font-medium text-gray-400 py-1">{`Volume 24H (${props._2ndCoin})`}</div>
          <div>{`${volumeFormat.format(quoteVolume)}`}</div>
        </div>
      </header>
      <header className="hidden md:flex flex-row mb-6 border-y py-2">
        <SymbolDropdown _1stCoin={props._1stCoin} _2ndCoin={props._2ndCoin} />
        <div className="grid grid-cols-6">
          <div className="pr-6">
            <div
              className={lastPrice > prevLastPrice ? 'text-green-600' : 'text-red-500'}
            >{`${priceFormat.format(lastPrice)}`}</div>
            <div className="text-xs">{`$${priceFormat.format(lastPrice)}`}</div>
          </div>
          <div className={headerItemClass}>
            <div className="font-medium text-gray-400 py-1">Change 24H</div>
            <div className={lastPrice > prevClosePrice ? 'text-green-600' : 'text-red-500'}>
              {`${priceFormat.format(priceChange24h)} \u00A0 ${Number(
                priceChangePercent24h
              ).toFixed(2)}%`}
            </div>
          </div>
          <div className={headerItemClass}>
            <div className="font-medium text-gray-400 py-1">High 24H</div>
            <div>{`${priceFormat.format(highPrice24h)}`}</div>
          </div>
          <div className={headerItemClass}>
            <div className="font-medium text-gray-400 py-1">Low 24H</div>
            <div>{`${priceFormat.format(lowPrice24h)}`}</div>
          </div>
          <div className={headerItemClass}>
            <div className="font-medium text-gray-400 py-1">{`Volume 24H (${props._1stCoin})`}</div>
            <div>{`${volumeFormat.format(volume)}`}</div>
          </div>
          <div className={headerItemClass}>
            <div className="font-medium text-gray-400 py-1">{`Volume 24H (${props._2ndCoin})`}</div>
            <div>{`${volumeFormat.format(quoteVolume)}`}</div>
          </div>
        </div>
        <div className="ml-auto flex justify-center items-center ">
          <div className={lastPrice > prevClosePrice ? 'text-green-600' : 'text-red-500'}>
            <span className="text-2xl pr-2">{`${Number(priceChangePercent24h).toFixed(2)}%`}</span>
          </div>
          <img className="w-7" src={lastPrice > prevClosePrice ? UpIcon : DownIcon}></img>
        </div>
      </header>
    </>
  );
};

export default SymbolHeader;
