import { useStaticQuery, graphql, Link } from "gatsby";
import React from "react";
import LogoLink from "../header/logo-link";
import FooterNav from "./footer-nav";
import BitGo from "./../../images/bitgo.png";
import ComodoSecure from "./../../images/comodo-secure.png";
import HsbcUk from "./../../images/hsbc-uk.png";
import { getRandomInt } from "../../utils/tools";
import "./footer.css";

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteDomain
            siteShortName
          }
        }
      }
    `
  );

  return (
    <footer className="relative bg-gray-50 border-t py-10 sm:py-20">
      <div className="container">
        <FooterNav />
        <div className="flex flex-wrap items-center mt-20">
          <div className="sm:border-r w-full sm:w-auto flex justify-center mb-6 sm:mb-0">
            <LogoLink />
          </div>
          <div className="flex items-center gap-x-10 sm:ml-20 w-full sm:w-auto justify-center sm:justify-start">
            <img src={BitGo} className="h-8"></img>
            <img src={ComodoSecure} className="h-8"></img>
            <img src={HsbcUk} className="h-4"></img>
          </div>
          <div className="mt-8 flex flex-wrap justify-center sm:justify-end gap-x-3 text-xs font-medium mr-auto sm:mr-0 ml-auto border rounded-md px-3 py-2">
            <div>Status:</div>
            <div className="text-green-600 status-bar">All systems normal</div>
            <div className="text-blue-700">{getRandomInt(20, 100) + " ms"}</div>
          </div>
        </div>
        <div className="text-xxs sm:text-xs mt-10 sm:flex sm:gap-x-8 flex-wrap">
          <div className="text-center text-gray-500 mb-2">
            Copyright &copy;
            {" " + new Date().getFullYear() + " " + site.siteMetadata.siteShortName + " "}
            Global Ltd. All rights reserved.
          </div>
          <div className="text-center text-gray-500 sm:flex sm:gap-x-6">
            <a href={"mailto:support@" + site.siteMetadata.siteDomain}>
              {"support@" + site.siteMetadata.siteDomain}
            </a>
            <Link to="/support" className="pl-4 text-blue-700">
              Support Center
            </Link>
          </div>
          <ul className="xl:ml-auto flex gap-x-4 text-xxs sm:text-xs text-gray-500 justify-center mt-10 sm:mt-0">
            <li>
              <Link to="/terms-of-use">Terms of Use</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/cookies-policy">Cookies Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
