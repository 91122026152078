import { Button } from "evergreen-ui";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import EmailInput from "./email-input";
import FormHeader from "./form-header";

type SignupEmailFormProps = {
  step: number;
  invalidEmail: boolean;
  onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const SignupEmailForm = (props: SignupEmailFormProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteName
          }
        }
      }
    `
  );

  return (
    <form autoComplete="off" className={props.step === 0 ? "block" : "hidden"}>
      <FormHeader
        firstLine={"Join " + site.siteMetadata.siteName}
        secondLine="Enter your email to get verification code"
      />
      <EmailInput onChange={props.onEmailChange} />
      <div className={"mb-6 text-red-500 " + (props.invalidEmail ? "visible" : "invisible")}>
        Invalid format of email address
      </div>
      <Button
        fontFamily="Manrope"
        appearance="primary"
        intent="none"
        size="large"
        className="w-full"
        onClick={props.onButtonClick}
      >
        Send
      </Button>
    </form>
  );
};

export default SignupEmailForm;
