import React from "react";

const CodeSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 48 48">
      <path d="M7 40Q5.8 40 4.9 39.1Q4 38.2 4 37V11Q4 9.8 4.9 8.9Q5.8 8 7 8H41Q42.2 8 43.1 8.9Q44 9.8 44 11V37Q44 38.2 43.1 39.1Q42.2 40 41 40ZM12.35 30.3H14.45V17.7H12.85L9.2 20.15L10.25 21.8L12.35 20.4ZM19 30.3H27.15V28.4H21.7V28.3Q22.4 27.7 23.225 26.975Q24.05 26.25 24.7 25.6Q25.75 24.6 26.35 23.675Q26.95 22.75 26.95 21.45Q26.95 19.85 25.85 18.775Q24.75 17.7 23 17.7Q21.5 17.7 20.475 18.4Q19.45 19.1 18.95 20.25L20.85 21.15Q21.1 20.6 21.7 20.2Q22.3 19.8 23 19.8Q23.9 19.8 24.4 20.3Q24.9 20.8 24.9 21.5Q24.9 22.5 24.4 23.175Q23.9 23.85 23.05 24.6Q21.9 25.65 21.025 26.45Q20.15 27.25 19 28.35ZM34.7 30.3Q36.5 30.3 37.675 29.425Q38.85 28.55 38.85 26.7Q38.85 25.45 38.2 24.65Q37.55 23.85 36.5 23.75V23.7Q37.45 23.4 37.925 22.725Q38.4 22.05 38.4 20.95Q38.4 19.45 37.325 18.575Q36.25 17.7 34.65 17.7Q33.55 17.7 32.55 18.3Q31.55 18.9 30.9 20.05L32.6 20.95Q33 20.4 33.525 20.075Q34.05 19.75 34.65 19.75Q35.4 19.75 35.875 20.175Q36.35 20.6 36.35 21.35Q36.35 22.2 35.775 22.5Q35.2 22.8 34.25 22.8H33.55V24.75H34.3Q35.25 24.75 36.025 25.25Q36.8 25.75 36.8 26.7Q36.8 27.6 36.225 28.075Q35.65 28.55 34.7 28.55Q33.95 28.55 33.25 28.05Q32.55 27.55 32.25 26.7L30.5 27.35Q30.9 28.7 31.925 29.5Q32.95 30.3 34.7 30.3ZM7 37H41Q41 37 41 37Q41 37 41 37V11Q41 11 41 11Q41 11 41 11H7Q7 11 7 11Q7 11 7 11V37Q7 37 7 37Q7 37 7 37ZM7 37Q7 37 7 37Q7 37 7 37V11Q7 11 7 11Q7 11 7 11Q7 11 7 11Q7 11 7 11V37Q7 37 7 37Q7 37 7 37Z" />
    </svg>
  );
};

export default CodeSVG;
