import React from "react";
import BlueButton from "../../blue-button";
import DashboardLayout from "../dashboard-layout";
import FakeTable from "../fake-table";
import Dropdown from "./dropdown";
import WalletsNav from "./wallets-nav";

type WithdrawalTemplateProps = {
  symbol: string;
};

const WithdrawalTemplate = (props: WithdrawalTemplateProps) => {
  return (
    <DashboardLayout activeTab={-1}>
      <WalletsNav title="Withdrawal" activeTab={2}></WalletsNav>
      <div className="py-4 mb-1">
        <Dropdown firstWallet={props.symbol} />
      </div>
      <div className="py-2 mb-4 text-xs text-gray-600 leading-5">
        Do not withdraw directly to a crowdfund or ICO address, as your account will not be credited
        with tokens from such sales.
      </div>
      <div className="py-4 mb-4">
        <div className="text-xs font-bold mb-2">Wallet Address</div>
        <input
          className="w-full py-2 px-4 text-sm rounded border"
          placeholder="Wallet Address"
          type="text"
          disabled
        ></input>
      </div>
      <div className="py-4 mb-4">
        <div className="text-xs font-bold mb-2">Comment</div>
        <input
          className="w-full py-2 px-4 text-sm rounded mr-4 border"
          placeholder="Comment"
          type="text"
          disabled
        ></input>
      </div>
      <div className="py-4 mb-4">
        <div className="text-xs font-bold mb-2">Amount</div>
        <div className="w-full flex items-start justify-between">
          <div className="contents">
            <input
              className="w-full py-2 px-4 text-sm rounded mr-4 border"
              placeholder="Amount"
              type="text"
              disabled
            ></input>
          </div>
          <div className="flex justify-right">
            <BlueButton text={"Withdraw"} anchor={"#"} minimal={true} />
          </div>
        </div>
      </div>
      <div className="border rounded-lg my-14 p-4 drop-shadow">
        <div className="text-sm font-bold">Withdraw Limit</div>
        <div className="my-4">
          <strong className="font-semibold">0.00000000</strong>
          <span className="px-2">/</span>
          <strong className="font-semibold">2</strong>
          <span className="pl-2 text-gray-500 font-semibold">BTC</span>
        </div>
        <BlueButton text="Increase" anchor="/dashboard/settings/verification" minimal={true}></BlueButton>
      </div>
      <div className="py-4">
        <FakeTable
          title="Withdraw History"
          headings={["Wallet Address", "Amount", "Status / Date"]}
        />
      </div>
    </DashboardLayout>
  );
};

export default WithdrawalTemplate;
