import React, { PropsWithChildren } from "react";
import "./../../styles/doc.css";

type DocTemplateProps = PropsWithChildren<{}>;

const DocTemplate = (props: DocTemplateProps) => {
  return <div className="relative container doc-template py-10 sm:py-20">{props.children}</div>;
};

export default DocTemplate;
