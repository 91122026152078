import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Lang } from '../model/enums';
import Footer from './footer/footer';
import Header from './header/header';
import FacebookLoader from './loaders/facebook-loader';
import Seo from './seo';
import Support from './support';

type LayoutProps = PropsWithChildren<{
  lang?: Lang;
  removePadding?: boolean;
  container?: boolean;
  relative?: boolean;
  withLoading?: boolean;
  supportDisabled?: boolean;
  seoDisabled?: boolean;
}>;

const Layout = (props: LayoutProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteShortName
          }
        }
      }
    `
  );

  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.withLoading) {
      setLoading(true);

      const timeout = setTimeout(() => {
        setLoading(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <>
      {!props.seoDisabled && (
        <Seo
          lang={'en'}
          title={site.siteMetadata.siteShortName + ': Bitcoin Exchange | Cryptocurrency Exchange'}
          description={
            site.siteMetadata.siteShortName +
            ' is the most advanced and secure cryptocurrency exchange to exchange Bitcoin, Ethereum, Litecoin, TRON, USDT, NEO, XRP, and more.'
          }
          meta={[]}
        />
      )}
      <div className="main-backgroung">
        <div className="main-background-gradient"></div>
      </div>
      <Header />
      <main
        className={
          (loading ? 'invisible ' : 'visible ') +
          (props.removePadding ? '' : 'sm:pt-10 ') +
          (props.container ? 'container ' : '') +
          (props.relative ? 'relative ' : '')
        }
      >
        {props.children}
      </main>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center">
          <FacebookLoader />
        </div>
      )}
      <Footer />
      {!props.supportDisabled && <Support />}
    </>
  );
};

export default Layout;
