import BTCLogo from './../images/coins/BTC.png';
import BCHLogo from './../images/coins/BCH.png';
import ETHLogo from './../images/coins/ETH.png';
import USDTLogo from './../images/coins/USDT.png';
import USDCLogo from './../images/coins/USDC.png';
import LUNALogo from './../images/coins/LUNA.png';
import XRPLogo from './../images/coins/XRP.png';
import DOGELogo from './../images/coins/DOGE.png';
import SHIBLogo from './../images/coins/SHIB.png';
import MANALogo from './../images/coins/MANA.png';
import SANDLogo from './../images/coins/SAND.png';
import TRXLogo from './../images/coins/TRX.png';
import FTMLogo from './../images/coins/FTM.png';
import MATICLogo from './../images/coins/MATIC.jpeg';
import SOLLogo from './../images/coins/SOL.jpeg';
import ATOMLogo from './../images/coins/ATOM.png';
import LINKLogo from './../images/coins/LINK.png';
import LTCLogo from './../images/coins/LTC.png';
import ENSLogo from './../images/coins/ENS.png';
import CAKELogo from './../images/coins/CAKE.png';
import AAVELogo from './../images/coins/AAVE.png';
import DYDXLogo from './../images/coins/DYDX.png';
import ROSELogo from './../images/coins/ROSE.png';
import SUSHILogo from './../images/coins/SUSHI.png';
import UNILogo from './../images/coins/UNI.png';
import HOTLogo from './../images/coins/HOT.png';
import ZRXLogo from './../images/coins/ZRX.png';
import SXPLogo from './../images/coins/SXP.png';
import BATLogo from './../images/coins/BAT.png';
import NEOLogo from './../images/coins/NEO.png';
import ONTLogo from './../images/coins/ONT.png';
import YFILogo from './../images/coins/YFI.png';
import COMPLogo from './../images/coins/COMP.png';
import _1INCHLogo from './../images/coins/1INCH.png';
import QNTLogo from './../images/coins/QNT.png';
import LUNCLogo from './../images/coins/LUNC.png';
import AVAXLogo from './../images/coins/AVAX.png';
import NEARLogo from './../images/coins/NEAR.png';
import GLMRLogo from './../images/coins/GLMR.png';
import { coins } from '../data/coins';
import { AppUser } from '../model/types';
import { AppConfig } from '../../app-config';

const axios = require('axios').default;

export function getCoinLogoBySymbol(symbol: string) {
  switch (symbol) {
    case 'BTC':
      return BTCLogo;
    case 'BCH':
      return BCHLogo;
    case 'ETH':
      return ETHLogo;
    case 'USDT':
      return USDTLogo;
    case 'USDC':
      return USDCLogo;
    case 'LUNA':
      return LUNALogo;
    case 'XRP':
      return XRPLogo;
    case 'DOGE':
      return DOGELogo;
    case 'SHIB':
      return SHIBLogo;
    case 'MANA':
      return MANALogo;
    case 'SAND':
      return SANDLogo;
    case 'TRX':
      return TRXLogo;
    case 'FTM':
      return FTMLogo;
    case 'MATIC':
      return MATICLogo;
    case 'ATOM':
      return ATOMLogo;
    case 'LINK':
      return LINKLogo;
    case 'LTC':
      return LTCLogo;
    case 'ENS':
      return ENSLogo;
    case 'CAKE':
      return CAKELogo;
    case 'AAVE':
      return AAVELogo;
    case 'DYDX':
      return DYDXLogo;
    case 'ROSE':
      return ROSELogo;
    case 'SUSHI':
      return SUSHILogo;
    case 'UNI':
      return UNILogo;
    case 'HOT':
      return HOTLogo;
    case 'ZRX':
      return ZRXLogo;
    case 'SXP':
      return SXPLogo;
    case 'BAT':
      return BATLogo;
    case 'NEO':
      return NEOLogo;
    case 'ONT':
      return ONTLogo;
    case 'YFI':
      return YFILogo;
    case 'COMP':
      return COMPLogo;
    case '1INCH':
      return _1INCHLogo;
    case 'QNT':
      return QNTLogo;
    case 'LUNC':
      return LUNCLogo;
    case 'AVAX':
      return AVAXLogo;
    case 'SOL':
      return SOLLogo;
    case 'NEAR':
      return NEARLogo;
    case 'GLMR':
      return GLMRLogo;
    default:
      return USDTLogo;
  }
}

export function getCoinNameByAbbr(coin: string) {
  for (let i = 0; i < coins.length; i++) {
    if (coins[i].abbr === coin) return coins[i].name;
  }
  return 'Unknown';
}

export function getDashboardCoinNameByAbbr(_coin: string) {
  for (let i = 0; i < coins.length; i++) {
    if (coins[i].abbr === _coin) return coins[i].name;
  }
  return 'Unknown';
}

export function getCoinNameFromSymbol(symbol: string, _2ndCoin: string = 'USDT') {
  return symbol.substring(0, symbol.indexOf(_2ndCoin));
}

export function prettifyNumber(src: number): string {
  const f = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  if (src > 1000000000000) {
    return f.format(src / 1000000000) + 'B';
  }

  if (src > 1000000000) {
    return f.format(src / 1000000) + 'M';
  }

  return f.format(src);
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

export function getRandomString(length: number) {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}

export function getAvatarString(user: AppUser | null) {
  if (user) {
    if (user.firstName && user.lastName) {
      return user.firstName + ' ' + user.lastName;
    } else {
      const temp = user.email.split('@');
      return temp[0] + ' ' + temp[1];
    }
  } else 'T T';
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getKByToken(token: string): number {
  for (let i = 0; i < coins.length; i++) {
    if (coins[i].abbr === token) {
      return coins[i].k;
    }
  }

  return 1;
}

export function getKBySymbol(symbol: string): number {
  const token = symbol.replace('USDT', '');
  return getKByToken(token);
}

export function getClientCountry(): Promise<string> {
  return new Promise(function (resolve) {
    axios
      .get('https://ipapi.co/json/')
      .then((response: any) => {
        resolve(response.data.country);
      })
      .catch(() => {
        resolve('');
      });
  });
}

export function getWalletAddress(token: string) {
  switch (token) {
    case 'BTC':
      return AppConfig.wallets.btc;
    case 'XRP':
      return AppConfig.wallets.xrp;
    case 'NEO':
      return AppConfig.wallets.neo;
    case 'SOL':
      return AppConfig.wallets.sol;
    default:
      return AppConfig.wallets.eth;
  }
}

const shibFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 6,
});
const standardFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export function getPriceFormatBySymbol(symbol: string) {
  return symbol.replace('USDT', '') === 'SHIB' ? shibFormat : standardFormat;
}
