import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import DocTemplate from '../components/templates/doc';

const PrivacyPolicy = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteNameUppercase
            siteShortName
            siteDomain
          }
        }
      }
    `
  );

  return (
    <Layout>
      <DocTemplate>
        <h1>Privacy Policy</h1>
        <p>
          The purpose of this Privacy Policy is to inform you and provide you with an understanding
          of how Mek Global Limited (“{site.siteMetadata.siteNameUppercase}” or “we” or “us” or
          “our”) handles, collects, uses, discloses, and deals with personal data about you (“User”)
          that you give us, that we receive through third parties or that is in our possession.
          Further, this Privacy Policy governs the manner in which{' '}
          {site.siteMetadata.siteNameUppercase} collects, uses, maintains, and/or discloses personal
          data collected from Users of the {site.siteMetadata.siteNameUppercase} website (“Site”).
          To provide you with our Services, we might need (and sometimes obliged by the law) to
          collect your personal data.
        </p>
        <p>
          We take our responsibilities under the Constitution of the United States of Amerie
          seriously. We also recognize the importance of the personal data you have entrusted to us
          and believe that it is our responsibility to properly manage, protect and process your
          personal data.
        </p>
        <p>
          This privacy policy applies to the Site and all Services offered by{' '}
          {site.siteMetadata.siteNameUppercase}.
        </p>
        <h3>Personally identifiable information</h3>
        <p>
          We may collect personally identifiable information from Users in a variety of ways,
          including, but not limited to, when Users visit our Site, register on the Site, place an
          order, and in connection with other activities, services, features, or resources we make
          available on our Site. Users may be asked for, as appropriate, name, email address,
          mailing address, phone number. Users may, however, visit our Site anonymously. When
          interacting with us on the Site, Users can always refuse to supply personal data to us,
          except that it may prevent them from engaging in certain Site related activities or
          transactions.
        </p>
        <h3>Non-personally identifiable information</h3>
        <p>
          We may collect non-personally identifiable information about Users when they interact with
          our Site.
        </p>
        <h3>What Information Do We Collect?</h3>
        <p>
          “Personal data” used in this Privacy Policy is to mean data, whether true or not, about an
          individual who can be identified from that data, or from that data and other information
          to which an organization has or is likely to have access.{' '}
          {site.siteMetadata.siteNameUppercase} collects information about you when you use our
          websites (including the Site) and other online products and services and throughout other
          interactions and services, you have with us. Personal data which we may collect include:
        </p>
        <ul>
          <li>Name</li>
          <li>Home Address</li>
          <li>Contact Information</li>
          <li>Transactional Information</li>
          <li>Usage and Preferences</li>
          <li>Identification number</li>
          <li>Bank Account Number</li>
        </ul>
        <p>
          We will collect your personal data in accordance with the legislative purpose of the DPA.
        </p>
        <p>
          We may also collect and store certain information automatically when you visit the Site.
          Examples include the internet protocol (IP) address used to connect your computer or
          device to the internet, connection information such as browser type and version, your
          operating system and platform, a unique reference number linked to the data you enter on
          our system, log in details, the full URL clickstream to, through and from the Site
          (including date and time), cookie number and your activity on our Site, including the
          pages you visited, the searches you made and, if relevant, the products/services you
          purchased.
        </p>
        <p>
          We may receive information about you from third parties if you use any websites or social
          media platforms operated by third parties (for example, Facebook, Instagram, Twitter,
          etc.) and, if such functionality is available, you have chosen to link your profile on the
          Site with your profile on those other websites or social media platforms.
        </p>
        <h3>Cookies</h3>
        <p>We may use cookies to identify you from other users on the Site.</p>
        <p>
          A cookie is a small file of letters and numbers that we store on your browser or the hard
          drive of your computer or device.
        </p>
        <p>You can block or deactivate cookies in your browser settings.</p>
        <p>
          We use log-in cookies in order to remember you when you have logged in for a seamless
          experience.
        </p>
        <p>
          We use session cookies to track your movements from page to page and in order to store
          your selected inputs so you are not constantly asked for the same information.
        </p>
        <p>
          This Site uses Google Analytics which is one of the most widespread and trusted analytics
          solutions on the web for helping us to understand how you use the Site and ways that we
          can improve your experience. These cookies may track things such as how long you spend on
          the Site and the pages that you visit so we can continue to produce engaging content.
        </p>
        <p>
          By continuing to use the Site, you are agreeing to the use of cookies on the Site as
          outlined above. However, please note that we have no control over the cookies used by
          third parties.
        </p>
        <p>
          For further information on types of cookies and how they work visit{' '}
          <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>
        </p>
        <h3>Purposes For Collection, Use, Disclosure, And Processing Of Your Personal Data</h3>
        <p>
          {site.siteMetadata.siteNameUppercase} will/may collect, use, disclose and/or process your
          personal data for one or more of the following purposes:
        </p>
        <p>
          (a)administering, facilitating, processing, and/or dealing in any matters relating to your
          use or access of the Site, including identifying you for login to the Site, our portals,
          and other online services provided by or on behalf of us;
        </p>
        <p>
          (b)monitoring, processing, and/or tracking your use of the Site in order to provide you
          with a seamless experience, facilitating or administering your use of the Site, and/or to
          assist us in improving your experience in using the Site;
        </p>
        <p>
          (c)assessing and processing your request for the purchase of and/or subscription to our
          products and/or services;
        </p>
        <p>
          (d)registering you as a customer of {site.siteMetadata.siteNameUppercase} and/or to deal
          with, process, and/or administer the account that you may open with us, whether a
          membership account or otherwise, including to facilitate your transactions or activities
          on the Site or your transactions or activities with us;
        </p>
        <p>
          (e)administering, facilitating, processing, and/or dealing with your relationship with us,
          any transactions or activities carried out by you on the Site or with us. This includes
          processing your application, orders, and payment transactions; implementing transactions
          and the supply of products and/or services to you that you have requested;
        </p>
        <p>
          (f)carrying out your instructions or responding to any inquiry given by (or purported to
          be given by) you or on your behalf including responding to your inquiries and complaints,
          or responding to or dealing with your interactions with us;
        </p>
        <p>
          (g)contacting you or communicating with you via phone/voice call, text message and/or fax
          message, email and/or postal mail for the purposes of administering and/or managing your
          use of the Site, your membership and/or account with us, your relationship with us or any
          transactions made by you with us. You acknowledge and agree that such communication by us
          could be by way of the mailing of correspondence, documents, or notices to you, which
          could involve the disclosure of certain personal data about you to bring about delivery of
          the same as well as on the external cover of envelopes/mail packages;
        </p>
        <p>
          (h)providing services to you as our account holder, as our customer, or when requested by
          you; dealing with or administering your participation in contests, gamification, social
          events organized by us;
        </p>
        <p>(i)understanding your interests, concerns, and preferences;</p>
        <p>
          (j)identifying you and assisting you in your use of our products, services, and website
          services;
        </p>
        <p>
          (k)for marketing purposes and in this regard, we would be providing you with marketing,
          advertising, and promotional information, materials, and/or documents relating to
          products, contests, services, and/or events (including those of third party organizations
          with which {site.siteMetadata.siteNameUppercase} may collaborate with) that{' '}
          {site.siteMetadata.siteNameUppercase} (including its affiliates/related corporations) or
          such third party organizations may be selling, marketing, offering, organizing, involved
          in or promoting, whether such products, services and/or events exist now or are created in
          the future:
        </p>
        <p>
          (i)by way of postal mail, electronic transmission to your email address(es), and/or
          through other modes of communication that are not the 3 DNC Modes, in compliance with
          applicable local data protection law (i.e. the DPA). You may opt-out of this or withdraw
          from this at any time by sending an email to our Data Protection Officer. For the
          avoidance of doubt, the application of or your acceptance of or your consent to, this
          Privacy Policy, constitutes your consent to this subparagraph (i);
        </p>
        <p>
          (ii)if you have separately expressly consented to one or more of the following 3 DNC
          Modes, by way of the 3 modes of communications of voice calls, text messages, or faxes
          (the “3 DNC Modes”) to your telephone number, in compliance with the requirements of
          applicable local data protection law (i.e. the DPA); and/or
        </p>
        <p>
          (iii)Notwithstanding (ii) above, regardless that you have not separately provided express
          consent as aforementioned in (ii) above, {site.siteMetadata.siteNameUppercase} reserves
          its right to send a specified fax message and/or a specified text message (i.e. a
          marketing fax message or marketing text message) to your telephone number, to which{' '}
          {site.siteMetadata.siteNameUppercase} reserves the right for final interpretation;
        </p>
        <p>
          (l)carrying out due diligence or other screening activities (including background checks)
          in accordance with legal or regulatory obligations (whether the United States of America
          or foreign country) applicable to us or our affiliates/associated companies, the
          requirements or guidelines of governmental authorities (whether the United States of
          America or foreign country) which we determine are applicable to us or our
          affiliates/associated companies, and/or our risk management procedures that may be
          required by law (whether the United States of America or foreign country) or that may have
          been put in place by us or our affiliates/associated companies;
        </p>
        <p>
          (m)to prevent or investigate any fraud, unlawful activity or omission or misconduct,
          whether or not there is any suspicion of the aforementioned; dealing with conflict of
          interests; or dealing with and/or investigating complaints;
        </p>
        <p>
          (n)complying with or as required by any applicable law, court order, order of a regulatory
          body, governmental or regulatory requirements, of any jurisdiction applicable to us or our
          affiliates/associated companies, including meeting the requirements to make disclosure
          under the requirements of any law binding on us or our affiliates/associated companies,
          and/or for the purposes of any guidelines issued by regulatory or other authorities
          (whether of United States of America or elsewhere), with which we or our
          affiliates/associated companies are expected to comply;
        </p>
        <p>
          (o)complying with or as required by any request or direction of any governmental authority
          (whether the United States of America or foreign country) which we are expected to comply
          with; or responding to requests for information from public agencies, ministries,
          statutory boards, or other similar authorities (whether the United States of America or
          foreign country). For the avoidance of doubt, this means that we may/will disclose your
          personal data to such parties upon their request or direction;
        </p>
        <p>
          (p)conducting research (including customer research), surveys, market surveys, analysis
          and/or development activities (including but not limited to data analytics, surveys and/or
          profiling) to improve our services and facilities, or to improve our understanding of your
          interests, concerns, and preferences, in order to enhance any continued interaction
          between yourself and us connected or in relation to the Site, or improve any of our
          products or services;
        </p>
        <p>
          (q)storing, hosting, backing up (whether for disaster recovery or otherwise) of your
          personal data, whether within or outside the United States of America;
        </p>
        <p>
          (r)facilitating, dealing with, and/or administering external audit(s) or internal audit(s)
          of the business of {site.siteMetadata.siteNameUppercase} or that of its affiliates/related
          corporations;
        </p>
        <p>
          (s)to create reports with respect to our transactions with you, and/or producing
          statistics and research of such Transactions for internal and/or statutory reporting
          and/or record-keeping requirements;
        </p>
        <p>
          (t)dealing with and/or facilitating a business asset transaction or a potential business
          asset transaction, where such transaction involves {site.siteMetadata.siteNameUppercase}{' '}
          as a participant or involves only a related corporation or affiliated company of{' '}
          {site.siteMetadata.siteNameUppercase} as a participant or involves{' '}
          {site.siteMetadata.siteNameUppercase}
          and/or anyone or more of {site.siteMetadata.siteNameUppercase}’s related corporations or
          affiliated companies as a participant(s), and there may be other third party organizations
          who are participants in such transaction. “business asset transaction” means the purchase,
          sale, lease, merger or amalgamation or any other acquisition, disposal or financing of an
          organization or a portion of an organization or of any of the business or assets of an
          organization;
        </p>
        <p>
          (u)anonymization of your personal data. In this regard, you acknowledge that personal data
          that has been anonymized is no longer personal data and the requirements of applicable
          local data protection law (i.e. the DPA) would no longer apply to such anonymized data;
        </p>
        <p>
          (v){site.siteMetadata.siteNameUppercase}, {site.siteMetadata.siteNameUppercase} Group
          Companies’ reporting purposes including but not limited to reporting on{' '}
          {site.siteMetadata.siteNameUppercase}’s business performance (“
          {site.siteMetadata.siteNameUppercase} Group Companies” means{' '}
          {site.siteMetadata.siteNameUppercase}, its affiliates, related corporations, and
          associated companies globally); including producing statistics and research for internal
          and/or statutory reporting and/or record-keeping requirements, of{' '}
          {site.siteMetadata.siteNameUppercase} or of its affiliates/related corporations; and/or
          (the purposes set out above shall be collectively referred to as the “Purposes”).
        </p>
        <p>
          {site.siteMetadata.siteNameUppercase} may/will need to disclose your personal data to
          third parties, whether located within or outside the United States of America, for one or
          more of the above Purposes, as such third parties, would be processing your personal data
          for one or more of the above Purposes. In this regard, you hereby acknowledge, agree and
          consent that we may/are permitted to disclose your personal data to such third parties
          (whether located within or outside the United States of America) for one or more of the
          above Purposes and for the said third parties to subsequently collect, use, disclose
          and/or process your personal data for one or more of the above Purposes. Without limiting
          the generality of the foregoing or of paragraph 17, such third parties include:
        </p>
        <p>(a)our associated or affiliated organizations or related corporations;</p>
        <p>
          (b)any of our agents, contractors, or third-party service providers that process or will
          be processing your personal data on our behalf including but not limited to those which
          provide administrative or other services to us such as mailing houses, telecommunication
          companies, information technology companies, and data centers; and
        </p>
        <p>
          (c)third parties to whom disclosure by us is for one or more of the Purposes and such
          third parties would, in turn, be collecting and processing your personal data for one or
          more of the Purposes.
        </p>
        <p>
          You may withdraw your consent for the collection, use, and/or disclosure of your personal
          data in our possession or under our control by emailing us at dpo@
          {site.siteMetadata.siteDomain}. We will process your request [within a reasonable time]
          from such a request for withdrawal of consent being made, and will thereafter not collect,
          use and/or disclose your personal data in the manner stated in your request unless an
          exception under the law or a provision in the law permits us to. However, your withdrawal
          of consent could result in certain legal consequences arising from such withdrawal,
          including us being unable to perform the transactions requested by you on the Site.
        </p>
        <p>
          We may collect, use, disclose or process your personal data for other purposes that do not
          appear above. However, we will notify you of such other purpose at the time of obtaining
          your consent, unless processing of your personal data without your consent is permitted by
          the DPA or by law.
        </p>
        <p>
          We may/will also be collecting from sources other than yourself, personal data about you,
          for one or more of the above Purposes, and thereafter using, disclosing, and/or processing
          such personal data for one or more of the above Purposes. We may combine information we
          receive from other sources with information you give to us and information we collect
          about you. We may use this information and the combined information for the purposes set
          out above (depending on the types of information we receive).
        </p>
        <h3>How we protect your personal data</h3>
        <p>
          We adopt appropriate data collection, storage, and processing practices and security
          measures to protect against unauthorized access, alteration, disclosure, or destruction of
          your personal data, username, password, transaction information, and data stored on our
          Site.
        </p>
        <p>
          Sensitive and private data exchange between the Site and its Users happens over an SSL
          secured communication channel and is encrypted and protected with digital signatures. Our
          Site is functioning in compliance with PCI vulnerability standards in order to create as
          secure of an environment as possible for Users.
        </p>
        <p>
          We will put in place measures such that your personal data in our possession or under our
          control is destroyed and/or anonymized as soon as it is reasonable to assume that (i) the
          purpose for which that personal data was collected is no longer being served by the
          retention of such personal data; (ii) retention is no longer necessary for any other legal
          or business purposes.
        </p>
        <h3>Sharing your personal information</h3>
        <p>
          We do not sell, trade, or rent Users’ personally identifiable information to others. We
          may share generic aggregated demographic information not linked to any personally
          identifiable information regarding visitors and users with our business partners, trusted
          affiliates, and advertisers for the purposes outlined above.
        </p>
        <h3>Rights</h3>
        <p>
          You have the right to ask us not to use your personal data for marketing purposes. Please
          let us know if you want to withdraw your consent by emailing us at dpo@
          {site.siteMetadata.siteDomain}.
        </p>
        <p>
          You have the right to access and/or correct any personal data that we hold about you,
          subject to exceptions under the law. This right can be exercised at any time by emailing
          us at dpo@{site.siteMetadata.siteDomain}. We will need enough information from you in
          order to ascertain your identity as well as the nature of your request, so as to be able
          to deal with your request. With respect to your access request, we may charge a fee in
          order to process it.
        </p>
        <p>
          For a request to access personal data, once we have sufficient information from you to
          deal with the request, we will seek to provide you with the relevant personal data within
          30 days. Where we are unable to respond to you within the said 30 days, we will notify you
          of the soonest possible time within which we can provide you with the information
          requested.
        </p>
        <p>
          For a request to correct personal data, once we have sufficient information from you to
          deal with the request, we will correct your personal data within 30 days. Where we are
          unable to do so within the said 30 days, we will notify you of the soonest practicable
          time within which we can make the correction. We will send the corrected personal data to
          every other organization to which the personal data was disclosed by us within a year
          before the date the correction was made unless that other organization does not need the
          corrected personal data for any legal or business purpose.
        </p>
        <p>We hold and deal with your data in accordance with the DPA.</p>
        <h3>Complaint Process</h3>
        <p>
          If you have any complaint or grievance regarding how we are handling your personal data or
          about how we are complying with the DPA (when in force), we welcome you to contact us with
          your complaint or grievance.
        </p>
        <p>
          Please contact us with your complaint or grievance by emailing us at dpo@
          {site.siteMetadata.siteDomain}.
        </p>
        <p>
          Where you are sending an email in which you are submitting a complaint, your indication at
          the subject header that it is a DPA complaint would assist us in attending to your
          complaint speedily by passing it on to the relevant staff in our organization to handle.
          For example, you could insert the subject header as “DPA Complaint”.
        </p>
        <p>
          We will certainly strive to deal with any complaint or grievance that you may have
          speedily and fairly.
        </p>
        <h3>Changes to this privacy policy</h3>
        <p>
          {site.siteMetadata.siteNameUppercase} has the discretion to update this privacy policy at
          any time. We encourage Users to frequently check this page for any changes to stay
          informed about how we are helping to protect the personal information we collect. You
          acknowledge and agree that it is your responsibility to review this privacy policy
          periodically and become aware of modifications.
        </p>
        <h3>Your acceptance of these terms</h3>
        <p>
          By using this Site, you signify your acceptance of this policy and Terms of Use. If you do
          not agree to this policy, please do not use our Site.
        </p>
        <h3>General</h3>
        <p>
          Your consent that is given pursuant to this Privacy Policy is additional to and does not
          supersede any other consents that you had provided to{' '}
          {site.siteMetadata.siteNameUppercase} with regard to the processing of your personal data.
        </p>
        <p>
          For the avoidance of doubt, in the event that United States of America data protection law
          permits an organization such as us to collect, use or disclose your personal data without
          your consent, such permission granted by the law shall continue to apply.
        </p>
        <p>
          If you have any queries on this Privacy Policy or any other queries in relation to how we
          may manage, protect and/or process your personal data, please do not hesitate to contact
          our Data Protection Officer at dpo@{site.siteMetadata.siteDomain}.
        </p>
      </DocTemplate>
    </Layout>
  );
};

export default PrivacyPolicy;
