import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import DocTemplate from "../components/templates/doc";

const TermsOfUse = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteName
            siteShortName
            siteDomain
          }
        }
      }
    `
  );

  return (
    <Layout>
      <DocTemplate>
        <h1>Terms of Use</h1>
        <h2>ACCEPTANCE OF TERMS OF USE</h2>
        <p>
          By using this Site, registering for a {site.siteMetadata.siteName} account ("Account") or
          using any other services, products, data, content, or other material available through the
          Site ("Services"), you ("you, your, or yourself") are agreeing to accept and comply with
          the terms and conditions of use stated below ("Terms of Use"). You should read the entire
          Terms of Use carefully before using this Site or any of the Services.
        </p>
        <p>The Services allow registered users of the Services to:</p>
        <ul>
          <li>
            Buy or sell Virtual Assets and Digital Assets from or to other Members in exchange for
            other Virtual Assets or Digital Assets, or for fiat currency;
          </li>
          <li>
            Enter into arrangements to store Virtual Assets and Digital Assets listed on the Site
            with a third-party custodian;
          </li>
          <li>Withdraw any fiat currency balance held in your Account;</li>
          <li>
            Transfer Virtual Assets and Digital Assets to a wallet or to other users of such Virtual
            Assets and Digital Assets outside the Site;
          </li>
          <li>Use Virtual Assets for purchasing goods; and</li>
          <li>Access and use the Ripple protocol.</li>
        </ul>
        <p>
          Depending on your country of residence, you may not be able to use all the functions of
          the Site or Services. It is your responsibility to follow the rules and laws in your
          country of residence and/or country from which you access this Site and the Services. As
          long as you agree to and comply with these Terms of Use. grants you the personal,
          non-exclusive, non-transferable, non-sublicensable, and limited right to enter and use the
          Site and the Services.
        </p>
        <p>
          IF YOU DO NOT ACCEPT THE TERMS OF USE AND CONDITIONS OUTLINED IN THIS AGREEMENT, DO NOT
          ACCESS THIS SITE AND DO NOT USE THE SERVICES.
        </p>
        <p>By opening an Account, you expressly represent and warrant:</p>
        <ul>
          <li>That you have accepted you are legally bound by these Terms of Use; and</li>
          <li>
            That you are at least 18 years of age and have the full capacity to accept these Terms
            of Use and enter into a transaction involving Virtual Assets and other Digital Assets.
          </li>
        </ul>
        <h2>RISKS</h2>
        <p>
          The trading of goods and products, real or virtual, as well as virtual currencies involves
          significant risk. Prices can and do fluctuate on any given day. Such price fluctuations
          may increase or decrease the value of your assets at any given moment. Any currency -
          virtual or not - may be subject to large swings in value and may even become worthless.
          There is an inherent risk that losses will occur as a result of buying, selling, or
          trading anything on a market.
        </p>
        <p>
          Virtual Asset and Digital Asset trading also have special risks not generally shared with
          official currencies, goods, or commodities in a market. Unlike most currencies, which are
          backed by governments or other legal entities, or by commodities such as gold or silver,
          Virtual Assets, and Digital Assets are unique types of assets, backed by technology and
          trust. There is no central bank that can issue more cryptocurrency or take corrective
          measures to protect the value of Virtual Assets and Digital Assets in a crisis.
        </p>
        <p>
          Instead, Virtual Assets and Digital Assets are an as-yet autonomous and largely
          unregulated global system of cryptocurrency firms and individuals. Traders put their trust
          in a digital, decentralized, and partially anonymous system that relies on peer-to-peer
          networking and cryptography to maintain its integrity.
        </p>
        <p>
          The trading of Virtual Assets and Digital Assets is often susceptible to irrational (or
          rational) bubbles or loss of confidence, which could collapse demand relative to supply.
          For example, confidence in Virtual Assets and Digital Assets might collapse because of
          unexpected changes imposed by software developers or others, a government crackdown, the
          creation of superior competing alternative currencies, or a deflationary or inflationary
          spiral. Confidence might also collapse because of technical problems: if the anonymity of
          the system is compromised, if money is lost or stolen, or if hackers or governments are
          able to prevent any transactions from settling.
        </p>
        <p>
          There may be additional risks that we have not foreseen or identified in our Terms of Use.
        </p>
        <p>
          You should carefully assess whether your financial situation and tolerance for risk is
          suitable for buying, selling, or trading Virtual Assets or Digital Assets.
        </p>
        <p>
          We use our banking providers in order to facilitate the receipt of fiat currency from
          Members and payments to other Members. Our banking providers DO NOT transfer, exchange or
          provide any services in connection with Virtual Assets or Digital Assets. Your fiat
          currency will be held in a bank account in the name of {site.siteMetadata.siteName}{" "}
          together with other Members' fiat currency. Where possible in a relevant jurisdiction that
          recognizes the concept of a client account, we will notify the relevant banking provider
          that the bank account is a client account in which we hold fiat currency on behalf of
          Members. You will, in any event, remain the beneficial owner of the fiat currency that we
          hold on your behalf. We will maintain detailed records of all fiat currency held on behalf
          of Members. Whilst {site.siteMetadata.siteName} uses reasonable care in the appointment of
          its banking providers, in the event of a banking provider becoming insolvent or entering
          into an insolvency process in a relevant jurisdiction, {site.siteMetadata.siteName} may
          have only an unsecured claim against the banking provider, and Members' fiat currency
          balances may be at risk subject to any protections provided at law in the relevant
          jurisdiction.
        </p>
        <p>
          We engage with third-party custodians in order to hold Virtual Assets and Digital Assets
          on your behalf. Your Virtual Assets and Digital Assets will be held on your behalf in a
          wallet in the name of {site.siteMetadata.siteName} together with other Members' Virtual
          Assets and/or Digital Assets. They may also from time to time be held in the wallet
          together with {site.siteMetadata.siteName} Virtual Assets and/or Digital Assets which
          reflect {site.siteMetadata.siteName} fees, but which are recorded on{" "}
          {site.siteMetadata.siteName} ledger as belonging to {site.siteMetadata.siteName}, and
          which you instruct {site.siteMetadata.siteName} to collect by withdrawing them from the
          wallet from time to time). We will maintain detailed records of all Virtual Assets and
          Digital Assets which Members hold with third-party custodians appointed by us.
        </p>
        <p>
          As set out below, {site.siteMetadata.siteName} will act only on your instructions in
          respect of Virtual Assets and Digital Assets and does not otherwise acquire any right,
          title, or interest in them. Whilst {site.siteMetadata.siteName} uses reasonable care in
          the appointment of third-party custodians, in the event of a third-party custodian
          becoming insolvent or entering into an insolvency process in a relevant jurisdiction, you
          may have only an unsecured claim against the third-party custodian, and Members' Virtual
          Assets or Digital Assets may be at risk subject to any protections provided at law in the
          relevant jurisdiction.
        </p>
        <h2>LIMITED RIGHT OF USE</h2>
        <p>
          Unless otherwise specified, all materials on this Site are the property of{" "}
          {site.siteMetadata.siteName} and are protected by copyright, trademark, and other
          applicable laws. You may view, print, and/or download a copy of the materials from this
          Site on any single computer solely for your personal, informational, and/or non-commercial
          use, provided you comply with all copyright and other proprietary notices.
        </p>
        <p>
          The trademarks, service marks, and logos of {site.siteMetadata.siteName} and others used
          in this Site ("Trademarks'') are the property of {site.siteMetadata.siteName} and their
          respective owners. The software, text, images, graphics, data, prices, trades, charts,
          graphs, video, and audio used on this Site belong to {site.siteMetadata.siteName}. The
          Trademarks and material should not be copied, reproduced, modified, republished, uploaded,
          posted, transmitted, scraped, collected, or distributed in any form or by any means,
          whether manual or automated. The use of any such materials on any other Site or networked
          computer environment for any other purpose is strictly prohibited; any such unauthorized
          use may violate copyright, trademark, and other applicable laws and could result in
          criminal or civil penalties.
        </p>
        <h2>MAINTAINING YOUR ACCOUNT: OUR RULES AND ROLE</h2>
        <p>
          This Site and the Services are for your personal use only. We are vigilant in maintaining
          the security of our Site and the Services. By registering with us, you agree to provide{" "}
          {site.siteMetadata.siteName} with current, accurate, and complete information about
          yourself, as prompted by the registration process, and to keep such information updated.
          You further agree that you will not use any Account other than your own or access the
          Account of any other Member at any time or assist others in obtaining unauthorized access.
        </p>
        <p>
          {site.siteMetadata.siteName} role is limited to providing you with a platform via the
          Services under which we act solely as a service provider, as your agent, and in accordance
          with your instructions for the management and transfer of Virtual Assets and other Digital
          Assets. We do not store any Virtual Assets or other Digital Assets directly, but where you
          Instruct us to arrange for any Virtual Assets or other Digital Assets owned by you
          (including any bought on your behalf) to be held until we receive further instruction from
          you to sell or transfer them (with any deposit of Digital Assets and/or Virtual Assets
          being deemed to be such an Instruction), we will, acting on your behalf, appoint a
          third-party as a custodian and to hold the relevant Virtual Assets or other Digital Assets
          as a custodian in accordance with our agency authority (see below). The custodian services
          will be provided on terms that apply directly between the custodian and you, and which
          will be made available to you. {site.siteMetadata.siteName} does not directly provide,
          charge for, and is not responsible for custodian services. Any issues you have relating to
          custodian services should be taken up by you with the custodians, however, we may, on your
          behalf, provide assistance in accordance with our agency authority (see below).
        </p>
        <p>
          Notwithstanding the direct custodial relationship between the custodian and you, if for
          any reason {site.siteMetadata.siteName} is held or declared to have any legal or
          beneficial interest in your Virtual Assets or other Digital Assets,{" "}
          {site.siteMetadata.siteName} will hold on trust for you all such right title and interest
          which {site.siteMetadata.siteName} is held or declared to have and will not transfer or
          sell any Virtual Assets or other Digital Assets other than in accordance with your
          instructions (which includes any instructions mandated by law, regulatory authority or
          enforcement agencies).
        </p>
        <p>
          In order for us to manage the Site, provide the Services and create a direct relationship
          between you and the third-party custodian to hold the relevant Virtual Assets or other
          Digital Assets as custodian on your behalf, you appoint {site.siteMetadata.siteName} as
          your agent with a perpetual and irrevocable (other than in the event of Account
          termination) agency authority to act as your agent to:
        </p>
        <ul>
          <li>
            Create a direct legal-custodial relationship between you and a third-party custodian for
            the custody of your Virtual Assets or other Digital Assets, as well as appointing
            authorized persons within {site.siteMetadata.siteName} to instruct such third-party
            custodians on your behalf;
          </li>
          <li>
            Access and/or transfer Virtual Assets or other Digital Assets as required for the
            operation of the Site and provision of the Services, including:
          </li>
          <ul>
            <li>
              Storing and keeping confidential private keys in respect of Virtual Assets or other
              Digital Assets;
            </li>
            <li>
              Transferring cryptocurrencies to third-party custodians (who are entitled in turn to
              appoint sub-custodians and provide the custodian services to you through such
              sub-custodians or other nominees or agents); and
            </li>
            <li>
              Moving cryptocurrencies between hot and cold wallets held by these custodians to allow
              sufficient hot wallet liquidity to support trades on the Site whilst at the same time
              providing the additional security of cold wallet storage;
            </li>
          </ul>
          <li>
            Share your information with third-party custodian-service providers so that they can be
            engaged to provide custodian services to you (see below);
          </li>
          <li>
            Undertake the following events that act, or could act as restrictions, or impact your
            rights:
          </li>
          <ul>
            <li>Freezing of Ripple accounts in accordance with Ripple Labs policy;</li>
            <li>Termination of our relationship with you and/or your Account;</li>
            <li>Suspension of our relationship with you and/or your Account;</li>
            <li>Prohibiting wash trading and other activities;</li>
            <li>Requiring minimum trade sizes;</li>
            <li>Blocking Accounts in certain circumstances;</li>
            <li>Applying service downtime and unavailability restrictions;</li>
            <li>Complying with enforcement-agency orders or regulatory actions; and</li>
          </ul>
          <li>
            Otherwise act only on your instructions in respect of any Virtual Assets or other
            Digital Assets, including in respect of:
          </li>
          <ul>
            <li>Any buying or selling of Virtual Assets or other Digital Assets;</li>
            <li>
              The accessing or transferring of Virtual Assets or other Digital Assets between
              wallets; and/or
            </li>
            <li>Any other purpose or action as instructed by you.</li>
          </ul>
        </ul>
        <p>
          By agreeing to these Terms of Use you acknowledge and agree that in doing so, you instruct
          us on an irrevocable ongoing basis to undertake all of the above-mentioned actions on your
          behalf. You cannot revoke this ongoing Instruction except by closing your Account, in
          which case it will lapse only when your Account is closed. In these Terms of Use,
          "Instructions" means:
        </p>
        <ul>
          <li>
            Instructions received or directed via the Site or as received directly from you in any
            medium other than via the Site, where {site.siteMetadata.siteName} has expressly agreed
            in writing to accept such instructions from you;
          </li>
          <li>
            Acts {site.siteMetadata.siteName} may take as set out in these Terms of Use or in any
            other form of documentation establishing the legal relationship between you and{" "}
            {site.siteMetadata.siteName}, including instructions within the remit of our agency
            authority set out above (including the Instruction to create the legal custodial
            relationship with the third-party custodian for the purpose of administering the
            Services for you); and
          </li>
          <li>Instructions mandated by law, regulatory authority, or enforcement agencies.</li>
        </ul>
        <p>
          As a result of this limited agency relationship, we do not, and you agree that we do not,
          have or acquire any rights, title, or interest in any Virtual Assets or other Digital
          Assets that are held on your behalf by the third-party custodian.
        </p>
        <p>
          We are not an intermediary, do not acquire any rights, title, or interest in, and do not
          assume and have no rights or control over any Virtual Assets or other Digital Assets or
          any other Member assets other than in respect of actions taken in accordance with our
          agency authority and your Instructions. As a result, we will not transfer or sell any
          Virtual Assets or other Digital Assets other than in accordance with your instructions
          (which includes any instructions mandated by law, regulatory authority, or enforcement
          agencies).
        </p>
        <p>
          The creation or use of Accounts without obtaining prior express permission from{" "}
          {site.siteMetadata.siteName} will result in the immediate suspension of all said Accounts,
          as well as all pending purchase/sale offers. Any attempt to do so or to assist others
          (Members or otherwise), or the distribution of instructions, software, or tools for that
          purpose, will result in the Accounts of such Members being terminated. Termination is not
          the exclusive remedy for such a violation, and {site.siteMetadata.siteName} may elect to
          take further action against you.
        </p>
        <p>
          You are also responsible for maintaining the confidentiality of your Account information,
          including your password, safeguarding your own Virtual Assets and Digital Assets, and all
          activity including Transactions that are posted to your Account (including all
          Instructions to {site.siteMetadata.siteName}). Any actions on the Site, transactions,
          orders, Instructions, and operations initiated from your Account or using your password
          are (1) considered to have been made by you and (2) irrevocable once validated using your
          password or made through your Account. If there is suspicious activity related to your
          Account, we may, but are not obligated to, request additional information from you,
          including authenticating documents, and freeze any transactions pending our review. You
          are obligated to comply with these security requests or accept the termination of your
          Account. You are required to notify
          {site.siteMetadata.siteName} immediately of any unauthorized use of your Account or
          password, or any other breach of security, by email to support@
          {site.siteMetadata.siteDomain} . Any Member who violates these rules may be terminated,
          and thereafter held liable for losses incurred by {site.siteMetadata.siteName} or any user
          of the Site and Services.
        </p>
        <p>
          {site.siteMetadata.siteName} similarly reserves the right to freeze Ripple accounts in
          accordance with the Ripple Labs policy, which took effect on 15 September 2014. Please see
          the explanation provided by Ripple Labs below:
        </p>
        <p>
          "The freeze protocol extension gives gateways the ability to 1) globally freeze all their
          issued funds or 2) freeze funds issued to a specific user. Frozen funds may only be sent
          back to the issuing gateway. The global freeze feature allows a gateway to freeze all
          balances it issues. The gateway may still issue payments. Accounts holding frozen balances
          may return the funds to the gateway. This feature is useful for migrating users from one
          account to another and to safeguard users in the event that the gateway account is
          compromised. The individual freeze is primarily intended for compliance with regulatory
          requirements, which may vary from one jurisdiction to another. It also allows gateways to
          freeze individual accounts issuances in order to investigate suspicious activity. These
          features allow gateways to better operate in compliance with laws and regulations."
        </p>
        <p>
          You will not take, directly or indirectly, any action designed, or that might reasonably
          be expected, to cause or result in destabilization or manipulation of the price of Virtual
          Assets or Digital Assets, which are available on the Site.
        </p>
        <p>
          {site.siteMetadata.siteName} reserves the right to suspend, delay or cancel an Instruction
          or series of Instructions issued by a Member or colluding Members which if executed would
          result in a price swing of 5% or more of the value of a cryptocurrency available on the
          Site.
        </p>
        <p>
          For the avoidance of doubt, any action taken by {site.siteMetadata.siteName} to suspend,
          delay, cancel or freeze any instructions and/or to suspend your Account will not affect
          your rights, title, or interest in respect of your Virtual Assets and other Digital Assets
          which are held by a third-party custodian on your behalf, but may affect{" "}
          {site.siteMetadata.siteName} ability to execute your instructions, which you hereby
          acknowledge and agree.
        </p>
        <p>
          You acknowledge that you will only credit your {site.siteMetadata.siteName} account from a
          bank account that is in your own name and that you will instruct payments from your{" "}
          {site.siteMetadata.siteName} account only to a bank account that is in your own name.
        </p>
        <p>We reserve the right to cancel transfers that are made by or sent to third parties.</p>
        <h3>Wash Trading</h3>
        <p>
          {site.siteMetadata.siteName} does not allow you to cross trade, either alone or in
          collusion with others or to place an order, which would result in a self-execution - i.e.,
          where the same trader or group of traders would act as both the maker and taker for the
          trade.
        </p>
        <p>
          If two orders of opposing sides would result in a crossed trade, both orders are subject
          to specific measures, ranging from scrutiny, cancellation, suspension, or prohibition to
          trade on the Site. If two orders of the same quantity would result in self-execution, both
          orders are subject to specific measures, ranging from scrutiny, cancellation, suspension,
          or prohibition to trade on the Site.
        </p>
        <h3>Market Manipulation</h3>
        <p>
          You are prohibited from engaging or attempting to engage in market manipulation. This
          prohibition applies to any kind of activity that results in or has the purpose of:
        </p>
        <ul>
          <li>
            Artificially controlling or manipulating the price or trading volume of any of the
            virtual currencies listed on the Site; and
          </li>
          <li>
            Manipulating of a benchmark, including, but not limited to, the transmission of false or
            misleading inputs, or any other action that manipulates the calculation of a benchmark.
          </li>
          <li>
            Arbitraging digital assets as individual account users, including, possible attempts of
            laundering stolen funds from third-party exchange platforms
          </li>
        </ul>
        <p>
          Market manipulation will trigger specific measures, ranging from scrutiny, cancellation of
          orders, suspension or prohibition to trade on the Site, and disclosure to third parties
          including full disclosure to competent authorities.
        </p>
        <p>
          Lastly, you agree not to use the Services to perform criminal activity of any sort,
          including but not limited to, money laundering, illegal gambling operations, terrorist
          financing, malicious hacking, or any criminal or illegal activity.
        </p>
        <p>The minimum trade size is defined by the {site.siteMetadata.siteName} Fee Schedule.</p>
        <p>The accounts that were or will be funded did not and will not earn interest.</p>
        <h2>ARBITRAGING DIGITAL ASSETS</h2>
        <p>
          {site.siteMetadata.siteName} forbids individual accounts to perform digital asset
          arbitrage - i.e. depositing funds from third-party exchange service with only the purpose
          to perform the exchange and withdraw funds to a third-party exchange gaining profit on
          price differences between {site.siteMetadata.siteName} and third-party exchange Services
          due to the high possibility of money laundering activity. {site.siteMetadata.siteName}{" "}
          reserves the right to shut down that kind of activity by freezing arbitraged funds and
          forcing you to upgrade your account to Institutional. {site.siteMetadata.siteName}{" "}
          reserves the right to freeze your account until you follow the conditions of this term.
          Individual account, upgraded to Institutional, or account registered as Institutional is
          exempted of following this requirement. {site.siteMetadata.siteName} will not charge you
          any fees for upgrading your account to Institutional while being frozen for performing
          this kind of activity.
        </p>
        <h2>COMMISSIONS</h2>
        <p>
          {site.siteMetadata.siteName} Commissions are available in our Fee Schedule.{" "}
          {site.siteMetadata.siteName} reserves the right to change its Commissions at any time and
          at its sole discretion. You will be notified of any changes in advance through your
          Account or Email. Upon such notification, it is your responsibility to review the amended
          Fee Schedule. Your continued use of the Site following the posting of a notice of
          Commission changes signifies that you accept and agree to the changes.{" "}
          {site.siteMetadata.siteName} will not charge you any fees other than the{" "}
          {site.siteMetadata.siteName} Commissions and other fees set out in the Fee Schedule.{" "}
          {site.siteMetadata.siteName} may not use the Digital Assets and/or Virtual Assets in any
          way to generate revenue other than in connection with the charging of fees as set out in
          the Fee Schedule.
        </p>
        <h2>VERIFICATION OF ACCOUNTS</h2>
        <p>
          The creation and use of your Account are subject to verifications, as required by
          statutory and regulatory obligations incumbent on {site.siteMetadata.siteName}. You agree
          to provide us with the information we request for the purposes of identity verification,
          compliance with know-your-customer rules, as well as detection of money laundering,
          terrorism financing, fraud, or any other financial crime. The requested information may
          include Personal Data (please refer to our Privacy Policy). By providing us with the
          information we request, you confirm {site.siteMetadata.siteName} exchange platform as made
          available on websites that it is true and accurate, and agree to inform us in case of
          change concerning such information. Your Account will be blocked until we are satisfied
          with the information you have provided and determine at our sole discretion that it is
          sufficient to validate your account. In the meantime, you will not be allowed to terminate
          your Account or request the deletion of the Personal Data processed in the course of
          verification operations.
        </p>
        <h2>VERIFICATION OF ACCOUNTS</h2>
        <p>
          The creation and use of your Account are subject to verifications, as required by
          statutory and regulatory obligations incumbent on {site.siteMetadata.siteName}. You agree
          to provide us with the information we request for the purposes of identity verification,
          compliance with know-your-customer rules, as well as detection of money laundering,
          terrorism financing, fraud, or any other financial crime. The requested information may
          include Personal Data (please refer to our Privacy Policy). By providing us with the
          information we request, you confirm that it is true and accurate, and agree to inform us
          in case of change concerning such information. Your Account will be blocked until we are
          satisfied with the information you have provided and determine at our sole discretion that
          it is sufficient to validate your account. In the meantime, you will not be allowed to
          terminate your Account or request the deletion of the Personal Data processed in the
          course of verification operations.
        </p>
        <h2>TERMINATION AND ESCROW OF UNVERIFIED ACCOUNTS</h2>
        <p>
          You may terminate this agreement with {site.siteMetadata.siteName} and close your Account
          at any time, following settlement of any pending transactions.
        </p>
        <p>
          You also agree that {site.siteMetadata.siteName} may, by giving notice, in its sole
          discretion terminate your access to the Site and to your Account, including without
          limitation, its right to limit, suspend or terminate the Services and Members' accounts,
          prohibit access to the Site, the Services, and its content, services, and tools, delay or
          remove hosted content, and take technical and legal steps to keep Members off the Site if
          we suspect that they are creating problems or possible legal liabilities, infringing the
          intellectual property rights of third parties, or acting inconsistently with the letter or
          spirit of these Terms of Use. Additionally, we may, in appropriate circumstances and at
          our discretion, suspend or terminate Accounts of Members for any reason, including without
          limitation: (1) attempts to gain unauthorized access to the Site or another Member's
          account or providing assistance to others attempting to do so, (2) overcoming software
          security features limiting the use of or protecting any content, (3) usage of the Services
          to perform illegal activities such as money laundering, illegal gambling operations,
          financing terrorism or other criminal activities, (4) violations of these Terms of Use,
          (5) a failure to pay or a fraudulent payment for Transactions, (6) unexpected operational
          difficulties, or (7) upon the request of law enforcement or other government agencies.
        </p>
        <p>
          {site.siteMetadata.siteName} expressly reserves the right to cancel and/or terminate
          Accounts that have not been verified by a Member despite efforts made in good faith by{" "}
          {site.siteMetadata.siteName} to contact said Member to obtain such verification
          ("Unverified Accounts''). All Unverified Accounts which have been inactive for a period of
          6 months or more are further subject to transfer to a third-party escrow ("Unverified
          Escrow''), and will no longer be maintained or under the legal responsibility of{" "}
          {site.siteMetadata.siteName}. The administrator/trustee of the Unverified Escrow shall
          make any and all additional reasonable efforts required by law to determine and contact
          each Unverified Account owner and, after suitable effort and time has been expended, we
          may be required to convert the residual Virtual Assets or other Digital Assets into fiat
          and send the converted amount to a national authority responsible for the safekeeping of
          such funds.
        </p>
        <p>
          The suspension or escrow of an Account shall not affect the payment of commissions due for
          past Transactions. Upon termination, Members shall send to {site.siteMetadata.siteName}{" "}
          details of a valid bank account to allow for the transfer of any fiat currencies credited
          to their account. This bank account must be held by and in the name of the Member. Virtual
          Assets or other Digital Assets may be transferred to a valid bank account only after
          conversion into a fiat currency. {site.siteMetadata.siteName} shall facilitate the
          transfer of the currencies as soon as possible following the Member's request and within
          the time frames specified by {site.siteMetadata.siteName}.
        </p>
        <p>
          {site.siteMetadata.siteName} will arrange to send you the credit balance of your Account;
          however, in certain circumstances, a number of intermediaries may be involved in an
          international payment and these or the beneficiary bank may deduct charges. We will make
          all reasonable efforts to ensure that such charges are disclosed to you prior to sending
          your payment; however, where they cannot be avoided, you acknowledge that these charges
          cannot always be calculated in advance and that you agree to be responsible for such
          charges.
        </p>
        <p>Upon Account closure, any amount less than 25 USD/EUR in value will not be returned.</p>
        <h2>AVAILABILITY OF SERVICES</h2>
        <p>
          All Services are provided without warranty of any kind, either express or implied and in
          particular without implied warranties of merchantability and fitness for a particular
          purpose. We do not represent that this Site or the Services, or the services of any
          third-party custodian, will be available 100% of the time to meet your needs. We will
          strive to provide you with the Services as soon as possible, but there are no guarantees
          that access will not be interrupted, or that there will be no delays, failures, errors,
          omissions, or a loss of transmitted information.
        </p>
        <p>
          We will use reasonable endeavors to ensure that the Site and the Services can be accessed
          by you in accordance with these Terms of Use. However, we may suspend the use of the Site
          and the Services for maintenance and will make reasonable efforts to give you notice of
          this. You acknowledge that this may not be possible in an emergency, and accept the risks
          associated with the fact that you may not always be able to use the Site and the Services
          or carry out urgent transactions using your Account.
        </p>
        <h2>APIS AND WIDGETS</h2>
        <p>
          We may provide access to certain parties to access specific data and information through
          our API (Application Programming Interface) or widgets. We also may provide widgets for
          your use to enter our data on your Site. You are free to use these in their original
          unmodified and unaltered state.
        </p>
        <h2>EXTERNAL WEBSITES</h2>
        <p>
          {site.siteMetadata.siteName} makes no representations whatsoever about any External
          Websites you may access through the Site including the websites of any third-party
          custodian service providers. Occasionally, the {site.siteMetadata.siteName} website may
          provide references or links to External Websites. We do not control these External
          Websites or third-party sites or any of the content contained therein. You agree that we
          are in no way responsible or liable for the External Websites referenced or linked from
          the {site.siteMetadata.siteName} website, including, but not limited to, website content,
          policies, failures, promotions, products, opinions, advice, statements, prices,
          activities, advertisements, services or actions and/or any damages, losses, failures or
          problems caused by, related to or arising from those sites. You shall bear all risks
          associated with the use of such content.
        </p>
        <p>
          External Websites have separate and independent terms of use and related policies. We
          request that you review the policies, rules, terms, and regulations of each site that you
          visit. It is up to you to take precautions to ensure that whatever you select for your use
          is free of items such as viruses, worms, Trojan horses, and other items of a destructive
          nature.
        </p>
        <h2>FINANCIAL ADVICE</h2>
        <p>
          For the avoidance of doubt, we do not provide any investment advice in connection with the
          Services described in these Terms of Use. We may provide information on the price, range,
          and volatility of Virtual Assets and other Digital Assets that are available on our Site
          and events that have affected the price of such Virtual Assets and Digital Assets, but
          this must not be considered investment advice nor should it be construed as such. Any
          decision to purchase or sell Virtual Assets and other Digital Assets is solely your
          decision and we shall not be liable for any loss suffered.
        </p>
        <h2>FINANCIAL REGULATION</h2>
        <p>
          Our business model and our Services facilitate the buying, selling and trading of Virtual
          Assets and other Digital Assets and their use to purchase goods in an unregulated,
          international open payment system. The Services we provide are currently unregulated
          within the UK.
        </p>
        <h2>{site.siteMetadata.siteName} APPROACH TO DIGITAL FORKS IN VIRTUAL ASSETS</h2>
        <p>
          {site.siteMetadata.siteName} has no control over the software protocols that governor
          constitute the framework of Virtual Assets or other Digital Assets. Therefore,{" "}
          {site.siteMetadata.siteName} assumes no responsibility for the update or any modification
          of the underlying protocols, and {site.siteMetadata.siteName} is not able to guarantee
          their functionality, security, or availability.
        </p>
        <p>
          By accepting the present Terms of Use, you acknowledge and accept the risk that underlying
          software protocols relating to any of the virtual currencies available on the Site are
          likely to be subject to sudden changes in operating rules (hereafter "fork"), and such
          forks may materially affect the value, function and/or the name of the virtual currencies
          that are exchanged or acquired through the Site.
        </p>
        <p>
          {site.siteMetadata.siteName} will use reasonable endeavors to provide you with notice of
          forks by publishing such notice on the Site, including, to the extent possible, whether{" "}
          {site.siteMetadata.siteName} intends to support either or both branches of a fork, and you
          agree that you will read such notices in order to make a decision in that respect.
        </p>
        <p>
          However, it is your responsibility to make yourself aware of, and consider how to deal
          with, upcoming forks, including whether to give us Instructions for the withdrawal of the
          balance of any Virtual Assets or other Digital Assets. In the event of a fork, there is a
          risk that {site.siteMetadata.siteName} may need to temporarily suspend operations in
          relation to that fork without providing advance notice to you.{" "}
          {site.siteMetadata.siteName} may in their reasonable discretion, decline to support either
          or both branches of a fork.
        </p>
        <p>
          You acknowledge that third-party custodian-service providers may operate fork policies
          and, under their terms as entered into with you in respect of custodian services, may not
          be obliged (or able) to support all or any forks. You should ensure you check their fork
          policies regularly (as well as the terms of your agreement with custodian-service
          providers instructed by {site.siteMetadata.siteName} on your behalf, which will be made
          available to you) and whenever you become aware of a forthcoming fork. Notwithstanding
          that a third-party custodian-service provider may support a particular cryptocurrency,
          including a fork, this will not oblige {site.siteMetadata.siteName} to support that
          cryptocurrency or fork.
        </p>
        <p>
          In the event of a fork which affects Virtual Assets or other Digital Assets pursuant to
          these Terms of Use, and which is unsupported by {site.siteMetadata.siteName}:
        </p>
        <ul>
          <li>
            {site.siteMetadata.siteName} will not acquire any right, title, or interest in the
            unsupported forked Virtual Asset and/or other Digital Assets; and
          </li>
          <li>
            {site.siteMetadata.siteName} may be unable to give effect to any Instructions given in
            respect of those Virtual Assets or other Digital Assets and {site.siteMetadata.siteName}{" "}
            will have no liability to you in respect of the unsupported forked Virtual Assets or
            other Digital Assets.
          </li>
        </ul>
        <p>
          In the event that {site.siteMetadata.siteName} subsequently decides to support the forked
          Virtual Assets or other Digital Assets, {site.siteMetadata.siteName} will then give effect
          to your Instructions
        </p>
        <p>
          By agreeing to the present Terms of Use, you acknowledge the risks presented by forks and
          you accept that {site.siteMetadata.siteName} has no responsibility to assist you to move
          or sell Virtual Assets or other Digital Assets of an unsupported branch of a forked
          protocol.
        </p>
        <h2>EMAIL</h2>
        <p>
          Unencrypted email messages sent over the Internet are not secure, and{" "}
          {site.siteMetadata.siteName} is not responsible for any damages incurred by the result of
          sending email messages in this way. We suggest sending emails in encrypted formats; you
          are welcome to send PGP encrypted emails to us. The instructions and keys to do so are
          available upon request.
        </p>
        <p>
          If you send unencrypted or unsecured email or other types of communications to us, we may
          respond using the same channels, and you hereby accept the risks associated therewith.
        </p>
        <h2>DATA PROTECTION</h2>
        <p>
          Privacy is very important to us. Full details of our Privacy Policy can be found at
          Privacy Policy. We recommend that you read the Privacy Policy carefully so that you know
          the data that we collect, how we use the data, and who we share your data with.
        </p>
        <h2>DISCLOSURES TO LEGAL AUTHORITIES AND AUTHORISED FINANCIAL INSTITUTIONS</h2>
        <p>
          We may share your Personal Data with law enforcement, data protection authorities,
          government officials, and other authorities when:
        </p>
        <ul>
          <li>Required by law;</li>
          <li>Compelled by subpoena, court order, or other legal procedure;</li>
          <li>We believe that disclosure is necessary to prevent damage or financial loss;</li>
          <li>Disclosure is necessary to report suspected illegal activity; or</li>
          <li>
            Disclosure is necessary to investigate violations of our Terms of Use or Privacy Policy
          </li>
        </ul>
        <p>
          We may also share information concerning US citizens who are {site.siteMetadata.siteName}{" "}
          customers with other financial institutions, as authorized under Section 314(b) of the US
          Patriot Act, and with tax authorities, including the US Internal Revenue Service, pursuant
          to the Foreign Account Tax Compliance Act ("FATCA"), to the extent that this statute may
          be determined to apply to.
        </p>
        <p>
          For further information on how we process your Personal Data, please refer to the Privacy
          Policy.
        </p>
        <h2>INTERNATIONAL TRANSFERS OF PERSONAL DATA</h2>
        <p>
          We store and process your Personal Data in data centers around the world, wherever{" "}
          {site.siteMetadata.siteName} facilities or service providers are located. As such, we may
          transfer your Personal Data outside of the European Economic Area ("EEA"). Such transfers
          are undertaken in accordance with our legal and regulatory obligations.
        </p>
        <p>Please refer to our Privacy Policy for further details.</p>
        <h2>JURISDICTION</h2>
        <p>
          The Terms of Use shall be governed and construed in accordance with the law of England and
          Wales. The parties hereto agree to irrevocably submit to the exclusive jurisdiction of the
          courts of England and Wales.
        </p>
        <h2>LIMITATION OF LIABILITY</h2>
        <p>
          To the extent permitted by law, {site.siteMetadata.siteName} will not be held liable for
          any damages, loss of profit, loss of revenue, loss of business, loss of opportunity, loss
          of data, indirect or consequential loss unless the loss suffered arising from gross
          negligence, willful deceit or fraud. Nothing in these terms excludes or limits the
          liability of either party for fraud, death, or personal injury caused by negligence which
          may not be limited or excluded by law. Although {site.siteMetadata.siteName} endeavors to
          provide accurate and timely information on the Site, the Site may not always be entirely
          accurate, complete, or current and may include errors. {site.siteMetadata.siteName} may
          change or update the Site at any time without notice, and you should accordingly verify
          with independent sources all information before relying on it to make decisions or
          actions. You remain entirely responsible for your decisions and actions. Subject to the
          above, you also agree and acknowledge that {site.siteMetadata.siteName} has no liability
          or responsibility in respect of the custody of any Virtual Assets and/or Digital Assets.
        </p>
        <p>
          Subject to the foregoing, {site.siteMetadata.siteName} aggregate liability for claims
          based on events arising out of or in connection with any single Member's use of the Site
          and/or Services, whether in contract, tort (including negligence) or otherwise, shall in
          no circumstances exceed the greater of either (a) the total amount held on the Account of
          the Member making a claim less any amount of Commission that may be due and payable in
          respect of such an Account, or (b) 125% of the amount of the Transaction(s) that are the
          subject of the claim less any amount of Commission that may be due and payable in respect
          of such Transaction(s).
        </p>
        <h2>INDEMNITY</h2>
        <p>
          To the full extent permitted by applicable law, you hereby agree to indemnify{" "}
          {site.siteMetadata.siteName} and its partners against any action, liability, cost, claim,
          loss, damage, proceeding or expense suffered or incurred if directly or not directly
          arising from your use of the Site, your use of the Services or from your violation of
          these Terms of Use.
        </p>
        <h2>TAXES</h2>
        <p>
          It is your responsibility to determine whether, and to what extent, any taxes apply to any
          transactions you and your Company conduct through the Services, and to withhold, collect,
          report and remit the correct amounts of taxes to the appropriate tax authorities. Your
          transaction history is available through your Account.
        </p>
        <h2>MISCELLANEOUS</h2>
        <p>
          If we are unable to perform the Services outlined in the Terms of Use due to factors
          beyond our control including but not limited to an event of Force Majeure, change of law,
          or change in sanctions policy, we shall not be liable for the Services provided under this
          agreement during the time period coincident with the event.
        </p>
        <h2>MODIFICATION OF TERMS</h2>
        <p>
          {site.siteMetadata.siteName} reserves the right to change, add or remove parts of these
          Terms at any time and at its sole discretion. You will be notified of any changes in
          advance through your Account. Upon such notification, it is your responsibility to review
          the amended Terms. Your continued use of the Site and the Services following the posting
          of a notice of changes to the Terms signifies that you accept and agree to the changes and
          that all subsequent transactions by you will be subject to the amended Terms.
        </p>
        <h2>TRANSFER</h2>
        <p>
          {site.siteMetadata.siteName} may transfer its rights and obligations under the Terms of
          Use to other entities of {site.siteMetadata.siteName} Ltd. This includes any other firm or
          business entity that directly or indirectly acquires all or substantially all of the
          assets or business of {site.siteMetadata.siteName} Ltd. Any transfer of the rights and
          obligations of {site.siteMetadata.siteName} Ltd to other entities in{" "}
          {site.siteMetadata.siteName} Ltd. will be binding upon you. If you do not consent to any
          transfer, you may terminate this agreement and close your Account.
        </p>
        <h2>DEFINITIONS</h2>
        <p>
          Account. The contractual arrangement wherein an individual has accepted the{" "}
          {site.siteMetadata.siteName} Terms of Use, Fee Schedule, and Privacy Policy, and received
          approval to use the Services, including the purchase and sale of Virtual Assets and other
          Digital Assets, and to perform associated Transactions.
        </p>
        <p>
          Buyer(s). Member(s) that submit an offer to buy Virtual Assets or other Digital Assets
          through the Services.
        </p>
        <p>
          Commission(s). The fee is payable to {site.siteMetadata.siteName}, including on each
          Transaction, such as a Purchase Transaction, and as further defined in the Fee Schedule.
        </p>
        <p>Member(s). Buyers and Sellers as well as any holder of an Account.</p>
        <p>Digital Asset(s). Digitized right to use in a binary format.</p>
        <p>
          Digital Asset(s) Arbitrage. Transferring and exchanging funds at{" "}
          {site.siteMetadata.siteName} from {"&"} to third-party exchanges with a possible purpose
          to launder stolen funds or gaining profit on price differences between{" "}
          {site.siteMetadata.siteName} and third-party exchange Services.
        </p>
        <p>External Website(s). Websites not owned or operated by {site.siteMetadata.siteName}.</p>
        <p>
          Member(s). Refers to Buyers and Sellers as well as any registered holders of an Account.
        </p>
        <p>
          Personal Data. Any information relating to an identified or identifiable natural person.
          An identifiable natural person is one who can be identified, directly or indirectly, in
          particular by reference to an identifier such as a name, an identification number,
          location data, or an online identifier, or to one or more factors specific to the
          physical, physiological, genetic, mental, economic, cultural or social identity of that
          natural person.
        </p>
        <p>
          Price. The "price per coin" for which Members are willing to buy or sell Virtual Assets or
          other Digital Assets. The Price may be expressed in any of the currencies deposited by
          Members in their Account and supported by the Services. See our Site for a full list of
          currencies.
        </p>
        <p>
          Seller(s). Member(s) that submit an offer to sell Virtual Assets or other Digital Assets
          through the Services.
        </p>
        <p>
          Service(s). The technological platform, functional rules, and market are managed by{" "}
          {site.siteMetadata.siteName}
          to permit Sellers and Buyers to purchase and sell Virtual Assets.
        </p>
        <p>
          Site. {site.siteMetadata.siteName} platform as made available on websites{" "}
          {site.siteMetadata.siteDomain}
        </p>
        <p>Transaction. Includes the following:</p>
        <ul>
          <li>
            The agreement between the Buyer and the Seller to exchange currencies, Virtual Assets or
            other Digital Assets through the Services for currencies at a commonly agreed rate
            ("Purchase Transaction");
          </li>
          <li>
            The exchange of fiat currencies and Virtual Assets between Members ("Conversion
            Transaction");
          </li>
          <li>The exchange of Virtual Assets between Members ("Transfer Transaction"); and</li>
          <li>The exchange of fiat currencies among Members ("Currency Transfer Transaction").</li>
        </ul>
        <p>
          {site.siteMetadata.siteName} may not offer all these transaction types at this time or at
          all locations.
        </p>
        <p>
          Transaction Price. The total price paid by the Buyer for each Transaction performed
          through the Services.
        </p>
        <p>
          Virtual Asset(s). Digital representation of value that can be digitally traded, or
          transferred, and can be used for payment or investment purposes. Virtual Assets such as
          cryptocurrencies are a form of Virtual Assets designed to work as a medium of exchange
          that uses strong cryptography to secure financial transactions, control the creation of
          additional units and verify the transfer of assets. Virtual Assets use decentralized
          control as opposed to centralized digital currencies and central banking systems.
        </p>
      </DocTemplate>
    </Layout>
  );
};

export default TermsOfUse;
