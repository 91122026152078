import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { getKLines } from '../../../utils/binance/api';
import PlotlyLoadable from '../../../utils/plotly-loadable';
import IntervalButton from './interval-button';

type SymbolPlotProps = {
  interval: string;
  _1stCoin: string;
  _2ndCoin: string;
};

const SymbolPlot = (props: SymbolPlotProps) => {
  const f = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 6 });

  const isMobile = useMediaQuery('(max-width: 500px)');

  const [selectedInterval, setSelectedInterval] = useState<string>(props.interval);
  const [symbol, setSymbol] = useState<string>(`${props._1stCoin}${props._2ndCoin}`);

  const [date, setDate] = useState<number[]>([]);
  const [open, setOpen] = useState<number[]>([]);
  const [high, setHigh] = useState<number[]>([]);
  const [low, setLow] = useState<number[]>([]);
  const [close, setClose] = useState<number[]>([]);
  const [volume, setVolume] = useState<number[]>([]);
  const [lastPrice, setLastPrice] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      updateChart(selectedInterval);
    }, 1000);
    return () => clearInterval(interval);
  }, [selectedInterval]);

  function updateChart(interval: string) {
    setSelectedInterval(interval);

    getKLines(symbol, interval, 50, function (date, open, high, low, close, volume) {
      setDate(date);
      setOpen(open);
      setHigh(high);
      setLow(low);
      setClose(close);
      setVolume(volume);
      setLastPrice(close[close.length - 1]);
    });
  }

  return (
    <div className="grid md:block w-full">
      <div className="flex flex-wrap gap-4 text-xs text-gray-400">
        <IntervalButton
          selected={selectedInterval === '1m'}
          value="1m"
          onClick={(e) => setSelectedInterval('1m')}
        />
        <IntervalButton
          selected={selectedInterval === '3m'}
          value="3m"
          onClick={(e) => setSelectedInterval('3m')}
        />
        <IntervalButton
          selected={selectedInterval === '5m'}
          value="5m"
          onClick={(e) => setSelectedInterval('5m')}
        />
        <IntervalButton
          selected={selectedInterval === '15m'}
          value="15m"
          onClick={(e) => setSelectedInterval('15m')}
        />
        <IntervalButton
          selected={selectedInterval === '30m'}
          value="30m"
          onClick={(e) => setSelectedInterval('30m')}
        />
        <IntervalButton
          selected={selectedInterval === '1h'}
          value="1H"
          onClick={(e) => setSelectedInterval('1h')}
        />
        <IntervalButton
          selected={selectedInterval === '2h'}
          value="2H"
          onClick={(e) => setSelectedInterval('2h')}
        />
        <IntervalButton
          selected={selectedInterval === '4h'}
          value="4H"
          onClick={(e) => setSelectedInterval('4h')}
        />
        <IntervalButton
          selected={selectedInterval === '6h'}
          value="6H"
          onClick={(e) => setSelectedInterval('6h')}
        />
        <IntervalButton
          selected={selectedInterval === '8h'}
          value="8H"
          onClick={(e) => setSelectedInterval('8h')}
        />
        <IntervalButton
          selected={selectedInterval === '12h'}
          value="12H"
          onClick={(e) => setSelectedInterval('12h')}
        />
        <IntervalButton
          selected={selectedInterval === '1d'}
          value="1D"
          onClick={(e) => setSelectedInterval('1d')}
        />
        <IntervalButton
          selected={selectedInterval === '3d'}
          value="3D"
          onClick={(e) => setSelectedInterval('3d')}
        />
        <IntervalButton
          selected={selectedInterval === '1w'}
          value="1W"
          onClick={(e) => setSelectedInterval('1w')}
        />
        <IntervalButton
          selected={selectedInterval === '1M'}
          value="1M"
          onClick={(e) => setSelectedInterval('1M')}
        />
      </div>
      <PlotlyLoadable
        className="w-full"
        useResizeHandler={true}
        data={[
          {
            x: date,
            open: open,
            high: high,
            low: low,
            close: close,
            increasing: { line: { color: 'green' } },
            decreasing: { line: { color: 'red' } },
            type: 'candlestick',
            xaxis: 'x',
            yaxis: 'y',
          },
          {
            xaxis: 'x',
            yaxis: 'y2',
            x: date,
            y: volume,
            type: 'bar',
            marker: {
              color: close.map(function (c, index) {
                return c > open[index] ? 'rgba(0, 128, 0, 0.4)}' : 'rgba(255, 0, 0, 0.4))';
              }),
            },
          },
        ]}
        layout={{
          height: isMobile ? 375 : 500,
          font: {
            family: 'Manrope',
          },
          annotations: [
            {
              x: date[date.length - 1],
              y: close[close.length - 1],
              xref: 'x',
              yref: 'y',
              text: `${f.format(lastPrice)}`,
              showarrow: true,
              font: {
                family: 'Manrope',
                size: 14,
                color: '#ffffff',
              },
              align: 'center',
              arrowhead: 1,
              arrowsize: 1,
              arrowwidth: 1,
              arrowcolor: 'rgba(0, 128, 0, 0)',
              ax: -30,
              ay: -30,
              bordercolor: '#c7c7c7',
              borderwidth: 0,
              borderpad: 2,
              bgcolor: close[close.length - 1] > close[close.length - 2] ? 'rgb(0, 128, 0)' : 'red',
              opacity: 0.8,
            },
          ],
          shapes: [
            {
              type: 'line',
              x0: date[0],
              y0: close[close.length - 1],
              x1: date[date.length - 1],
              y1: close[close.length - 1],
              opacity: 0.7,
              line: {
                color: close[close.length - 1] > close[close.length - 2] ? 'green' : 'red',
                width: 1,
                dash: 'dot',
              },
            },
          ],
          autosize: true,
          xaxis: {
            visible: true,
            rangeslider: {
              visible: false,
            },
            type: 'date',
            showticklabels: true,
            showgrid: true,
            gridwidth: 0.5,
          },
          xaxis2: {
            visible: true,
            type: 'date',
            showticklabels: true,
            showgrid: true,
            gridwidth: 0.5,
          },
          yaxis: {
            side: 'right',
            showgrid: true,
            gridwidth: 0.5,
            domain: [0.2, 1],
          },
          yaxis2: {
            anchor: 'x2',
            domain: [0, 0.2],
            autorange: true,
            type: 'linear',
            visible: true,
            showgrid: false,
            zeroline: false,
            side: 'right',
          },
          title: '',
          showlegend: false,
          margin: {
            l: 0,
            r: 36,
            b: 50,
            t: 10,
            pad: 4,
          },
        }}
        config={{ staticPlot: true, doubleClick: false, responsive: true, autosizable: true }}
      />
    </div>
  );
};

export default SymbolPlot;
