import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import BlueButton from "./blue-button";
import "./rewards-banner.css";

const RewardsBanner = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteShortName
          }
        }
      }
    `
  );

  return (
    <div className="relative rewards-banner">
      <div className="rewards-banner-main">
        <div className="container text-white flex items-center h-full">
          <div>
            <h1 className="text-3xl sm:text-5xl font-semibold mb-8">
              <span className="text-6xl sm:text-7xl text-blue-600 font-bold">$630</span> Reward For
              Completing Tasks
            </h1>
            <h2 className="text-base sm:text-lg font-bold mb-4">
              Join {site.siteMetadata.siteShortName} and Get Exclusive Rewards
            </h2>
            <BlueButton text="Sign Up Now" anchor="/signup" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardsBanner;
