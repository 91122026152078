import { navigate } from "gatsby";
import React, { useState } from "react";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";
import DashboardLayout from "../../../components/dashboard/dashboard-layout";
import PasswordInput from "../../../components/dashboard/settings/password-input";
import SettingsNav from "../../../components/dashboard/settings/settings-nav";
import { AppUser } from "../../../model/types";
import { updatePassword } from "../../../utils/myapi";

const Security = () => {
  const [loggedIn, setLoggedIn] = useLocalStorage<boolean>("logged_in", false);
  const user = useReadLocalStorage<AppUser>("user");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");

  const onUpdate = () => {
    if (user && user.password === oldPassword) {
      updatePassword(user.email, user.password, newPassword, (user: AppUser | null) => {
        setLoggedIn(false);
        navigate("/signin");
      });
    }
  };

  return (
    <DashboardLayout activeTab={4}>
      <SettingsNav title="Settings" subtitle="Security" activeTab={1}></SettingsNav>
      <div className="py-4">
        <h2 className="text-base font-bold mb-2">Password</h2>
        <p className="text-xs text-gray-500 mb-8">Used for account login</p>
        <PasswordInput label="Old password" onChange={(e) => setOldPassword(e.target.value)} />
        <PasswordInput label="New password" onChange={(e) => setNewPassword(e.target.value)} />
        <button
          onClick={onUpdate}
          className="text-center font-semibold bg-blue-700 hover:bg-blue-800 text-white py-2 px-4 text-sm rounded"
        >
          Update password
        </button>
      </div>
    </DashboardLayout>
  );
};

export default Security;
