import React from "react";

type FeatureItemProps = {
  icon: any;
  title: string;
  text: string;
};

const FeatureItem = (props: FeatureItemProps) => {
  return (
    <div className="px-4 py-3 text-center">
      <div className="relative flex">
        <div className="mx-auto mb-5 bg-white rounded-full w-16 h-16 flex justify-center items-center shadow">
          <img src={props.icon}></img>
        </div>
      </div>
      <h4 className="font-bold">{props.title}</h4>
      <p className="mt-3 text-gray-600">{props.text}</p>
    </div>
  );
};

export default FeatureItem;
