import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import PinIcon from "../../images/pin.svg";

const Sidebar = () => {
  const { site, allMdx } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteShortName
          }
        }
        allMdx(sort: { fields: frontmatter___date, order: DESC }) {
          nodes {
            frontmatter {
              date(formatString: "MMMM D, YYYY")
              title
            }
            id
            slug
          }
        }
      }
    `
  );

  return (
    <div className="lg:pl-14">
      <div className="sm:sticky sm:top-0 sm:pt-6 pb-14">
        <h4 className="font-bold mb-6">Recommended Posts</h4>
        <ul>
          {allMdx.nodes.map((node: any) => (
            <li key={node.id} className="mb-6">
              <div className="flex">
                <img className="w-5 h-5 mr-3" src={PinIcon}></img>
                <Link to={`/news/${node.slug}`}>
                  <p className="text-sm font-semibold mb-2 tracking-wider">
                    {node.frontmatter.title.replace(/%siteName%/g, site.siteMetadata.siteShortName)}
                  </p>
                  <p className="text-xs text-gray-400">Posted at {node.frontmatter.date}</p>
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
