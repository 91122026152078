import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import BlueButton from '../blue-button';
import ExchangeScreen from './../../images/exchange-screen.png';

const TradeEasily = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteShortName
          }
        }
      }
    `
  );

  return (
    <div className="container py-20 sm:py-40">
      <div className="flex gap-x-20">
        <img className="hidden lg:block w-1/3 p-2" src={ExchangeScreen}></img>
        <div className="grow flex flex-col justify-between gap-y-10">
          <h2 className="text-2xl sm:text-4xl font-bold text-gray-900 text-center sm:text-left">Trade easily</h2>
          <ul className="list-none pl-8 grid gap-y-16">
            <li className="relative text-base">
              <span className="text-lg absolute -left-8 text-blue-700 font-bold">01</span>
              <strong className="text-lg">Trade with crypto and digital assets in realtime</strong>
              <p className="text-gray-500 mt-2">
                Our trading terminal providing real market data and give to user possibility trade
                with digital assets and cryptocurrencies with comfort and confidence
              </p>
            </li>
            <li className="relative text-base">
              <span className="text-lg absolute -left-8 text-blue-700 font-bold">02</span>
              <strong className="text-lg">
                Buy {'&'} Sell Cryptocurrency assets with {site.siteMetadata.siteShortName}
              </strong>
              <p className="text-gray-500 mt-2">
                Buy and Sell BTC, ETH, USDT, SOL, DOT, DOGE and other currencies comfortable with
                fair market prices
              </p>
            </li>
            <li className="relative text-base">
              <span className="text-lg absolute -left-8 text-blue-700 font-bold">03</span>
              <strong className="text-lg">Access anywhere 24/7</strong>
              <p className="text-gray-500 mt-2">
                Exchange anything and anywhere with desktop and mobile friendly interface
              </p>
            </li>
          </ul>
          <div className="mx-auto mr-auto">
            <BlueButton anchor="/signup" text="Start trading" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeEasily;
