import { Avatar } from 'evergreen-ui';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import BlueButton from '../../components/blue-button';
import DashboardLayout from '../../components/dashboard/dashboard-layout';
import KeyIcon from '../../images/key.svg';
import PasswordIcon from '../../images/password.svg';
import ConfirmationIcon from '../../images/confirmation.svg';
import GoogleIcon from '../../images/google.svg';
import VerificationIcon from '../../images/verification.svg';
import { useReadLocalStorage } from 'usehooks-ts';
import { AppUser } from '../../model/types';
import AffiliateBox from '../../components/dashboard/affiliate/affiliate-box';
import AffiliateStatistics from '../../components/dashboard/affiliate/affiliate-statistics';
import { getAvatarString } from '../../utils/tools';

const Dashboard = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteDomain
          }
        }
      }
    `
  );

  const user: AppUser | null = useReadLocalStorage<AppUser>('user');

  const [lastLoginDate, setLastLoginDate] = useState<Date>(new Date());
  const [passwordUpdatedDaysAgo, setPasswordUpdatedDaysAgo] = useState<number>(7);

  return (
    <DashboardLayout activeTab={0}>
      <div className="relative sm:flex flex-row items-center py-10 mb-14 border-b items-center">
        <div className="hidden sm:block">
          <Avatar name={getAvatarString(user)} size={100} marginRight={40} />
        </div>
        <div>
          <div className="flex flex-row items-center mb-0">
            <span className="text-xl font-semibold">
              {user?.firstName || user?.lastName
                ? user.firstName + ' ' + user.lastName
                : 'User: #' + user?.id}
            </span>
            <span className="bg-gray-50 py-1 px-2 ml-2 sm:ml-4 text-xxs text-gray-600 rounded">
              ID: {user?.id}
            </span>
          </div>
          <span className="text-sm font-medium text-gray-700">{user?.email}</span>
          <div className="grid grid-cols-1 sm:flex flex-row items-center mt-5 sm:mt-3">
            <div className="flex flex-row justify-between sm:block sm:mr-5 sm:pr-5 sm:border-r mb-4 sm:mb-0">
              <div className="text-xs font-semibold text-gray-500">KYC Verification</div>
              <div className="text-sm font-semibold text-rose-600">Unverified</div>
            </div>
            <div className="flex flex-row justify-between sm:block sm:mr-5 sm:pr-5 sm:border-r mb-4 sm:mb-0">
              <div className="text-xs font-semibold text-gray-500">Account Security</div>
              <div className="text-sm font-semibold text-rose-600">Low</div>
            </div>
            <div className="flex flex-row justify-between sm:block">
              <div className="text-xs font-semibold text-gray-500">Fee Discount</div>
              <div className="text-sm font-semibold text-gray-900">0%</div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 2xl:grid-cols-5 2xl:gap-10">
        <div className="col-span-3">
          <div className="mb-20">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-bold">Account Security</h3>
              <div className="flex justify-right">
                <BlueButton
                  anchor="/dashboard/settings/security"
                  text="Security Center"
                  minimal={true}
                ></BlueButton>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 sm:gap-5 mt-6 rounded-md sm:rounded-none overflow-hidden shadow sm:shadow-none">
              <Link
                to="#"
                className="bg-gray-50 p-4 border-b sm:rounded sm:border sm:shadow-sm sm:hover:-translate-y-1 transition duration-500 ease-in-out"
              >
                <div className="flex">
                  <img src={KeyIcon} className="w-7 h-7 mr-5 shrink-0"></img>
                  <div className="flex flex-col items-start">
                    <span className="text-xs font-semibold mb-1">Login History</span>
                    <span className="text-xxs text-gray-500">
                      Last login: {lastLoginDate.toLocaleString()}
                    </span>
                  </div>
                </div>
              </Link>
              <Link
                to="#"
                className="bg-gray-50 p-4 border-b sm:rounded sm:border sm:shadow-sm sm:hover:-translate-y-1 transition duration-500 ease-in-out"
              >
                <div className="flex">
                  <img src={PasswordIcon} className="w-7 h-7 mr-5 shrink-0"></img>
                  <div className="flex flex-col items-start">
                    <span className="text-xs font-semibold mb-1">Password</span>
                    <span className="text-xxs text-gray-500">
                      Updated {passwordUpdatedDaysAgo} days ago
                    </span>
                  </div>
                </div>
              </Link>
              <Link
                to="#"
                className="bg-gray-50 p-4 border-b sm:rounded sm:border sm:shadow-sm sm:hover:-translate-y-1 transition duration-500 ease-in-out"
              >
                <div className="flex">
                  <img src={ConfirmationIcon} className="w-7 h-7 mr-5 shrink-0"></img>
                  <div className="flex flex-col items-start">
                    <span className="text-xs font-semibold mb-1">Confirmation code</span>
                    <span className="text-xxs text-rose-600">Not set</span>
                  </div>
                </div>
              </Link>
              <Link
                to="#"
                className="bg-gray-50 p-4 border-b sm:rounded sm:border sm:shadow-sm sm:hover:-translate-y-1 transition duration-500 ease-in-out"
              >
                <div className="flex">
                  <img src={GoogleIcon} className="w-7 h-7 mr-5 shrink-0"></img>
                  <div className="flex flex-col items-start">
                    <span className="text-xs font-semibold mb-1">Google Authentication</span>
                    <span className="text-xxs text-rose-600">Not set</span>
                  </div>
                </div>
              </Link>
              <Link
                to="#"
                className="bg-gray-50 p-4 border-b sm:rounded sm:border sm:shadow-sm sm:hover:-translate-y-1 transition duration-500 ease-in-out"
              >
                <div className="flex">
                  <img src={VerificationIcon} className="w-7 h-7 mr-5 shrink-0"></img>
                  <div className="flex flex-col items-start">
                    <span className="text-xs font-semibold mb-1">Verification</span>
                    <span className="text-xxs text-rose-600">Unverified</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="mb-20">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-bold">Trading Fees</h3>
              <div className="flex justify-right">
                <BlueButton anchor="/fees" text="Fee Schedule" minimal={true}></BlueButton>
              </div>
            </div>
            <div className="grid grid-cols-1 xl:flex mt-8 mb-10 gap-y-3 xl:gap-y-0">
              <div className="flex flex-row-reverse items-center justify-between sm:block sm:pr-10 sm:mr-10 xl:border-r">
                <div className="text-lg sm:text-xl font-bold sm:mb-3">Lv.1</div>
                <div className="text-xs text-gray-500 font-medium tracking-wider">
                  Trading level
                </div>
              </div>
              <div className="flex flex-row-reverse items-center justify-between sm:block sm:pr-10 sm:mr-10 xl:border-r">
                <div className="text-lg sm:text-xl font-bold sm:mb-3">0.075%</div>
                <div className="text-xs text-gray-500 font-medium tracking-wider">Maker</div>
              </div>
              <div className="flex flex-row-reverse items-center justify-between sm:block sm:pr-10 sm:mr-10 xl:border-r">
                <div className="text-lg sm:text-xl font-bold sm:mb-3">0.075%</div>
                <div className="text-xs text-gray-500 font-medium tracking-wider">Taker</div>
              </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-2 mb-20 gap-5">
              <div className="p-5 bg-gray-50 flex flex-col rounded-md">
                <div>
                  <div className="text-xxs text-gray-800 font-semibold mb-1 tracking-wider">
                    30d Trade Volume (BTC)
                  </div>
                  <div className="text-xs text-gray-500 font-medium tracking-wider">
                    Evaluated at 00:00 AM (UTC) every day
                  </div>
                </div>
                <div className="flex justify-between text-sm mt-5">
                  <div>
                    <strong>0</strong>
                    <span className="ml-1 text-gray-500 font-medium">BTC</span>
                    <small className="ml-1">(0%)</small>
                  </div>
                  <div>
                    <strong>30</strong>
                    <span className="ml-1 text-gray-500 font-medium">BTC</span>
                  </div>
                </div>
                <div>
                  <div className="h-2 bg-gray-200 rounded mt-4"></div>
                </div>
              </div>
            </div>
            <div className="mb-20">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-bold">Affiliates</h3>
                <div className="flex justify-right">
                  <BlueButton
                    anchor="/dashboard/affiliates"
                    text="Affiliates Center"
                    minimal={true}
                  ></BlueButton>
                </div>
              </div>
              <AffiliateStatistics />
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
                <AffiliateBox title="Refferal code" textToCopy={'' + user?.affiliateCode}>
                  {user?.affiliateCode}
                </AffiliateBox>
                <AffiliateBox
                  title="Refferal link"
                  textToCopy={site.siteMetadata.siteDomain + '/invite/'}
                >
                  <span className="text-gray-500">{site.siteMetadata.siteDomain + '/invite/'}</span>
                  {user?.affiliateCode}
                </AffiliateBox>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 w-full">
          <div className="mb-20">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-bold">Withdrawal Limit</h3>
              <div className="flex justify-right">
                <BlueButton
                  anchor="/dashboard/settings/verification"
                  text="Increase Limit"
                  minimal={true}
                ></BlueButton>
              </div>
            </div>
            <div className="p-4 bg-gray-50 rounded-md mt-6 text-gray-800">
              <div className="text-xs font-semibold">You can withdraw</div>
              <div className="text-lg mt-4">
                <strong>0.00000000</strong>
                <span className="px-4">/</span>
                <strong>2</strong>
                <span className="pl-4 text-gray-500 font-semibold">BTC</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
