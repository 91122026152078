import React, { useState } from 'react';
import CloseSVG from './svg/close';
import HelpSVG from './svg/help';
import Logo from './../images/icon-blue.svg';
import { useStaticQuery, graphql } from 'gatsby';
import MinusIcon from './../images/minus.svg';
import FacebookLoader from './loaders/facebook-loader';

const Support = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteShortName
          }
        }
      }
    `
  );

  const [open, setOpen] = useState<boolean>(false);

  const [userMessages, setUserMessages] = useState<string[]>([]);
  const [currentMessage, setCurrentMessage] = useState<string>('');

  const [loading, setLoading] = React.useState<boolean>(true);

  const handleOpenSupport = (isOpen: boolean) => {
    setOpen(isOpen);

    if (isOpen) {
      setLoading(true);
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentMessage(e.target.value);
  };

  const onEnterPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();

      if (currentMessage.length > 0) {
        userMessages.push(currentMessage);
        setCurrentMessage('');
        setUserMessages(userMessages);
      }
    }
  };

  return (
    <>
      {open ? (
        <div className="bg-gray-50 p-1 md:p-0 z-40 fixed bottom-0 md:bottom-4 right-0 md:right-4 w-full md:max-w-sm h-full md:max-h-2/3">
          <div className="bg-white flex flex-col justify-between border rounded-lg shadow-md overflow-hidden w-full h-full">
            <header>
              <div className="flex bg-gradient-to-tr from-blue-800 via-blue-700 to-blue-400 text-white py-3">
                <div className="flex-none w-10 h-full"></div>
                <div className="grow text-center font-bold">
                  {site.siteMetadata.siteShortName} Support
                </div>
                <button
                  className="z-50 flex-none w-10 h-full pr-4 flex items-center text-white bg-transparent"
                  onClick={() => handleOpenSupport(false)}
                >
                  <img className="w-6 h-full" src={MinusIcon}></img>
                </button>
              </div>
              <div className="px-6 py-4 border-b shadow flex items-center gap-x-3">
                <img src={Logo} className="w-6 h-6"></img>
                <div className="text-xs tracking-wider">
                  <div className="font-bold">Live Support</div>
                  <div className="font-thin">Customer Support</div>
                </div>
              </div>
            </header>
            <main className="h-full w-full px-4 py-6 text-sm flex flex-col gap-2">
              {!loading ? (
                <div className="mr-auto py-2 px-4 border shadow rounded-full bg-gray-50">
                  Hello, my name is Sandy, how can I help?
                </div>
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  <FacebookLoader />
                </div>
              )}
              {userMessages.map((message, index) => (
                <div
                  key={index}
                  className="ml-auto py-2 px-4 border shadow rounded-full bg-blue-700 text-white"
                >
                  {message}
                </div>
              ))}
            </main>
            <footer className="bg-white px-4 py-3">
              <form>
                <textarea
                  value={currentMessage}
                  style={{ fontSize: '16px' }}
                  rows={3}
                  onKeyDown={(e) => onEnterPress(e)}
                  onChange={(e) => onTextAreaChange(e)}
                  placeholder="Type a message here..."
                  className="w-full py-2 px-3 appearance-none resize-none outline-blue-400 border placeholder:text-gray-300 placeholder:font-thin rounded"
                ></textarea>
              </form>
            </footer>
          </div>
        </div>
      ) : (
        <div
          onClick={() => handleOpenSupport(true)}
          className="sm:hover:scale-105 duration-300 animate-fade-in fixed bottom-4 right-4 bg-gradient-to-tr from-blue-800 via-blue-700 to-blue-400 drop-shadow-lg text-white rounded-full w-12 sm:w-28 h-12 flex justify-center items-center opacity-90 cursor-pointer"
        >
          <div className="w-5 h-5">
            <HelpSVG />
          </div>
          <div className="hidden sm:block ml-2 font-semibold tracking-wider">Chat</div>
        </div>
      )}
    </>
  );
};

export default Support;
