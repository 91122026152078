import React, { useState } from "react";
import CheckSVG from "../../svg/check";
import DocumentSVG from "../../svg/document";

type DocumentProps = {
  name: string;
  pressed: boolean;
  onClick: () => void;
};

const DocumentInput = (props: DocumentProps) => {
  return (
    <div
      onClick={props.onClick}
      className={
        "w-full flex items-center gap-4 py-5 px-4 rounded-lg border shadow-sm " +
        (props.pressed ? "bg-slate-700 text-white" : "bg-gray-50")
      }
    >
      <div
        className={
          "border-2 rounded-full w-6 h-6 p-1 " +
          (props.pressed ? "border-green-700 bg-green-700 text-white" : "")
        }
      >
        {props.pressed && <CheckSVG />}
      </div>
      <div className="w-7 h-7">
        <DocumentSVG />
      </div>
      <div className={"text-sm " + (props.pressed ? "" : "font-bold")}>{props.name}</div>
    </div>
  );
};

export default DocumentInput;
