import React, { useState } from "react";
import VisibilitySVG from "../../svg/visibility";
import VisibilityOffSVG from "../../svg/visibility-off";

type PasswordInputProps = {
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const PasswordInput = (props: PasswordInputProps) => {
  const [type, setType] = useState<string>("password");

  const onTypeChange = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <div className="mb-6">
      <div className="text-xs mb-1 font-semibold">{props.label}</div>
      <div className="flex items-center bg-gray-50 py-2 px-4 rounded-lg border">
        <input
          onChange={props.onChange}
          type={type}
          style={{ fontSize: "16px" }}
          className="outline-none w-full bg-gray-50"
        ></input>
        <button onClick={onTypeChange} className="text-gray-600 w-8 h-8">
          {type === "password" ? <VisibilitySVG /> : <VisibilityOffSVG />}
        </button>
      </div>
    </div>
  );
};

export default PasswordInput;
