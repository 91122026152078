import { AppUser } from '../model/types';
import { AppConfig } from './../../app-config';
import { getRandomInt, getRandomString, getWalletAddress } from './tools';

const axios = require('axios').default;

function getOfflineUser(
  _email: string,
  _password: string,
  _firstName: string = '',
  _lastName: string = ''
): AppUser {
  let id = 0;
  let affiliateCode = '';
  const lsUser = localStorage.getItem('user');
  if (lsUser) {
    const user: AppUser = JSON.parse(lsUser);
    id = user.id;
    affiliateCode = user.affiliateCode;
  }

  return {
    id: id > 0 ? id : getRandomInt(1000000000, 9999999999),
    email: _email,
    password: _password,
    firstName: _firstName,
    lastName: _lastName,
    affiliateCode: affiliateCode.length > 0 ? affiliateCode : getRandomString(6),
    lastLogin: new Date().getTime(),
    passwordUpdatedOn: new Date().getTime(),
  };
}

export function isEmailAllowed(email: string, callback: (result: boolean) => void) {
  if (AppConfig.OFFLINE_MODE) {
    callback(true);
  } else {
    const formData = new FormData();
    formData.append('email', email);
    axios({
      method: 'post',
      url: 'https://api.' + AppConfig.DOMAIN + '/check_email',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response: any) {
        callback(!response.data.email_already_exists);
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }
}

export function signUp(
  email: string,
  password: string,
  invitationCode: string,
  callback: (user: AppUser | null) => void
) {
  if (AppConfig.OFFLINE_MODE) {
    callback(getOfflineUser(email, password));
  } else {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('invitation_code', invitationCode);
    axios({
      method: 'post',
      url: 'https://api.' + AppConfig.DOMAIN + '/signup',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response: any) {
        if (response.data.success) {
          callback({
            id: response.data.id,
            email: response.data.email,
            password: response.data.password,
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            affiliateCode: response.data.affiliate_code,
            lastLogin: response.data.last_login,
            passwordUpdatedOn: response.data.password_updated_on,
          });
        } else {
          callback(null);
        }
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }
}

export function signIn(email: string, password: string, callback: (user: AppUser | null) => void) {
  if (AppConfig.OFFLINE_MODE) {
    callback(getOfflineUser(email, password));
  } else {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    axios({
      method: 'post',
      url: 'https://api.' + AppConfig.DOMAIN + '/login',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response: any) {
        if (response.data.success) {
          callback({
            id: response.data.id,
            email: response.data.email,
            password: response.data.password,
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            affiliateCode: response.data.affiliate_code,
            lastLogin: response.data.last_login,
            passwordUpdatedOn: response.data.password_updated_on,
          });
        } else {
          callback(null);
        }
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }
}

export function getWallet(
  email: string,
  password: string,
  token: string,
  callback: (wallet: string) => void
) {
  if (AppConfig.CONST_WALLETS) {
    callback(getWalletAddress(token));
  } else {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('token', token);

    axios({
      method: 'post',
      url: 'https://api.' + AppConfig.DOMAIN + '/get_wallet',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response: any) {
        callback(response.data.address);
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }
}

export function changeNames(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  callback: (user: AppUser | null) => void
) {
  if (AppConfig.OFFLINE_MODE) {
    callback(getOfflineUser(email, password, firstName, lastName));
  } else {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('firstname', firstName);
    formData.append('lastname', lastName);

    axios({
      method: 'post',
      url: 'https://api.' + AppConfig.DOMAIN + '/change_names',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response: any) {
        callback({
          id: response.data.id,
          email: response.data.email,
          password: response.data.password,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          affiliateCode: response.data.affiliate_code,
          lastLogin: response.data.last_login,
          passwordUpdatedOn: response.data.password_updated_on,
        });
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }
}

export function updatePassword(
  email: string,
  password: string,
  newPassword: string,
  callback: (user: AppUser | null) => void
) {
  if (AppConfig.OFFLINE_MODE) {
    callback(getOfflineUser(email, newPassword));
  } else {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('new_password', newPassword);

    axios({
      method: 'post',
      url: 'https://api.' + AppConfig.DOMAIN + '/update_password',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response: any) {
        callback({
          id: response.data.id,
          email: response.data.email,
          password: response.data.password,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          affiliateCode: response.data.affiliate_code,
          lastLogin: response.data.last_login,
          passwordUpdatedOn: response.data.password_updated_on,
        });
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }
}
