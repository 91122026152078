import React, { PropsWithChildren } from "react";
import BlueButton from "../blue-button";

type BlockProps = PropsWithChildren<{
  title: string;
  text: string;
  buttonText: string;
  buttonLink: string;
}>;

const Block = (props: BlockProps) => {
  return (
    <div className="flex justify-between w-full my-40">
      <div className="flex flex-col justify-around w-3/5">
        <h2 className="text-3xl font-bold">{props.title}</h2>
        <p className="text-gray-500 font-semibold">{props.text}</p>
        <div>
          <BlueButton text={props.buttonText} anchor={props.buttonLink}></BlueButton>
        </div>
      </div>
      <div className="w-1/3">{props.children}</div>
    </div>
  );
};

export default Block;
