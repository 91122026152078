import React from "react";
import Loadable from "react-loadable";
import FacebookLoader from "../components/loaders/facebook-loader";

const PlotlyLoadable = Loadable({
  loader: () => import(`react-plotly.js`),
  loading: ({ timedOut }) =>
    timedOut ? (
      <blockquote>Error: Loading Plotly timed out.</blockquote>
    ) : (
      <div className="w-full h-full flex justify-center mt-16">
        <FacebookLoader></FacebookLoader>
      </div>
    ),
  timeout: 10000,
});

export default PlotlyLoadable;
