import React, { useState } from "react";
import ArrowSVG from "../../svg/arrow";

type SelectInputProps = {
  label: string;
  items: string[] | number[];
};

const SelectInput = (props: SelectInputProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | number>(props.items[0]);

  const toogleDropdown = () => setOpen(!open);
  const handleItemClick = (_selectedItem: string | number) => {
    setSelectedItem(_selectedItem);
    setOpen(false);
  };

  return (
    <div className={"mb-6 " + (open ? "relative" : "")}>
      <div className="text-xs mb-1 font-semibold">{props.label}</div>
      <div
        className={"py-2 px-4 border-x border-t " + (open ? "rounded-t-lg" : "rounded-lg border-b")}
        onClick={toogleDropdown}
      >
        <div className="flex flex-row items-center">
          <div style={{ fontSize: "16px" }} className="text-gray-800">
            {selectedItem}
          </div>
          <div
            className={
              "ml-auto w-6 h-6 transition duration-300 " +
              (open ? "rotate-180 text-blue-700" : "text-gray-800")
            }
          >
            <ArrowSVG />
          </div>
        </div>
      </div>
      <div
        className={
          open
            ? "bg-white absolute top-full left-0 right-0 max-h-64 overflow-y-auto border-x border-b rounded-b-lg "
            : "hidden"
        }
      >
        {props.items.map(
          (item, index) =>
            selectedItem !== item && (
              <div key={index} className="py-2 px-4" onClick={() => handleItemClick(item)}>
                <div style={{ fontSize: "16px" }} className="text-gray-800">
                  {item}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default SelectInput;
