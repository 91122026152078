import React, { useState } from "react";
import BlueButton from "../../blue-button";
import DashboardLayout from "../dashboard-layout";
import FakeTable from "../fake-table";
import Dropdown from "./dropdown";
import WalletDropdown from "./dropdown/wallet";
import WalletsNav from "./wallets-nav";

type TransferTemplateProps = {
  symbol: string;
};

const TransferTemplate = (props: TransferTemplateProps) => {
  const [firstWallet, setFirstWallet] = useState<string>("Spot Wallet");
  const [secondWallet, setSecondWallet] = useState<string>("Funding Wallet");

  return (
    <DashboardLayout activeTab={-1}>
      <WalletsNav title="Transfer" activeTab={1}></WalletsNav>
      <div className="py-4 mb-1">
        <Dropdown firstWallet={props.symbol} />
      </div>
      <div className="py-4 mb-1">
        <div className="text-xs font-bold pl-1 mb-1">From</div>
        <WalletDropdown wallet={firstWallet} />
      </div>
      <div className="py-4 mb-1">
        <div className="text-xs font-bold pl-1 mb-1">Transfer to</div>
        <WalletDropdown wallet={secondWallet} />
      </div>
      <div className="py-4 mb-1">
        <div className="text-xs font-bold pl-1 mb-1">Amount</div>
        <div className="w-full flex items-start justify-between py-2">
          <div className="contents">
            <input
              className="w-full py-2 px-4 text-sm rounded mr-4"
              placeholder="Amount"
              type="text"
              disabled
            ></input>
          </div>
          <div className="flex justify-right">
            <BlueButton text={"Transfer"} anchor={"#"} minimal={true} />
          </div>
        </div>
      </div>
      <div className="py-4">
        <FakeTable title="Transfer History" headings={["From", "To", "Amount", "Date"]} />
      </div>
    </DashboardLayout>
  );
};

export default TransferTemplate;
