import { Link } from "gatsby";
import React from "react";
import TableRow from "./table-row";

const MarketsList = () => {
  const thClass = "font-normal px-6 py-4";

  return (
    <div className="container relative">
      <div className="p-2 sm:p-5 bg-white rounded-lg drop-shadow-lg sm:drop-shadow-2xl">
        <div className="overflow-x-auto">
          <table className="w-full text-left">
            <thead className="border-b">
              <tr className="text-gray-500 text-xs">
                <th className={thClass}>Name</th>
                <th className={thClass}>Last Price</th>
                <th className={thClass}>Change 24H</th>
                <th className={thClass}>Volume 24H</th>
                <th className={thClass}>Low 24H</th>
                <th className={thClass}>High 24H</th>
              </tr>
            </thead>
            <tbody>
              <TableRow _1stCoin="BTC" _2ndCoin="USDT" />
              <TableRow _1stCoin="ETH" _2ndCoin="USDT" />
              <TableRow _1stCoin="ETH" _2ndCoin="BTC" />
              <TableRow _1stCoin="AVAX" _2ndCoin="USDT" />
              <TableRow _1stCoin="SOL" _2ndCoin="USDT" />
              <TableRow _1stCoin="TRX" _2ndCoin="USDT" />
              <TableRow _1stCoin="XRP" _2ndCoin="USDT" />
              <TableRow _1stCoin="DOGE" _2ndCoin="USDT" />
              <TableRow _1stCoin="MANA" _2ndCoin="USDT" />
              <TableRow _1stCoin="SAND" _2ndCoin="USDT" />
              <TableRow _1stCoin="SHIB" _2ndCoin="USDT" />
              <TableRow _1stCoin="BTC" _2ndCoin="USDC" />
            </tbody>
          </table>
        </div>
        <Link to="/markets" className="block pb-2 pt-6 text-center text-sm font-semibold">
          Show all Markets
        </Link>
      </div>
    </div>
  );
};

export default MarketsList;
