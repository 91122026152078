import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import WhiteIcon from "./../../images/icon-white.svg";

const WelcomeBanner = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteName
          }
        }
      }
    `
  );

  return (
    <div className="h-44 bg-gradient-to-tr from-indigo-500 via-purple-500 to-rose-500 relative">
      <div className="w-11/12 mx-auto pt-7 pl-2">
        <h2 className="text-3xl sm:text-4xl text-white font-bold mb-4">
          {"Welcome to " + site.siteMetadata.siteName + "!"}
        </h2>
        <p className="text-xl sm:text-2xl text-white font-light">
          {"Join & earn up to "}
          <span className="font-semibold">300 USDT</span>
          <br />
          {" as welcome gift!"}
        </p>
      </div>
      <img src={WhiteIcon} className="absolute right-4 bottom-4 h-20 sm:h-24"></img>
    </div>
  );
};

export default WelcomeBanner;
