import { FileUploader } from "evergreen-ui";
import { useStaticQuery, graphql } from "gatsby";
import React, { useState } from "react";
import BlueButton from "../../../components/blue-button";
import DashboardLayout from "../../../components/dashboard/dashboard-layout";
import DocumentInput from "../../../components/dashboard/settings/document-input";
import FileUploaderInput from "../../../components/dashboard/settings/file-uploader-input";
import SelectInput from "../../../components/dashboard/settings/select-input";
import SettingsNav from "../../../components/dashboard/settings/settings-nav";
import TextInput from "../../../components/dashboard/settings/text-input";
import { countries } from "../../../data/countries";
import { months } from "../../../data/months";

const Verification = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteShortName
          }
        }
      }
    `
  );

  const [selectedDocument, setSelectedDocument] = useState<number>(0);

  return (
    <DashboardLayout activeTab={4}>
      <SettingsNav title="Settings" subtitle="Verification" activeTab={2}></SettingsNav>
      <div className="py-4">
        <h2 className="text-base font-bold mb-4">Individual Account</h2>
        <p className="text-xxs text-gray-800 font-thin mb-10 tracking-wider">
          Please be advised {site.siteMetadata.siteShortName} may not make the services available in
          all markets and jurisdictions and may restrict or prohibit use of the services from
          certain jurisdiction. We currently only accept customers from the jurisdictions or
          countries listed below in order to comply with all applicable laws and regulations. Please
          select your country correctly.
        </p>
        <h2 className="text-base font-bold mb-2">Personal information</h2>
        <p className="text-xs text-gray-500 mb-8">Fill the representative identity</p>
        <TextInput label="First Name"></TextInput>
        <TextInput label="Last Name"></TextInput>
        <SelectInput label="Citizenship" items={countries.map((item) => item.en_short_name)} />
        <SelectInput label="Day of birth" items={Array.from({ length: 31 }, (_, i) => i + 1)} />
        <SelectInput label="Month of birth" items={months.map((month) => month.name)} />
        <SelectInput
          label="Year of birth"
          items={Array.from({ length: 54 }, (_, i) => i + 1951).reverse()}
        />
        <h2 className="text-base font-bold mb-2 mt-12">Document</h2>
        <p className="text-xs text-gray-500 mb-8">Select the document you wish to upload</p>
        <div className="flex flex-wrap gap-y-5 mb-10">
          <DocumentInput
            name="Passport"
            pressed={selectedDocument === 1}
            onClick={() => setSelectedDocument(1)}
          />
          <DocumentInput
            name="Driver license"
            pressed={selectedDocument === 2}
            onClick={() => setSelectedDocument(2)}
          />
          <DocumentInput
            name="Identity Card"
            pressed={selectedDocument === 3}
            onClick={() => setSelectedDocument(3)}
          />
        </div>
        <TextInput label="Document series and number" placeholder="00 00 0000"></TextInput>
        <FileUploaderInput label="Documents photo" customLabel={true} />
        <div className="flex justify-center my-10">
          <BlueButton minimal={false} text="Complete" anchor="/dashboard"></BlueButton>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Verification;
