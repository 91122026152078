export const AppConfig = {
  DOMAIN: 'newnax.com',
  OFFLINE_MODE: true,
  CONST_WALLETS: true,
  wallets: {
    btc: '1M4rviekEAVRSpkJP1kseNGW4QAa9JzPnd',
    xrp: 'rDdaCEXkh4yVwa7BrtPBSj6Weu9Voigsst',
    neo: 'NYtsu21bkkXctriA4g8e7s6Fuk6um7FQbu',
    eth: '0xCf94Bb1c23c43DaDd5c707532599faF81D9399c9',
    sol: 'BNESNMGh4xHn3v1TeF7ksWFXy2ffrbvS1eyssU81i6Hv',
  },
};
