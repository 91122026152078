import { Button, TextareaField, TextInputField, toaster } from "evergreen-ui";
import { navigate } from "gatsby";
import React, { useState } from "react";
import FileUploaderInput from "../components/dashboard/settings/file-uploader-input";
import Layout from "../components/layout";
import { sleep } from "../utils/tools";

const Support = () => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Layout>
      <div className="container relative">
        <div className="my-10 sm:my-20">
          <h1 className="text-2xl sm:text-4xl font-bold mb-8">Submit a request</h1>
          <div className="w-full" style={{ maxWidth: "650px" }}>
            <TextInputField
              fontFamily="Manrope"
              required
              label="Your email address"
              className="!h-10"
            ></TextInputField>
            <TextInputField required label="Subject" className="!h-10"></TextInputField>
            <TextareaField
              className="!h-40"
              required
              label="Description"
              hint="Please enter the details of your request. A member of our support staff will respond as soon as possible."
            ></TextareaField>
            <FileUploaderInput label="Attachments(optional)" customLabel={false} />
            <Button
              isLoading={loading}
              size="large"
              marginRight={16}
              appearance="primary"
              className="w-40"
              onClick={async () => {
                setLoading(true);
                await sleep(1000);
                toaster.success("Request sent!");
                navigate("/");
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Support;
