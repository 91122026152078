import React from "react";

const DocumentSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill="currentColor">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6 3.75C17.7325 3.75 18.5367 3.75058 19.1661 3.80201C19.7867 3.85271 20.171 3.94909 20.4755 4.10423C21.087 4.41582 21.5842 4.913 21.8958 5.52453C22.0509 5.829 22.1473 6.21325 22.198 6.83388C22.2494 7.46327 22.25 8.26752 22.25 9.4V18.6C22.25 19.7325 22.2494 20.5367 22.198 21.1661C22.1473 21.7867 22.0509 22.171 21.8958 22.4755C21.5842 23.087 21.087 23.5842 20.4755 23.8958C20.171 24.0509 19.7867 24.1473 19.1661 24.198C18.5367 24.2494 17.7325 24.25 16.6 24.25H5.75V3.75H16.6ZM21.1565 2.76772C20.6053 2.48688 20.0017 2.36527 19.2883 2.30699C18.5906 2.24999 17.7252 2.24999 16.633 2.25H16.633H16.6H5C4.58579 2.25 4.25 2.58579 4.25 3V25C4.25 25.4142 4.58579 25.75 5 25.75H16.6H16.633C17.7252 25.75 18.5906 25.75 19.2883 25.693C20.0017 25.6347 20.6053 25.5131 21.1565 25.2323C22.0502 24.7769 22.7769 24.0502 23.2323 23.1565C23.5131 22.6053 23.6347 22.0017 23.693 21.2883C23.75 20.5906 23.75 19.7252 23.75 18.633V18.6V9.4V9.36698C23.75 8.27485 23.75 7.40935 23.693 6.71173C23.6347 5.99835 23.5131 5.39472 23.2323 4.84355C22.7769 3.94978 22.0502 3.22312 21.1565 2.76772ZM9 17.25C8.58579 17.25 8.25 17.5858 8.25 18C8.25 18.4142 8.58579 18.75 9 18.75H19C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25H9ZM8.25 21C8.25 20.5858 8.58579 20.25 9 20.25H17C17.4142 20.25 17.75 20.5858 17.75 21C17.75 21.4142 17.4142 21.75 17 21.75H9C8.58579 21.75 8.25 21.4142 8.25 21ZM14 13.5C12.6193 13.5 11.5 12.3807 11.5 11C11.5 9.61929 12.6193 8.5 14 8.5C15.3807 8.5 16.5 9.61929 16.5 11C16.5 12.3807 15.3807 13.5 14 13.5ZM10 11C10 8.79086 11.7909 7 14 7C16.2091 7 18 8.79086 18 11C18 13.2091 16.2091 15 14 15C11.7909 15 10 13.2091 10 11Z"
      ></path>
    </svg>
  );
};

export default DocumentSVG;
