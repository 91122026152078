import React, { useEffect, useState } from "react";
import { coins } from "../../../../data/coins";
import { getCoinLogoBySymbol, getDashboardCoinNameByAbbr } from "../../../../utils/tools";
import ArrowSVG from "../../../svg/arrow";

type DropdownProps = {
  firstWallet: string;
  setToken?: (token: string) => void;
  setWallet?: (wallet: string) => void;
};

const Dropdown = (props: DropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>(props.firstWallet);

  useEffect(() => {
    setSelectedItem(props.firstWallet);
    props.setToken && props.setToken(props.firstWallet);
  }, []);

  const toogleDropdown = () => setOpen(!open);
  const handleItemClick = (token: string) => {
    props.setToken && props.setToken(token);
    setSelectedItem(token);
    setOpen(false);
  };

  return (
    <div className={open ? "relative" : ""}>
      <div
        className={"px-4 py-3 border-x border-t shadow " + (open ? "rounded-t-2xl" : "rounded-2xl")}
        onClick={toogleDropdown}
      >
        <div className="flex flex-row items-center">
          <img src={getCoinLogoBySymbol(selectedItem)} className="w-10 h-10 mr-4"></img>
          <div>
            <div className="text-xs text-gray-500">
              {selectedItem}
              <span className="text-gray-500 pl-1">{getDashboardCoinNameByAbbr(selectedItem)}</span>
            </div>
            <div>0</div>
          </div>
          <div
            className={
              "ml-auto w-8 h-8 transition duration-300 " +
              (open ? "rotate-180 text-blue-700" : "text-gray-800")
            }
          >
            <ArrowSVG />
          </div>
        </div>
      </div>
      <div
        className={
          open
            ? "bg-white absolute top-full left-0 right-0 max-h-64 overflow-y-auto border-x border-b rounded-b-2xl shadow"
            : "hidden"
        }
      >
        {coins.map(
          (item, index) =>
            selectedItem !== item.abbr && (
              <div
                key={index}
                className="flex flex-row px-4 py-3"
                onClick={() => handleItemClick(item.abbr)}
              >
                <img src={getCoinLogoBySymbol(item.abbr)} className="w-10 h-10 mr-4"></img>
                <div>
                  <div className="text-xs text-gray-500">
                    {item.abbr}
                    <span className="text-gray-500 pl-1">
                      {getDashboardCoinNameByAbbr(item.abbr)}
                    </span>
                  </div>
                  <div>0</div>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default Dropdown;
