import { Avatar } from "evergreen-ui";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";
import DashboardLayout from "../../../components/dashboard/dashboard-layout";
import SettingsNav from "../../../components/dashboard/settings/settings-nav";
import { AppUser } from "../../../model/types";
import { changeNames } from "../../../utils/myapi";
import { getAvatarString } from "../../../utils/tools";

const Settings = () => {
  const [lsUser, setLsUser] = useLocalStorage<AppUser | null>("user", null);
  const [user, setUser] = useState<AppUser | null>(useReadLocalStorage<AppUser>("user"));
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");

  useEffect(() => {}, []);

  const onFirstnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstname(e.target.value);
    if (user) {
      user.firstName = e.target.value;
      setUser(user);
    }
  };

  const onLastnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(e.target.value);
    if (user) {
      user.lastName = e.target.value;
      setUser(user);
    }
  };

  const onUpdate = () => {
    if (user) {
      changeNames(user.email, user.password, firstname, lastname, (updatedUser) => {
        setLsUser(updatedUser);
        navigate("/dashboard");
      });
    }
  };

  return (
    <DashboardLayout activeTab={4}>
      <SettingsNav title="Settings" subtitle="General" activeTab={0}></SettingsNav>
      <div className="py-4">
        <h2 className="text-base font-bold mb-2">Profile</h2>
        <p className="text-xs text-gray-500 mb-8">Profile privacy settings</p>
        <div className="grid grid-cols-4 gap-x-4">
          <div className="flex justify-center items-center">
            <Avatar name={getAvatarString(firstname || lastname ? user : lsUser)} size={64} />
          </div>
          <div
            className="col-span-3 bg-gray-50 border rounded-lg overflow-hidden"
            style={{ fontSize: "16px" }}
          >
            <input
              type="text"
              value={firstname}
              placeholder="First name"
              onChange={(e) => onFirstnameChange(e)}
              className="py-2 px-4 outline-none w-full bg-gray-50 focus:bg-gray-100 placeholder:font-thin"
            ></input>
            <input
              type="text"
              value={lastname}
              placeholder="Last name"
              onChange={(e) => onLastnameChange(e)}
              className="py-2 px-4 outline-none w-full bg-gray-50 focus:bg-gray-100 placeholder:font-thin"
            ></input>
          </div>
          <div className="col-start-2 col-span-3 text-xs pt-2 text-gray-600">
            Your name will be diplayed as{" "}
            <strong className="text-gray-900">
              {firstname || lastname
                ? firstname + " " + lastname
                : lsUser?.firstName || lsUser?.lastName
                ? lsUser.firstName + " " + lsUser.lastName
                : "User #" + user?.id}
            </strong>
          </div>
        </div>
        <button
          onClick={onUpdate}
          className="mt-8 text-center font-semibold bg-blue-700 hover:bg-blue-800 text-white py-2 px-4 text-sm rounded"
        >
          Update
        </button>
      </div>
    </DashboardLayout>
  );
};

export default Settings;
