import { graphql } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import React from "react";
import Layout from "../../components/layout";
import StartTrading from "../../components/features/start-trading";

const H2 = (props: any) => (
  <h2 className="text-xl sm:text-2xl font-black my-4 leading-relaxed sm:leading-loose" {...props} />
);

const Paragraph = (props: any) => (
  <p className="my-4 leading-relaxed sm:leading-loose" {...props} />
);

const Blockquote = (props: any) => (
  <blockquote className="border-l-4 sm:border-l-8 pl-2 sm:pl-4" {...props} />
);

const components = {
  p: Paragraph,
  h2: H2,
  blockquote: Blockquote,
};

type PostProps = {
  data: {
    site: {
      siteMetadata: {
        siteShortName: string;
      };
    };
    mdx: {
      frontmatter: {
        title: string;
        excerpt: string;
        hero_image: ImageDataLike;
        date: any;
      };
      body: string;
    };
  };
  path: string;
  pageContext: {
    id: string;
    slug: string;
  };
};

const NewsArticle = (props: PostProps) => {
  return (
    <Layout>
      <div className="relative container">
        <div className="sm:w-2/3 pb-20 sm:pb-40">
          <h1 className="text-3xl sm:text-4xl font-black mb-2 sm:mb-4 mt-5">
            {props.data.mdx.frontmatter.title.replace(
              /%siteName%/g,
              props.data.site.siteMetadata.siteShortName
            )}
          </h1>
          <p className="text-gray-400 text-xs sm:text-sm">
            Published at {props.data.mdx.frontmatter.date}
          </p>
          <GatsbyImage
            className="my-6"
            loading="eager"
            image={getImage(props.data.mdx.frontmatter.hero_image)!}
            alt={props.data.mdx.frontmatter.title}
          />
          <MDXProvider components={components}>
            <MDXRenderer>
              {props.data.mdx.body.replace(
                /%siteName%/g,
                props.data.site.siteMetadata.siteShortName
              )}
            </MDXRenderer>
          </MDXProvider>
        </div>
      </div>
      <StartTrading />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    site {
      siteMetadata {
        siteShortName
      }
    }
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        hero_image {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, formats: [AUTO])
          }
        }
      }
      body
    }
  }
`;

export default NewsArticle;
