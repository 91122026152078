import React from "react";
import DashboardLayout from "../../../components/dashboard/dashboard-layout";
import WalletBox from "../../../components/dashboard/wallets/wallet-box";
import { coins } from "../../../data/coins";
import { getCoinLogoBySymbol } from "../../../utils/tools";
import WalletsNav from "../../../components/dashboard/wallets/wallets-nav";
import { Link } from "gatsby";

const Wallets = () => {
  const thClass = "font-normal py-4";

  return (
    <DashboardLayout activeTab={1}>
      <WalletsNav title="Wallets" activeTab={-1}>
        <div className="mt-10">
          <div className="text-xxs text-gray-500 font-semibold uppercase mb-2">Total balance</div>
          <div className="text-xl font-bold">
            0.00000000 <span className="text-gray-600">BTC</span>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-6">
          <WalletBox walletName="Funding Wallet" />
          <WalletBox walletName="Spot Wallet" />
        </div>
        <div className="my-10">
          <div className="overflow-x-auto">
            <table className="w-full text-left table-fixed">
              <thead className="border-y">
                <tr className="text-gray-500 text-xs">
                  <th className={thClass + " w-32 pl-2"}>Asset</th>
                  <th className={thClass + " w-20"}>Avaliable</th>
                  <th className={thClass + " w-20"}>Locked</th>
                  <th className={thClass + " w-20"}>Total</th>
                  <th className={thClass + " w-20"}>USD Value</th>
                  <th className={thClass + " w-60"}></th>
                </tr>
              </thead>
              <tbody>
                {coins.map((item, index) => (
                  <tr key={index} className="border-b">
                    <th className="align-middle text-sm py-3 px-2 sm:px-6 text-gray-800 flex items-center">
                      <img
                        src={getCoinLogoBySymbol(item.abbr)}
                        className="w-5 h-5 rounded-full mr-3"
                      ></img>
                      <div className="">
                        <span className="text-sm font-semibold">{item.abbr}</span>
                      </div>
                    </th>
                    <th className="text-sm text-gray-600">0</th>
                    <th className="text-sm text-gray-600">0</th>
                    <th className="text-sm text-gray-600">0</th>
                    <th className="text-sm text-gray-600">0.00</th>
                    <th className="text-xs font-normal text-blue-700 flex flex-row gap-8">
                      <Link to={"/dashboard/wallets/deposit/" + item.abbr}>Deposit</Link>
                      <Link to={"/dashboard/wallets/withdraw/" + item.abbr}>Withdraw</Link>
                      <Link to={"/dashboard/wallets/transfer/" + item.abbr}>Transfer</Link>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </WalletsNav>
    </DashboardLayout>
  );
};

export default Wallets;
