import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import HeroTicker from "../components/markets/hero-ticker";
import MarketsTableRow from "../components/markets/markets-table-row";
import { Lang } from "../model/enums";
import { getAllSymbolsTicker24h } from "../utils/binance/api";
import { getCoinNameByAbbr, getCoinNameFromSymbol } from "../utils/tools";

const Markets = () => {
  const thClass = "font-normal px-6 py-4";

  const [tickers, setTickers] = useState<Ticker[]>([]);
  const [topVolumeCoins, setTopVolumeCoins] = useState<Ticker[]>([]);
  const [topGainers, setTopGainers] = useState<Ticker[]>([]);
  const [topLosers, setTopLosers] = useState<Ticker[]>([]);

  useEffect(() => {
    getAllSymbolsTicker24h(function (_tickers: Ticker[]) {
      setTickers(_tickers);

      const sortedByPriceChange = [..._tickers].sort(
        (a, b) => a.priceChangePercent - b.priceChangePercent
      );

      setTopLosers(sortedByPriceChange.slice(0, 5));
      setTopGainers(sortedByPriceChange.reverse().slice(0, 5));

      const sortedByVolume = [..._tickers].sort((a, b) => a.quoteVolume - b.quoteVolume);

      setTopVolumeCoins(sortedByVolume.reverse().slice(0, 5));
    });
  }, []);

  return (
    <Layout withLoading>
      <div className="container relative">
        <h1 className="text-2xl sm:text-4xl font-bold my-4 sm:my-10">Markets</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-4 md:gap-8 lg:gap-10">
          <HeroTicker title="Top Volume" pairs={topVolumeCoins} />
          <HeroTicker title="Top Gainers" pairs={topGainers} />
          <HeroTicker title="Top Losers" pairs={topLosers} />
        </div>
        <div className="my-20">
          <div className="overflow-x-auto">
            <table className="w-full text-left">
              <thead className="border-b">
                <tr className="text-gray-500 text-xs">
                  <th className={thClass}>Name</th>
                  <th className={thClass}>Last Price</th>
                  <th className={thClass}>Change 24H</th>
                  <th className={thClass}>Low 24H</th>
                  <th className={thClass}>High 24H</th>
                  <th className={thClass}>Volume 24H</th>
                  <th className={thClass}></th>
                </tr>
              </thead>
              <tbody>
                {tickers.map((ticker, index) => (
                  <MarketsTableRow
                    key={index}
                    coinName={getCoinNameByAbbr(getCoinNameFromSymbol(ticker.symbol))}
                    _1stCoin={getCoinNameFromSymbol(ticker.symbol)}
                    _2ndCoin="USDT"
                    price={ticker.lastPrice}
                    priceChange={ticker.priceChangePercent}
                    volume={ticker.quoteVolume}
                    low={ticker.lowPrice}
                    high={ticker.highPrice}
                  ></MarketsTableRow>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Markets;
