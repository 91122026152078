import React, { useState } from "react";
import ControlInput, { ControlInputType } from "./control-input";

type ControlPanelProps = {
  _1stCoin: string;
  _2ndCoin: string;
};

const ControlPanel = (props: ControlPanelProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="bg-gray-50 border px-4 py-5 rounded-lg w-full">
      <ul className="flex gap-x-4 mb-6 text-xs text-gray-600 cursor-pointer">
        <li
          className={selectedIndex === 0 ? "font-bold text-blue-700" : ""}
          onClick={() => setSelectedIndex(0)}
        >
          Limit
        </li>
        <li
          className={selectedIndex === 1 ? "font-bold text-blue-700" : ""}
          onClick={() => setSelectedIndex(1)}
        >
          Market
        </li>
        <li
          className={selectedIndex === 2 ? "font-bold text-blue-700" : ""}
          onClick={() => setSelectedIndex(2)}
        >
          Stop-limit
        </li>
      </ul>
      <div className={selectedIndex === 0 ? "block" : "hidden"}>
        <div className="grid grid-cols-2 gap-8">
          <ControlInput
            type={ControlInputType.LIMIT}
            isBuying={true}
            _1stCoin={props._1stCoin}
            _2ndCoin={props._2ndCoin}
          />
          <ControlInput
            type={ControlInputType.LIMIT}
            isBuying={false}
            _1stCoin={props._1stCoin}
            _2ndCoin={props._2ndCoin}
          />
        </div>
      </div>
      <div className={selectedIndex === 1 ? "block" : "hidden"}>
        <div className="grid grid-cols-2 gap-8">
          <ControlInput
            type={ControlInputType.MARKET}
            isBuying={true}
            _1stCoin={props._1stCoin}
            _2ndCoin={props._2ndCoin}
          />
          <ControlInput
            type={ControlInputType.MARKET}
            isBuying={false}
            _1stCoin={props._1stCoin}
            _2ndCoin={props._2ndCoin}
          />
        </div>
      </div>
      <div className={selectedIndex === 2 ? "block" : "hidden"}>
        <div className="grid grid-cols-2 gap-8">
          <ControlInput
            type={ControlInputType.STOP_LIMIT}
            isBuying={true}
            _1stCoin={props._1stCoin}
            _2ndCoin={props._2ndCoin}
          />
          <ControlInput
            type={ControlInputType.STOP_LIMIT}
            isBuying={false}
            _1stCoin={props._1stCoin}
            _2ndCoin={props._2ndCoin}
          />
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;
