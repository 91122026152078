import { Button } from "evergreen-ui";
import React from "react";
import FormHeader from "./form-header";
import InvitationCodeInput from "./invitation-code-input";
import PasswordInput from "./password-input";

type SignupSecretsFormProps = {
  step: number;
  onPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInvitationCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const SignupSecretsForm = (props: SignupSecretsFormProps) => {
  return (
    <form autoComplete="off" className={props.step === 1 ? "block" : "hidden"}>
      <FormHeader
        firstLine="Set up password"
        secondLine="Create a password that you will use to login in the future"
      />
      <PasswordInput onChange={props.onPasswordChange} />
      <InvitationCodeInput onChange={props.onInvitationCodeChange} />
      <Button
        fontFamily="Manrope"
        appearance="primary"
        intent="none"
        size="large"
        className="w-full"
        onClick={props.onButtonClick}
      >
        Complete
      </Button>
    </form>
  );
};

export default SignupSecretsForm;
