import React, { useState } from "react";
import ArrowSVG from "../../../svg/arrow";

type WalletDropdownProps = {
  wallet: string;
};

const wallets = ["Spot Wallet", "Funding Wallet"];

const WalletDropdown = (props: WalletDropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>(props.wallet);

  const toogleDropdown = () => setOpen(!open);
  const handleItemClick = (selectedWallet: string) => {
    setSelectedItem(selectedWallet);
    setOpen(false);
  };

  return (
    <div className={open ? "relative" : ""}>
      <div
        className={"px-4 py-3 border-x border-t shadow " + (open ? "rounded-t-2xl" : "rounded-2xl")}
        onClick={toogleDropdown}
      >
        <div className="flex flex-row items-center">
          <div>
            <div className="text-xs text-gray-500">{selectedItem}</div>
            <div>0</div>
          </div>
          <div
            className={
              "ml-auto w-8 h-8 transition duration-300 " +
              (open ? "rotate-180 text-blue-700" : "text-gray-800")
            }
          >
            <ArrowSVG />
          </div>
        </div>
      </div>
      <div
        className={
          open
            ? "bg-white absolute top-full left-0 right-0 max-h-64 overflow-y-auto border-x border-b rounded-b-2xl shadow"
            : "hidden"
        }
      >
        {wallets.map(
          (item, index) =>
            selectedItem !== item && (
              <div
                key={index}
                className="flex flex-row px-4 py-3"
                onClick={() => handleItemClick(item)}
              >
                <div>
                  <div className="text-xs text-gray-500">{item}</div>
                  <div>0</div>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default WalletDropdown;
