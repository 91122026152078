import { Link } from "gatsby";
import React, { PropsWithChildren } from "react";

type SidebarButtonLinkProps = PropsWithChildren<{
  text: string;
  to: string;
  pressed?: boolean;
  mobile: boolean;
}>;

const SidebarButtonLink = (props: SidebarButtonLinkProps) => {
  return (
    <Link
      to={props.to}
      className={
        "flex flex-row items-center " +
        (props.mobile ? "justify-center bg-gray-50 border rounded-lg py-1 px-3 " : "text-gray-800 mb-8 ") +
        (props.pressed ? "text-blue-700 border-blue-100 " : " ") +
        (props.mobile && props.pressed ? "order-first shadow-sm border" : "")
      }
    >
      <div className={props.mobile ? "w-6 h-6" : "w-6 h-6"}>{props.children}</div>
      <span className={props.mobile ? "pl-2 text-sm font-semibold" : "pl-4 text-base font-bold"}>
        {props.text}
      </span>
    </Link>
  );
};

export default SidebarButtonLink;
