import React from "react";
import AffiliateSVG from "../svg/affiliate";
import DashboardSVG from "../svg/dashboard";
import LogoutSVG from "../svg/logout";
import OrdersListSVG from "../svg/orders-list";
import SettingsSVG from "../svg/settings";
import WalletSVG from "../svg/wallet";
import SidebarButton from "./sidebar-button";
import SidebarButtonLink from "./sidebar-button-link";

type SidebarProps = {
  activeTab: number;
  onLogout: () => void;
  mobile: boolean;
};

const Sidebar = (props: SidebarProps) => {
  return (
    <div
      className={"flex " + (props.mobile ? "flex-row justify-between" : "flex-col")}
      style={{ width: props.mobile ? "770px" : "100%" }}
    >
      <SidebarButtonLink
        text="Workspace"
        to="/dashboard/"
        pressed={props.activeTab === 0}
        mobile={props.mobile}
      >
        <DashboardSVG />
      </SidebarButtonLink>
      <SidebarButtonLink
        text="Wallets"
        to="/dashboard/wallets"
        pressed={props.activeTab === 1}
        mobile={props.mobile}
      >
        <WalletSVG />
      </SidebarButtonLink>
      <SidebarButtonLink
        text="Orders"
        to="/dashboard/orders"
        pressed={props.activeTab === 2}
        mobile={props.mobile}
      >
        <OrdersListSVG />
      </SidebarButtonLink>
      <SidebarButtonLink
        text="Affiliates"
        to="/dashboard/affiliates"
        pressed={props.activeTab === 3}
        mobile={props.mobile}
      >
        <AffiliateSVG />
      </SidebarButtonLink>
      <SidebarButtonLink
        text="Settings"
        to="/dashboard/settings"
        pressed={props.activeTab === 4}
        mobile={props.mobile}
      >
        <SettingsSVG />
      </SidebarButtonLink>
      <SidebarButton text="Log Out" onClick={props.onLogout} mobile={props.mobile}>
        <LogoutSVG />
      </SidebarButton>
    </div>
  );
};

export default Sidebar;
