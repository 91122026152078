import React from "react";

type FakeTableProps = {
  title: string;
  headings: string[];
};

const FakeTable = (props: FakeTableProps) => {
  return (
    <>
      <h2 className="font-bold text-sm">{props.title}</h2>
      <table className="w-full table-fixed mt-4 h-40">
        <thead>
          <tr className="border-b text-xs">
            {props.headings.map((heading, index) => (
              <th key={index} className="w-1/3 pb-2 font-normal text-left text-gray-600">
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </>
  );
};

export default FakeTable;
