import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { isEmailAllowed, signUp } from '../utils/myapi';
import Header from '../components/header/header';
import { useLocalStorage } from 'usehooks-ts';
import WelcomeBanner from '../components/registration/welcome-banner';
import SignupEmailForm from '../components/registration/signup-email-form';
import SignupSecretsForm from '../components/registration/signup-secrets-form';
import { AppUser } from '../model/types';
import LogoLink from '../components/header/logo-link';

const SignUp = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteName
          }
        }
      }
    `
  );

  const [email, setEmail] = useLocalStorage<string>('email', '');
  const [password, setPassword] = useLocalStorage<string>('password', '');
  const [invitationCode, setInvitationCode] = useLocalStorage<string>('invitation_code', '');
  const [registered, setRegistered] = useLocalStorage<boolean>('registered', false);

  const [user, setUser] = useLocalStorage<AppUser | null>('user', null);

  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);

  function validateEmail() {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
    setInvalidEmail(false);
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleInvitationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvitationCode(e.target.value);
  };

  const handleComleteButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    signUp(email, password, invitationCode, (user) => {
      if (user) {
        setUser(user);
        setRegistered(true);
        navigate('/signin');
      }
    });
  };

  function handleCheckEmailButton(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    if (validateEmail()) {
      isEmailAllowed(email, (result) => {
        if (result) setStep(1);
      });
    } else {
      setInvalidEmail(true);
    }
  }

  return (
    <div className="relative">
      <div className="lg:hidden">
        <div className="mb-2">
          <Header />
        </div>
        <WelcomeBanner />
        <div className="container pt-16">
          <SignupEmailForm
            step={step}
            invalidEmail={invalidEmail}
            onEmailChange={handleEmailChange}
            onButtonClick={handleCheckEmailButton}
          />
          <SignupSecretsForm
            step={step}
            onPasswordChange={handlePasswordChange}
            onInvitationCodeChange={handleInvitationCodeChange}
            onButtonClick={handleComleteButton}
          />
        </div>
      </div>
      <div className="hidden lg:block container">
        <div className="mt-6">
          <LogoLink />
        </div>
        <div className="fixed left-0 top-20 bottom-0 w-1/2 flex justify-center items-center">
          <div className="pl-4 sm:pl-8 lg:pl-10 xl:pl-40">
            <SignupEmailForm
              step={step}
              invalidEmail={invalidEmail}
              onEmailChange={handleEmailChange}
              onButtonClick={handleCheckEmailButton}
            />
            <SignupSecretsForm
              step={step}
              onPasswordChange={handlePasswordChange}
              onInvitationCodeChange={handleInvitationCodeChange}
              onButtonClick={handleComleteButton}
            />
          </div>
        </div>
        <div className="signup-desktop-banner px-20 lg:px-28 xl:px-20 2xl:px-36 pt-52 bg-cover">
          <div className='w-2/5'>
            <h2 className="text-3xl lg:text-4xl xl:text-5xl text-white font-bold mb-4">
              {'Welcome to ' + site.siteMetadata.siteName + '!'}
            </h2>
            <p className="text-2xl xl:text-3xl text-white font-light">
              {'Join & earn up to '}
              <span className="font-semibold">300 USDT</span>
              {' as welcome gift!'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
