import React from "react";

const OrdersListSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="currentColor">
      <path d="M8.55 26.5Q7.5 26.5 6.75 25.775Q6 25.05 6 24Q6 22.95 6.75 22.225Q7.5 21.5 8.55 21.5Q9.55 21.5 10.275 22.25Q11 23 11 24Q11 25 10.275 25.75Q9.55 26.5 8.55 26.5ZM8.5 14Q7.45 14 6.725 13.275Q6 12.55 6 11.5Q6 10.45 6.725 9.725Q7.45 9 8.5 9Q9.55 9 10.275 9.725Q11 10.45 11 11.5Q11 12.55 10.275 13.275Q9.55 14 8.5 14ZM8.55 39Q7.5 39 6.75 38.275Q6 37.55 6 36.55Q6 35.5 6.75 34.75Q7.5 34 8.55 34Q9.55 34 10.275 34.75Q11 35.5 11 36.55Q11 37.55 10.275 38.275Q9.55 39 8.55 39ZM16 38V35H42V38ZM16 25.5V22.5H42V25.5ZM16 13V10H42V13Z" />
    </svg>
  );
};

export default OrdersListSVG;
