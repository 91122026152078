import { coins } from "../../data/coins";
import { getKBySymbol } from "../tools";

export function getTrades(symbol: string, limit: number, callback: (trades: any[]) => void) {
  fetch(`https://api.binance.com/api/v3/trades?symbol=${symbol}&limit=${limit}`)
    .then((response) => response.json())
    .then((data) => {
      callback(
        data.map((item: any) => {
          return { ...item, price: item.price * 2 };
        })
      );
    });
}

export function getSymbolPriceTicker(symbol: string, callback: (price: number) => void) {
  fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`)
    .then((response) => response.json())
    .then((data) => {
      callback(data.price * getKBySymbol(symbol));
    });
}

export function getAllSymbolsTicker24h(callback: (_tickers: Ticker[]) => void) {
  let url = "https://api.binance.com/api/v3/ticker/24hr?symbols=[";
  for (let i = 0; i < coins.length; i++) {
    if (coins[i].abbr !== "USDT" && coins[i].abbr !== "USDC") {
      url += '"' + coins[i].abbr + 'USDT"';

      if (i < coins.length - 1) {
        url += ",";
      }
    }
  }
  url += "]";

  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      callback(
        data.map((item: any) => {
          const k = getKBySymbol(item.symbol);
          return {
            ...item,
            openPrice: item.openPrice * k,
            highPrice: item.highPrice * k,
            lowPrice: item.lowPrice * k,
            weightedAvgPrice: item.weightedAvgPrice * k,
            lastPrice: item.lastPrice * k,
            askPrice: item.askPrice * k,
            bidPrice: item.bidPrice * k,
            prevClosePrice: item.prevClosePrice * k,
            priceChange: item.priceChange * k,
          };
        })
      );
    });
}

export function getTicker24h(
  symbol: string,
  callback: (
    lastPrice: number,
    priceChange24h: number,
    priceChangePercent24h: number,
    highPrice24h: number,
    lowPrice24h: number,
    volume: number,
    quoteVolume: number,
    weightedAvgPrice: number,
    bidPrice: number,
    askPrice: number,
    prevClosePrice: number
  ) => void
) {
  fetch(`https://api.binance.com/api/v3/ticker/24hr?symbol=${symbol}`)
    .then((response) => response.json())
    .then((data) => {
      const k = getKBySymbol(symbol);
      callback(
        data.lastPrice * k,
        data.priceChange,
        data.priceChangePercent,
        data.highPrice * k,
        data.lowPrice * k,
        data.volume,
        data.quoteVolume,
        data.weightedAvgPrice * k,
        data.bidPrice * k,
        data.askPrice * k,
        data.prevClosePrice * k
      );
    });
}

export function getOrderBook(
  symbol: string,
  limit: number,
  callback: (asks: number[][], bids: number[][]) => void
) {
  fetch(`https://api.binance.com/api/v3/depth?symbol=${symbol}&limit=${limit}`)
    .then((response) => response.json())
    .then((data) => {
      const k = getKBySymbol(symbol);

      callback(
        data.asks.map((ask: number[]) => {
          return [ask[0] * k, ask[1]];
        }),
        data.bids.map((bid: number[]) => {
          return [bid[0] * k, bid[1]];
        })
      );
    });
}

export function getKLines(
  symbol: string,
  interval: string,
  limit: number,
  callback: (
    date: number[],
    open: number[],
    high: number[],
    low: number[],
    close: number[],
    volume: number[]
  ) => void
) {
  fetch(
    `https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${interval}&limit=${limit}`
  )
    .then((response) => response.json())
    .then((data) => {
      const k = getKBySymbol(symbol);
      const d = [],
        o = [],
        h = [],
        l = [],
        c = [],
        v = [];

      for (let i = 0; i < data.length; i++) {
        d.push(data[i][0]);
        o.push(data[i][1] * k);
        h.push(data[i][2] * k);
        l.push(data[i][3] * k);
        c.push(data[i][4] * k);
        v.push(data[i][5]);
      }

      callback(d, o, h, l, c, v);
    });
}
