import React from "react";
import Layout from "../components/layout";

type FeeTableRowProps = {
  level: number;
  btc: string;
  c1: number;
  c2: number;
  c3: number;
  c4: number;
  closing?: boolean;
};

const FeeTableRow = (props: FeeTableRowProps) => {
  return (
    <tr>
      <td
        className={
          "border-t px-4 sm:px-6 py-4 text-xs sm:text-sm" + (props.closing ? " border-b" : "")
        }
      >
        <div className="min-w-120">
          <strong>Lv.{props.level}</strong>
          <span className="text-gray-400 font-semibold">{" " + props.btc}</span>
        </div>
      </td>
      <td
        className={
          "border-t border-l border-r px-4 sm:px-6 py-4 text-xs sm:text-sm" +
          (props.closing ? " border-b" : "")
        }
      >
        <div>{props.c1}%</div>
      </td>
      <td
        className={
          "border-t border-r px-4 sm:px-6 py-4 text-xs sm:text-sm" +
          (props.closing ? " border-b" : "")
        }
      >
        <div>{props.c2}%</div>
      </td>
      <td className={"w-4 border-t" + (props.closing ? " border-b" : "")}></td>
      <td
        className={
          "border-t border-l border-r px-4 sm:px-6 py-4 text-xs sm:text-sm" +
          (props.closing ? " border-b" : "")
        }
      >
        <div>{props.c3}%</div>
      </td>
      <td
        className={
          "border-t border-r px-4 sm:px-6 py-4 text-xs sm:text-sm" +
          (props.closing ? " border-b" : "")
        }
      >
        <div>{props.c4}%</div>
      </td>
    </tr>
  );
};

const Fees = () => {
  return (
    <Layout>
      <div className="container relative">
        <div className="my-10 sm:my-20">
          <h1 className="text-2xl sm:text-4xl font-bold mb-2">Fees</h1>
          <h2 className="text-gray-400 font-bold text-sm sm:text-base">
            Transition fee will be reduced by 30% after getting Institutional status
          </h2>
        </div>
        <div className="overflow-x-auto">
          <table
            className="w-full border-separate relative table-auto mb-4"
            style={{ borderSpacing: 0 }}
          >
            <thead>
              <tr>
                <td rowSpan={2}></td>
                <td
                  colSpan={2}
                  className="border-l border-t border-r rounded-t-xl min-w-xxs sm:min-w-xs p-6"
                >
                  <div className="text-xl sm:text-2xl font-bold mb-2">Regular User</div>
                  <div className="text-sm text-gray-500 font-semibold">0% Fee Discount</div>
                </td>
                <td rowSpan={2} className="w-4"></td>
                <td
                  colSpan={2}
                  className="border-l border-t border-r rounded-t-xl min-w-xxs sm:min-w-xs p-6"
                >
                  <div className="text-xl sm:text-2xl font-bold mb-2">Institutional User</div>
                  <div className="text-sm text-green-600 font-semibold">30% Fee Discount</div>
                </td>
              </tr>
              <tr className="text-xs text-gray-500">
                <td className="px-6 py-4 border-l border-t border-r">Maker</td>
                <td className="px-6 py-4 border-t border-r">Taker</td>
                <td className="px-6 py-4 border-l border-t border-r">Maker</td>
                <td className="px-6 py-4 border-t border-r">Taker</td>
              </tr>
            </thead>
            <tbody>
              <FeeTableRow
                level={1}
                btc=" < 30 BTC"
                c1={0.075}
                c2={0.075}
                c3={0.0525}
                c4={0.0525}
              />
              <FeeTableRow level={2} btc="≥ 300 BTC" c1={0.065} c2={0.07} c3={0.0455} c4={0.05} />
              <FeeTableRow level={3} btc="≥ 900 BTC" c1={0.06} c2={0.07} c3={0.042} c4={0.05} />
              <FeeTableRow
                level={4}
                btc="≥ 2700 BTC"
                c1={0.055}
                c2={0.065}
                c3={0.0385}
                c4={0.0455}
              />
              <FeeTableRow level={5} btc="≥ 5000 BTC" c1={0.05} c2={0.065} c3={0.035} c4={0.0455} />
              <FeeTableRow
                level={6}
                btc="≥ 10000 BTC"
                c1={0.045}
                c2={0.055}
                c3={0.0315}
                c4={0.0385}
              />
              <FeeTableRow
                level={7}
                btc="≥ 20000 BTC"
                c1={0.035}
                c2={0.045}
                c3={0.0245}
                c4={0.0315}
                closing
              />
            </tbody>
          </table>
        </div>
        <div className="text-center my-8 sm:mt-10 sm:mb-14 px-4 sm:px-40 text-xs sm:text-sm text-gray-400">
          The Maker-Taker trading mechanism encourages the continuation of price orders and early
          listings, aims to facilitate digital currency trading and maintain price stability.
        </div>
      </div>
    </Layout>
  );
};

export default Fees;
