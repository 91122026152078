import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { getSymbolPriceTicker } from '../utils/binance/api';
import { getPriceFormatBySymbol } from '../utils/tools';

export type SeoProps = {
  description: string;
  lang: string;
  meta: any[];
  title: string;
  symbol: string;
};

const SymbolSeo = (props: SeoProps) => {
  const [title, setTitle] = useState<string>('- | - | ' + props.title);

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.symbol.length > 0) {
        getSymbolPriceTicker(props.symbol, function (price) {
          const tmpTitle =
            '' +
            getPriceFormatBySymbol(props.symbol).format(price) +
            ' | ' +
            props.symbol +
            ' | ' +
            props.title;
          setTitle(tmpTitle);
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <Helmet
      htmlAttributes={{ lang: props.lang }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: `description`,
          content: props.description,
        },
        {
          property: `og:title`,
          content: props.title,
        },
        {
          property: `og:description`,
          content: props.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: ``,
        },
        {
          name: `twitter:title`,
          content: props.title,
        },
        {
          name: `twitter:description`,
          content: props.description,
        },
      ].concat(props.meta)}
    />
  );
};

export default SymbolSeo;
