import React from "react";
import "./facebook-loader-style.css";

const FacebookLoader = () => {
  return (
    <div className="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default FacebookLoader;
