import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { getAllSymbolsTicker24h } from '../../../utils/binance/api';
import {
  getCoinLogoBySymbol,
  getCoinNameByAbbr,
  getPriceFormatBySymbol,
} from '../../../utils/tools';
import ArrowSVG from '../../svg/arrow';

type SymbolDropdownProps = {
  _1stCoin: string;
  _2ndCoin: string;
};

const SymbolDropdown = (props: SymbolDropdownProps) => {
  const f = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const shibF = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [tickers, setTickers] = useState<Ticker[]>([]);

  const [open, setOpen] = useState<boolean>(false);
  const [selectedCoin, setSelectedCoin] = useState<string>(props._1stCoin);

  useEffect(() => {
    getAllSymbolsTicker24h(function (_tickers: Ticker[]) {
      setTickers(_tickers);
    });
  }, []);

  const toogleDropdown = () => setOpen(!open);

  return (
    <div className="relative cursor-pointer">
      <div
        className="flex sm:mr-8 sm:border-r sm:pr-8 sm:font-bold"
        onClick={() => toogleDropdown()}
      >
        <div>
          <div>{`${props._1stCoin} / ${props._2ndCoin}`}</div>
          <div className="text-xs text-gray-400 font-medium">
            {getCoinNameByAbbr(props._1stCoin) + ' Price'}
          </div>
        </div>
        <div className="grow pl-1 pt-1 sm:pt-0">
          <div
            className={
              'w-5 h-5 transition duration-300 text-gray-700 ' + (open ? 'rotate-180' : '')
            }
          >
            <ArrowSVG />
          </div>
        </div>
      </div>
      {open && (
        <div className="bg-white absolute top-full left-0 top-0 max-h-64 overflow-y-auto z-40 w-80 sm:-ml-1 sm:pl-1 my-2 py-1 sm:pr-4 border sm:border-0 rounded-2xl sm:rounded-none shadow sm:shadow-none">
          {tickers.map((coin: Ticker) => {
            const coin1 = coin.symbol.replace('USDT', '');
            return coin1 !== selectedCoin && coin1 !== 'USDT' ? (
              <Link
                to={'/markets/' + coin1 + '_USDT'}
                key={coin.symbol}
                className="flex flex-wrap items-center px-4 sm:px-0 py-3"
              >
                <img src={getCoinLogoBySymbol(coin1)} className="w-8 h-8 mr-4 rounded-full"></img>
                <div className="w-1/2">
                  <div className="text-sm sm:font-bold">
                    {coin.symbol.replace('USDT', '') + ' / USDT'}
                  </div>
                  <div className="text-xs text-gray-400 font-medium truncate">
                    {getCoinNameByAbbr(coin1)}
                  </div>
                </div>
                <div className="ml-auto pr-2 font-normal">
                  <div className="text-sm flex justify-end">
                    {'$' + getPriceFormatBySymbol(coin.symbol).format(coin.lastPrice)}
                  </div>
                  <div
                    className={
                      'text-sm flex justify-end ' +
                      (coin.priceChangePercent > 0 ? 'text-green-600' : 'text-red-500')
                    }
                  >
                    {(coin.priceChangePercent > 0 ? '+' : '') +
                      Number(coin.priceChangePercent).toFixed(2) +
                      '%'}
                  </div>
                </div>
              </Link>
            ) : null;
          })}
        </div>
      )}
    </div>
  );
};

export default SymbolDropdown;
