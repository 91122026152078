import { Pane, Tablist, Tab, Paragraph } from "evergreen-ui";
import React from "react";
import DashboardLayout from "../../components/dashboard/dashboard-layout";
import FakeTable from "../../components/dashboard/fake-table";

const Orders = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [tabs] = React.useState(["Open Orders", "Order History", "Transaction History"]);

  return (
    <DashboardLayout activeTab={2}>
      <Pane height={120}>
        <div className="overflow-auto">
          <div className="px-1 py-4 sm:py-10">
            <Tablist width={400} marginBottom={16} flexBasis={240} marginRight={24}>
              {tabs.map((tab, index) => (
                <Tab
                  fontFamily="Manrope"
                  key={tab}
                  id={tab}
                  onSelect={() => setSelectedIndex(index)}
                  isSelected={index === selectedIndex}
                  aria-controls={`panel-${tab}`}
                >
                  {tab}
                </Tab>
              ))}
            </Tablist>
          </div>
        </div>
        <Pane padding={16} background="white" flex="1">
          <div
            className={
              "overflow-x-auto " + (0 === selectedIndex || 1 === selectedIndex ? "block" : "hidden")
            }
          >
            <div className="w-192 sm:w-full">
              <FakeTable
                title=""
                headings={["Pair", "Side", "Price", "Amount", "Total", "Filled", "Status", "Date"]}
              />
            </div>
          </div>
          <div className={"overflow-x-auto " + (2 === selectedIndex ? "block" : "hidden")}>
            <div className="w-full">
              <FakeTable title="" headings={["Coin", "Amount", "Wallet"]} />
            </div>
          </div>
        </Pane>
      </Pane>
    </DashboardLayout>
  );
};

export default Orders;
