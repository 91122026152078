import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import DocTemplate from "../components/templates/doc";

const CookiesPolicy = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteNameUppercase
          }
        }
      }
    `
  );

  return (
    <Layout>
      <DocTemplate>
        <h1>Cookies Policy</h1>
        <p>
          The {site.siteMetadata.siteNameUppercase} site is using cookies. Cookies are small text files that
          are placed on your computer by websites that you visit. They are widely used in order to
          make websites work, or work more efficiently, as well as to provide information to the
          owners of the site.
        </p>
        <p>
          Cookies are typically stored on your computer's hard drive. Information collected from
          cookies is used by us to evaluate the effectiveness of our Site, analyze trends, and
          administer the Platform. The information collected from cookies allows us to determine
          such things as which parts of our Site are most visited and difficulties our visitors may
          experience in accessing our Site. With this knowledge, we can improve the quality of your
          experience on the Platform by recognizing and delivering more of the most desired features
          and information, as well as by resolving access difficulties. We also use cookies and/or a
          technology known as web bugs or clear gifs, which are typically stored in emails to help
          us confirm your receipt of, and response to, our emails and to provide you with a more
          personalized experience when using our Site.
        </p>
        <p>
          We use a third-party service provider(s), to assist us in better understanding the use of
          our Site. Our service provider(s) will place cookies on the hard drive of your computer
          and will receive information that we select that will educate us on such things as how
          visitors navigate around our site, what products are browsed, and general Transaction
          information. Our service provider(s) analyses this information and provides us with
          aggregate reports. The information and analysis provided by our service provider(s) will
          be used to assist us in better understanding our visitors' interests in our Site and how
          to better serve those interests. The information collected by our service provider(s) may
          be linked to and combined with information that we collect about you while you are using
          the Platform. Our service provider(s) is/are contractually restricted from using
          information they receive from our Site other than to assist us.
        </p>
        <p>
          Your continued use of the {site.siteMetadata.siteNameUppercase} site, as well as any subsequent
          usage, will be interpreted as your consent to cookies being stored on your device.
        </p>
      </DocTemplate>
    </Layout>
  );
};

export default CookiesPolicy;
