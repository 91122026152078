import { Button } from "evergreen-ui";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";
import Header from "../components/header/header";
import EmailInput from "../components/registration/email-input";
import FormHeader from "../components/registration/form-header";
import PasswordInput from "../components/registration/password-input";
import { AppUser } from "../model/types";
import { signIn } from "../utils/myapi";

const SignIn = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteName
          }
        }
      }
    `
  );

  const [email, setEmail] = useLocalStorage<string>("email", "");
  const [password, setPassword] = useLocalStorage<string>("password", "");

  const registered = useReadLocalStorage<boolean | null>("registered");
  const [loggedIn, setLoggedIn] = useLocalStorage<boolean>("logged_in", false);

  const [user, setUser] = useLocalStorage<AppUser | null>("user", null);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleComplete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (registered && user && user.email && user.password) {
      if (email === user.email && password === user.password) {
        setLoggedIn(true);
        navigate("/dashboard");
      }
    } else {
      signIn(email, password, (user) => {
        if (user) {
          setUser(user);
          setLoggedIn(true);
          navigate("/dashboard");
        }
      });
    }
  };

  return (
    <div>
      <div className="mb-2">
        <Header />
      </div>
      <div className="container flex justify-center">
        <form autoComplete="off" className="mt-16 sm:mt-48 w-full px-2 max-w-lg">
          <FormHeader
            firstLine={"Sign In to " + site.siteMetadata.siteName}
            secondLine="Enter your account data"
          />
          <EmailInput onChange={handleEmailChange} />
          <PasswordInput onChange={handlePasswordChange} />
          <Button
            appearance="primary"
            onClick={handleComplete}
            size="large"
            className="w-full mt-6"
          >
            Login
          </Button>
          <Link to="#" className="block text-center text-xs text-gray-800 mt-6 font-bold">
            Forgot password?
          </Link>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
