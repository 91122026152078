import React from "react";
import Layout from "../components/layout";
import RewardsBanner from "../components/rewards-banner";
import Block from "../components/rewards/block";
import Steps from "../components/rewards/steps";
import RegistrationSVG from "./../images/rewards/registration.svg";
import DepositSVG from "./../images/rewards/deposit.svg";
import ActiveTradingSVG from "./../images/rewards/active-trading.svg";
import CumulativeTradingSVG from "./../images/rewards/cumulative-trading.svg";
import { graphql, useStaticQuery } from "gatsby";

const Rewards = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteShortName
          }
        }
      }
    `
  );

  return (
    <Layout removePadding={true}>
      <RewardsBanner />
      <div className="relative container">
        <Steps />
        <Block
          title="Registration"
          text="Sign up and get a $10 coupon."
          buttonText={"Sign Up Now"}
          buttonLink={"/signup"}
        >
          <img src={RegistrationSVG}></img>
        </Block>
        <Block
          title="First Deposit"
          text="Make your first deposit and transfer it to the futures account, and you will get a coupon worth 10% of your deposited amount. The reward will be capped at $100."
          buttonText={"Deposit Now"}
          buttonLink={"/dashboard/wallets/deposit"}
        >
          <img src={DepositSVG}></img>
        </Block>
        <Block
          title="Active Trading"
          text="Active Trading - trade actively for 15 days (including spot trading and futures trading) to get a $100 coupon.
          Copy Trade - complete a Copy Trade for the first time and get a coupon worth 10% of your copy trade transaction fee. The reward will be capped at $200."
          buttonText={"Trade Now"}
          buttonLink={"/markets/BTC_USDT"}
        >
          <img src={ActiveTradingSVG}></img>
        </Block>
        <Block
          title="Cumulative Trading"
          text="Complete three futures trades and get a coupon worth 10% of your transaction fee for the three closing trades. The reward will be capped at $200. The reward will be capped at $200."
          buttonText={"Trade Now"}
          buttonLink={"/markets/BTC_USDT"}
        >
          <img src={CumulativeTradingSVG}></img>
        </Block>
      </div>
    </Layout>
  );
};

export default Rewards;
