import React from "react";

type TextInputProps = {
  label: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const TextInput = (props: TextInputProps) => {
  return (
    <div className="mb-6">
      <div className="text-xs mb-1 font-semibold">{props.label}</div>
      <input
        onChange={props.onChange}
        type="text"
        placeholder={props.placeholder}
        style={{ fontSize: "16px" }}
        className="outline-none w-full py-2 px-4 rounded-lg border"
      />
    </div>
  );
};

export default TextInput;
