import React, { useEffect, useState } from "react";
import BuyCryptoBanner from "../components/buy-crypto-banner";
import Layout from "../components/layout";
import ExchangeSVG from "../images/exchange.svg";
import BadgeSVG from "../images/fact-check.svg";
import WalletSVG from "../images/wallet.svg";
import ArrowStep from "../images/arrow-step.svg";
import RoundArrow from "../images/round-arrow.svg";
import VisaLogo from "../images/payment-methods/visa.svg";
import MastercardLogo from "../images/payment-methods/mastercard.svg";
import GooglePayLogo from "../images/payment-methods/google.svg";
import ApplePayLogo from "../images/payment-methods/apple.svg";
import { useStaticQuery, graphql, navigate } from "gatsby";
import BlueButton from "../components/blue-button";
import { useReadLocalStorage } from "usehooks-ts";
import { getSymbolPriceTicker } from "../utils/binance/api";

const f = new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });

const BuyCrypto = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteShortName
          }
        }
      }
    `
  );

  const loggedIn = useReadLocalStorage<boolean>("logged_in");
  const [btcPrice, setBtcPrice] = useState<number>(0);

  useEffect(() => {
    if (loggedIn) {
      getSymbolPriceTicker("BTCUSDT", (price) => {
        setBtcPrice(price);
      });
    } else {
      navigate("/signin");
    }
  }, []);

  return loggedIn ? (
    <Layout removePadding={true}>
      <BuyCryptoBanner />
      <div className="relative bg-gray-50 py-4">
        <div className="container">
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-5 mb-20">
            <div className="bg-white pt-4 pb-8 px-4 sm:px-8">
              <div className="flex items-center mb-6">
                <span className="bg-blue-700 w-1 h-4"></span>
                <span className="ml-2">Purchase steps</span>
              </div>
              <div className="flex items-center bg-gradient-to-r from-gray-100 to-white rounded-full py-2 px-3">
                <img src={ExchangeSVG} className="w-8 h-8"></img>
                <span className="text-xxs sm:text-xs font-semibold ml-4">
                  Choose the fiat you want to pay for, the currency you want to buy, and the
                  third-party service provider, and click the buy button
                </span>
              </div>
              <div className="pl-6 mt-1">
                <img src={ArrowStep}></img>
              </div>
              <div className="flex items-center bg-gradient-to-r from-gray-100 to-white rounded-full py-2 px-3">
                <img src={BadgeSVG} className="w-8 h-8"></img>
                <span className="text-xxs sm:text-xs font-semibold ml-4">
                  Verify your identity information on {site.siteMetadata.siteShortName} and
                  third-party service provider websites, and complete the payment
                </span>
              </div>
              <div className="pl-6 mt-1">
                <img src={ArrowStep}></img>
              </div>
              <div className="flex items-center bg-gradient-to-r from-gray-100 to-white rounded-full py-2 px-3">
                <img src={WalletSVG} className="w-8 h-8"></img>
                <span className="text-xxs sm:text-xs font-semibold ml-4">
                  Check the coins you bought in {site.siteMetadata.siteShortName} wallet coin
                  financial record, and check Order details in the website of service provider.
                </span>
              </div>
            </div>
            <div className="bg-white py-4 px-4 sm:px-8">
              <div className="flex items-center mb-6">
                <span className="bg-blue-700 w-1 h-4"></span>
                <span className="ml-2">Purchase</span>
              </div>
              <div className="flex gap-4 sm:gap-0 items-center flex-wrap sm:flex-nowrap mb-4">
                <div className="grow w-full">
                  <div className="text-lg font-semibold mb-2">I'll pay</div>
                  <div className="flex">
                    <div className="flex grow items-center border rounded py-2 px-4">
                      <input
                        style={{ fontSize: "16px" }}
                        className="grow outline-none placeholder:font-thin placeholder:text-gray-600 placeholder:text-xs sm:placeholder:text-sm"
                        placeholder="Please enter amount"
                      ></input>
                      <div className="font-semibold text-sm">USD</div>
                    </div>
                    <img className="hidden sm:block" src={RoundArrow}></img>
                  </div>
                </div>
                <div className="w-full sm:w-auto">
                  <div className="text-lg font-semibold mb-2">I'll get</div>
                  <div className="border w-full sm:w-28 flex justify-end sm:justify-center items-center py-2 px-4 rounded text-sm font-semibold">
                    BTC
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm text-gray-800 font-semibold mb-2">Payment method</div>
                <select name="methods" className="w-full outline-none border rounded py-2 px-4">
                  <option value="all">All</option>
                  <option value="visa">Visa</option>
                  <option value="mastercard">Mastercard</option>
                  <option value="google">Google Pay</option>
                  <option value="apple">Apple Pay</option>
                  <option value="samsung">Samsung Pay</option>
                  <option value="transfer">Bank transfer</option>
                </select>
              </div>
              <div>
                <div className="text-sm text-gray-800 font-semibold mb-2">
                  Recommended payment method
                </div>
                <div className="flex items-center gap-8">
                  <img src={VisaLogo} className="w-10 h-10"></img>
                  <img src={MastercardLogo} className="w-9 h-9"></img>
                  <img src={GooglePayLogo} className="w-10 h-10"></img>
                  <img src={ApplePayLogo} className="w-10 h-10"></img>
                </div>
              </div>
            </div>
            <div className="col-span-1 sm:col-span-2 bg-white py-4 px-4 sm:px-8">
              <div className="flex items-center mb-6">
                <span className="bg-blue-700 w-1 h-4"></span>
                <span className="ml-2">Choose a service provider</span>
              </div>
              <div className="w-full">
                <div className="overflow-x-auto">
                  <table
                    style={{ minWidth: "700px" }}
                    className="w-full table-fixed text-left text-xs"
                  >
                    <thead>
                      <tr className="text-left text-gray-400 font-semibold">
                        <th className="pr-4">Service provider</th>
                        <th className="pr-4">Crypto arrival time</th>
                        <th className="pr-4">Can get</th>
                        <th className="pr-4">Reference unit price</th>
                        <th className="sm:w-auto pr-4">Payment method</th>
                        <th className="pr-4">Operation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="font-semibold">
                        <td className="py-6 pr-4">Mercuryo</td>
                        <td className="py-6 pr-4">≈2~10 minutes</td>
                        <td className="py-6 pr-4">≈3.42 BTC</td>
                        <td className="py-6 pr-4">{f.format(btcPrice)} USD</td>
                        <td className="py-6 pr-4">
                          <div className="flex flex-wrap items-center gap-x-5 gap-y-2">
                            <img src={VisaLogo} className="w-7 h-7"></img>
                            <img src={MastercardLogo} className="w-5 h-5"></img>
                            <img src={GooglePayLogo} className="w-7 h-7"></img>
                            <img src={ApplePayLogo} className="w-7 h-7"></img>
                          </div>
                        </td>
                        <td className="py-6 pr-4">
                          <BlueButton
                            text="Purchase"
                            minimal={true}
                            anchor="/buy-crypto"
                          ></BlueButton>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  ) : null;
};

export default BuyCrypto;
