import React, { useEffect, useState } from 'react';
import UpIcon from './../../../images/arrow-up.svg';
import DownIcon from './../../../images/arrow-down.svg';
import { getOrderBook, getTicker24h } from '../../../utils/binance/api';

type OrderBookProps = {
  _1stCoin: string;
  _2ndCoin: string;
};

const OrderBook = (props: OrderBookProps) => {
  const [asks, setAsks] = useState<number[][]>([]);
  const [bids, setBids] = useState<number[][]>([]);
  const [lastPrice, setLastPrice] = useState<number>(0);
  const [prevLastPrice, setPrevLastPrice] = useState<number>(0);

  const symbol = `${props._1stCoin}${props._2ndCoin}`;
  const f = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 6 });

  useEffect(() => {
    const interval = setInterval(() => {
      getTicker24h(
        symbol,
        function (
          _lastPrice,
          priceChange24h,
          priceChangePercent24h,
          highPrice24h,
          lowPrice24h,
          volume,
          quoteVolume,
          weightedAvgPrice,
          bidPrice,
          askPrice,
          prevClosePrice
        ) {
          setPrevLastPrice(lastPrice);
          setLastPrice(_lastPrice);
        }
      );

      getOrderBook(symbol, 17, function (asks, bids) {
        setAsks(asks);
        setBids(bids);
      });
    }, 1500);
    return () => clearInterval(interval);
  }, [lastPrice, prevLastPrice]);

  function getAskBidFixedNumber(value: any) {
    return props._1stCoin === 'SHIB' ? Number(value).toFixed(0) : Number(value).toFixed(5);
  }

  return (
    <div className="text-sm">
      <table className="table-fixed w-full text-xs">
        <thead className="">
          <tr className="text-xxs text-gray-400">
            <th className="text-left pb-2 font-light">{`Price(${props._2ndCoin})`}</th>
            <th className="text-right pb-2 font-light">{`Amount(${props._1stCoin})`}</th>
            <th className="text-right pb-2 font-light">Total</th>
          </tr>
        </thead>
        <tbody>
          {asks &&
            asks.map((ask, index) => (
              <tr key={index} className="">
                <th className="text-left font-normal text-red-500 pb-1">{f.format(ask[0])}</th>
                <th className="text-right font-normal pb-1">{getAskBidFixedNumber(ask[1])}</th>
                <th className="text-right font-normal pb-1">
                  {Number(ask[0] * ask[1]).toFixed(5)}
                </th>
              </tr>
            ))}
          <tr>
            <th className="text-left py-2" colSpan={3}>
              <div className="flex items-center">
                <span
                  className={
                    'text-xl font-semibold ' +
                    (lastPrice > prevLastPrice ? 'text-green-600' : 'text-red-500')
                  }
                >
                  {f.format(lastPrice)}
                </span>
                <img
                  className="w-4 h-4 ml-1"
                  src={lastPrice > prevLastPrice ? UpIcon : DownIcon}
                ></img>
                <span className="pl-4 font-medium text-sm text-gray-500">
                  {'≈$' + f.format(lastPrice)}
                </span>
              </div>
            </th>
          </tr>
          {bids &&
            bids.map((bid, index) => (
              <tr key={index} className="">
                <th className="text-left font-normal text-green-600 pb-1">{f.format(bid[0])}</th>
                <th className="text-right font-normal pb-1">{getAskBidFixedNumber(bid[1])}</th>
                <th className="text-right font-normal pb-1">
                  {Number(bid[0] * bid[1]).toFixed(5)}
                </th>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderBook;
